import React from 'react';
import './propertylisting.css';

const PropertyListing = () => {

    const emptyIllustration = `
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
      <!-- Replace this with your SVG path data or structure -->
      <circle cx="50" cy="50" r="40" stroke="black" stroke-width="3" fill="red" />
    </svg>
  `;

  return (
    <div className="empty-page-container">
      <div dangerouslySetInnerHTML={{ __html: emptyIllustration }} className="empty-illustration" />
      <p className="empty-text">
        There are no apartment listed at the moment. Keep checking to find what you are looking for.
      </p>
    </div>
  );
};

export default PropertyListing;
