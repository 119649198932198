import axios from "axios";

import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { serverAddress } from "../config/Config";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router";

const AddWithdrawal = ({ user, balance }) => {
  let history = useHistory();

  const [state, setstate] = useState({
    amount: 0,
    apartment: "",
    bankAccount: "",
    note: "",
    phoneNumber: "",

    transactionId: "",
    loading: false,
    flag: false,
  });
  let {
    loading,
    flag,
    transactionId,
    phoneNumber,
    note,
    bankAccount,
    amount,
    apartment,
  } = state;
  if (!flag && balance && balance.apartment) {
    setstate({
      ...state,
      ...balance,
      apartment: balance.apartment.name_of_apartment,
      flag: true,
    });
  }

  let addBalance = (e) => {
    e.preventDefault();
    let obj = {
      ...state,
      amount: Number.parseFloat(amount),
    };

    if (amount > 0 && amount <= balance.balance) {
      let config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      setstate({ ...state, loading: true });

      axios
        .post(`${serverAddress}/admin/payment/withdraw`, obj, config)
        .then((res) => {
          console.log(res.data);
          history.push("/admin/balance");
        })
        .catch((err) => {
          toast.error("Withdrawal failed");
          setstate({ ...state, loading: false });
        });
    } else {
      toast.error(
        `Invalid amount , amount should be between 1 and  ${balance.balance}`
      );
    }
  };
  return (
    <div className="content center">
      <Row>
        <Col md="8" sm="12" style={{ margin: "auto" }}>
          <Card className="card-user">
            <CardHeader>
              <CardTitle tag="h5">Add Withdrawal</CardTitle>
            </CardHeader>
            <CardBody>
              <Form onSubmit={addBalance}>
                <Row>
                  <Col className="pr-1" md="12">
                    <FormGroup>
                      <label>Apartment</label>
                      <Input
                        placeholder="Apartment"
                        type="text"
                        value={apartment}
                        disabled
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Bank Account</label>
                      <Input
                        placeholder="Bank account"
                        type="text"
                        value={bankAccount}
                        onChange={(e) =>
                          setstate({ ...state, bankAccount: e.target.value })
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Phone Number</label>
                      <Input
                        placeholder="Phone Number"
                        type="Phone Number"
                        value={phoneNumber}
                        onChange={(e) =>
                          setstate({ ...state, phoneNumber: e.target.value })
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Transaction Id</label>
                      <Input
                        placeholder="Transaction Id"
                        type="text"
                        value={transactionId}
                        required
                        onChange={(e) =>
                          setstate({ ...state, transactionId: e.target.value })
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Note</label>
                      <Input
                        placeholder="Note"
                        type="text"
                        value={note}
                        onChange={(e) =>
                          setstate({ ...state, note: e.target.value })
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Amount</label>
                      <Input
                        placeholder="number"
                        type="text"
                        value={amount}
                        onChange={(e) =>
                          setstate({ ...state, amount: e.target.value })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <div className="update ml-auto mr-auto">
                    {!loading ? (
                      <Button
                        className="btn-round"
                        color="primary"
                        type="submit"
                      >
                        <span>Withdraw</span>
                      </Button>
                    ) : null}
                  </div>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  balance: state.balance,
});

export default connect(mapStateToProps)(AddWithdrawal);
