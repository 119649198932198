import React, { useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import dayjs from "dayjs";
import { serverAddress } from "../config/Config";

// reactstrap components
import { Card, CardBody, Table, Row, Col } from "reactstrap";

const Invoices = ({ user }) => {
  const [state, setstate] = useState({
    invoices: [],
    flag: false,
  });
  let { invoices, flag } = state;
  if (user && !flag) {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    axios.get(`${serverAddress}/invoice`, config).then((res) => {
      console.log(res.data);
      setstate({ ...state, invoices: res.data, flag: true });
    });
  }
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Id</th>
                      <th>From Date</th>
                      <th>To Date</th>
                      <th>Description</th>
                      <th>Amount</th>
                      <th>Timestamp</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoices.map((i) => (
                      <tr>
                        <td>{`${i.id.substring(0, 8)}...`}</td>
                        <td>
                          {dayjs(i.fromDate).format("D MMM , YYYY h:mm A")}
                        </td>
                        <td>{dayjs(i.toDate).format("D MMM , YYYY h:mm A")}</td>
                        <td>{i.description}</td>
                        <td>{`Ksh ${i.amount}`}</td>
                        <td>
                          {dayjs(i.timestamp).format("D MMM , YYYY h:mm A")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Invoices);
