import axios from "axios";

import React, { useState } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { serverAddress } from "../config/Config";

const ChangePassword = ({ user }) => {
  const [state, setstate] = useState({
    password: "",
    password1: "",
    loading: false,
  });
  let { password, password1, loading } = state;
  let changePassword = (e) => {
    e.preventDefault();
    setstate({ ...state, loading: true });

    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    if (password === password1 && password.length > 8) {
      axios
        .post(`${serverAddress}/auth/reset-password`, state, config)
        .then((res) => {
          toast.success(res.data);
          setstate({ ...state, loading: false });
        })

        .catch((err) => {
          toast.error("Can't send Request please try again");
          setstate({ ...state, loading: false });
        });
    } else if (password.length < 8) {
      toast.error("Passwords should have atleat 8 characters");
    } else {
      toast.error("Passwords do not match");
    }
  };
  return (
    <div className="content center">
      <Row>
        <Col md="8" sm="12" style={{ margin: "auto" }}>
          <Card className="card-user">
            <CardHeader>
              <CardTitle tag="h5">Change Password</CardTitle>
            </CardHeader>
            <CardBody>
              <Form onSubmit={changePassword}>
                <Row>
                  <Col className="pr-1" md="12">
                    <FormGroup>
                      <label>Password</label>
                      <Input
                        placeholder="Password"
                        type="password"
                        value={password}
                        required
                        onChange={(e) =>
                          setstate({ ...state, password: e.target.value })
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Confirm Password</label>
                      <Input
                        placeholder="Confirm Password"
                        type="password"
                        value={password1}
                        required
                        onChange={(e) =>
                          setstate({ ...state, password1: e.target.value })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <div className="update ml-auto mr-auto">
                    {!loading ? (
                      <Button
                        className="btn-round"
                        color="primary"
                        type="submit"
                      >
                        Change Password
                      </Button>
                    ) : null}
                  </div>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ChangePassword);
