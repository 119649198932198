import axios from "axios";

import React, { useState } from "react";

import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { serverAddress } from "../../config/Config";
import { connect } from "react-redux";

const Subscription = ({ user, subscription }) => {
  let history = useHistory();
  const [state, setstate] = useState({
    description: "",
    end: "",
    price: 0,
    start: "",
    id: "",
    status: "",
    tenantId: "",
    loading: false,
    flag: false,
  });
  let { description, end, start, price, status, loading, id, flag } = state;
  if (user && !flag) {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    axios
      .post(
        `${serverAddress}/subscription/create`,
        { id: subscription.id },
        config
      )
      .then((res) => setstate({ ...state, ...res.data, flag: true }));
  }

  let addSubscription = (e) => {
    e.preventDefault();
    setstate({ ...state, loading: true });

    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    let obj = {
      phoneNumber: Number.parseInt(user.phone_number.replace("+254", "254")),
      amountPaid: Number.parseInt(1),
      userId: user.tenant_id,
      paymentFor: id,
      username: `${user.first_name} ${user.last_name}`,
    };

    console.log(obj);

    axios
      .post(`${serverAddress}/subscription/add`, state, config)
      .then((res) => {
        axios
          .post(
            "https://rentizy.co.ke/rentizy-payment/api/v1/payments/mpesa/stk-push",
            obj
          )
          .then((r) => {
            console.log(r.data);
            toast.success("Payment initiated successfuly");
            setstate({ ...state, loading: false });
            history.push("/tenant/subscription");
          })
          .catch((err) => {
            toast.error("Payment initiation Failed");
            setstate({ ...state, loading: false });
          });
      });
  };
  return (
    <div className="content center">
      <Row>
        <Col md="8" sm="12" style={{ margin: "auto" }}>
          <Card className="card-user">
            <CardBody>
              <Form onSubmit={addSubscription}>
                <Row>
                  <Col className="pr-1" md="12">
                    <FormGroup>
                      <label>Description</label>
                      <Input
                        placeholder="Description"
                        type="text"
                        value={description}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Start</label>
                      <Input
                        placeholder="Start"
                        type="text"
                        value={` ${dayjs(start).format("D MMM , YYYY h:mm A")}`}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>End</label>
                      <Input
                        placeholder="End"
                        type="text"
                        value={` ${dayjs(end).format("D MMM , YYYY h:mm A")}`}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Status</label>
                      <Input placeholder="status" type="text" value={status} />
                    </FormGroup>
                    <FormGroup>
                      <label>Price</label>
                      <Input placeholder="price" type="text" value={price} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <div className="update ml-auto mr-auto">
                    {!loading ? (
                      <Button
                        className="btn-round"
                        color="primary"
                        type="submit"
                      >
                        Subscribe
                      </Button>
                    ) : null}
                  </div>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  subscription: state.subscription,
});

export default connect(mapStateToProps)(Subscription);
