import React, { useState } from "react";
import axios from "axios";
import { serverAddress } from "../../config/Config";
import { connect } from "react-redux";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Button,
  Row,
  Col,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import Dialog from "../Dialog/Dialog";
import { setDialog } from "../../actions/dialog";

import { setSelectedSubscription } from "../../actions/selectedSubscription";
import { toast } from "react-toastify";

const Subscriptions = ({ user, setSelectedSubscription, setDialog }) => {
  let history = useHistory();
  const [state, setstate] = useState({
    subs: [],
    flag: false,
    id: "",
  });

  let { subs, flag, id } = state;

  if (user && !flag) {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    axios.get(`${serverAddress}/packages`, config).then((res) => {
      console.log(res.data);
      setstate({ ...state, subs: res.data, flag: true });
    });
  }
  let deletSubscription = (id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    axios
      .post(`${serverAddress}/packages/delete`, { id: id }, config)
      .then((res) => {
        toast.warning("Subscription deleted successfuly");
        setstate({
          ...state,

          flag: false,
        });
      });
  };
  return (
    <>
      <Dialog param={id} callback={deletSubscription} />
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <Row>
                  <Col md={6}>
                    <CardTitle tag="h4">Subscriptions</CardTitle>
                  </Col>
                  <Col md={6}>
                    <div className="update ml-auto mr-auto">
                      <Button
                        onClick={(e) => {
                          setSelectedSubscription({});
                          history.push("add-subscription");
                        }}
                        className="btn-round"
                        color="primary"
                      >
                        Add Subscription
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Duration (Months)</th>
                      <th>Price Percentage</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subs.map((a) => {
                      return (
                        <tr>
                          <td>{a.name}</td>
                          <td>{a.description}</td>
                          <td>{a.duration}</td>
                          <td>{a.pricePercentage}</td>
                          <td>
                            <div className="update ml-auto mr-auto">
                              <Button
                                onClick={(e) => {
                                  setSelectedSubscription(a);
                                  history.push("add-subscription");
                                }}
                                className="btn-round"
                                color="primary"
                              >
                                Edit Subscription
                              </Button>
                              <Button
                                onClick={(e) => {
                                  setstate({ ...state, id: a.id });
                                  setDialog({
                                    message: `Do you want to delete subscription  ${a.name}`,
                                    show: true,
                                  });
                                }}
                                className="btn-round"
                                color="danger"
                              >
                                Delete Subscription
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { setSelectedSubscription, setDialog })(
  Subscriptions
);
