import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";

import { serverAddress } from "../config/Config";
import { counties } from "../config/counties";
import { setSelectedApartment } from "../actions/selecteApartment";
import Dropdown from "react-dropdown";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { setUser } from "../actions/user";
import { Link } from "react-router-dom";

function User({ user, setUser, setSelectedApartment }) {
  const [state, setstate] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    location: "",
    address: "",
    apartmentUnit: "",
    apartment_name: "",
    occupation: "",
    placeOfWork: "",
    county: "",
    dob: "",
    id_number: "",
    emergency_contact: "",
    loading: false,
  });

  let {
    email,
    apartment_name,
    first_name,
    last_name,
    phone_number,
    location,
    address,
    occupation,
    placeOfWork,
    county,
    dob,
    id_number,
    emergency_contact,
    loading,
    apartmentUnit,
  } = state;
  const [flag, setFlag] = useState(false);
  const [units, setUnits] = useState([]);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (user.type === "TENANT") {
      if (
        !user.location ||
        !user.occupation ||
        !user.address ||
        !user.placeOfWork ||
        !user.emergency_contact ||
        !user.apartmentUnit ||
        !user.id_number === 0
      ) {
        toast.warning("Please complete your profile to access Rentizy.");
      }
    }
  }, [flag, user]);

  useEffect(() => {
    if (county !== "") {
      axios.get(`${serverAddress}/apartments/county/${county}`).then((res) => {
        setOptions(res.data.map((a) => a.name_of_apartment));
      });
    }
  }, [flag, county]);

  if (user.appUser && !flag) {
    setFlag(true);
    setstate({ ...state, ...user });

    if (user.apartment_name && user.apartment_name !== "") {
      axios
        .get(
          `${serverAddress}/apartments/unit/apartment/${user.apartment_name}`
        )
        .then((res) => setUnits(res.data));
    }
  }

  let update = (e) => {
    e.preventDefault();
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    if (user.type === "TENANT") {
      setstate({ ...state, loading: true });

      axios
        .post(`${serverAddress}/tenant/update`, state, config)
        .then((res) => {
          axios.get(`${serverAddress}/auth`, config).then((r) => {
            setstate({ ...state, loading: false });
            setUser({ ...user, ...r.data });
            toast.success("Profile update successfully");
          });
        });
    }
    if (user.type === "OWNER") {
      axios
        .put(`${serverAddress}/owner`, state, config)
        .then((res) => console.log(res.data));
    }
  };
  return (
    <>
      {user ? (
        <div className="content">
          <Row>
            <Col md="8" style={{ margin: "auto" }}>
              <Card className="card-user">
                <CardHeader>
                  <CardTitle tag="h5">Edit Profile</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={update}>
                    <Row>
                      <Col className="pr-1" md="5">
                        <FormGroup>
                          <label>Email</label>
                          <Input
                            defaultValue={user.email}
                            disabled
                            placeholder="Email"
                            type="email"
                            value={email}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="px-1" md="3">
                        <FormGroup>
                          <label>First Name</label>
                          <Input
                            defaultValue={user.first_name}
                            placeholder="First Name"
                            type="text"
                            value={first_name}
                            onChange={(e) =>
                              setstate({ ...state, first_name: e.target.value })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="4">
                        <FormGroup>
                          <label htmlFor="exampleInputEmail1">Last Name</label>
                          <Input
                            defaultValue={user.last_name}
                            placeholder="Last Name"
                            type="text"
                            value={last_name}
                            onChange={(e) =>
                              setstate({ ...state, last_name: e.target.value })
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Phone Number</label>
                          <Input
                            defaultValue={user.phone_number}
                            placeholder="Phone number"
                            type="text"
                            value={phone_number}
                            onChange={(e) =>
                              setstate({
                                ...state,
                                phone_number: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="6">
                        <FormGroup>
                          {!user.location ? (
                            <label>
                              Location <span className="red-text"> * </span>
                            </label>
                          ) : (
                            <label>Location</label>
                          )}

                          <Input
                            defaultValue={user.location}
                            placeholder="Location"
                            type="text"
                            value={location}
                            onChange={(e) =>
                              setstate({ ...state, location: e.target.value })
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Label for="apartment">Apartment</Label>
                          {county && options.length === 0 ? (
                            <p>{`No apartments available in ${county}`}</p>
                          ) : null}

                          {options.length > 0 ? (
                            <Dropdown
                              options={options}
                              onChange={(e) =>
                                setstate({ ...state, apartment_name: e.value })
                              }
                              value={apartment_name}
                              placeholder="Select apartment"
                            />
                          ) : null}
                        </FormGroup>
                      </Col>
                      <p>
                        You can submit your apartment details for approval by{" "}
                        <Link
                          onClick={(e) => setSelectedApartment({})}
                          to="/tenant/add-apartments"
                        >
                          creating an apartment
                        </Link>
                      </p>
                    </Row>
                    <Dropdown
                      options={units.map((u) => u.unitName)}
                      onChange={(e) =>
                        setstate({ ...state, apartmentUnit: e.value })
                      }
                      value={apartmentUnit}
                      placeholder="Select apartment unit"
                    />

                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Address</label>
                          <Input
                            defaultValue={user.address}
                            placeholder="Home Address"
                            type="text"
                            value={address}
                            onChange={(e) =>
                              setstate({ ...state, address: e.target.value })
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="4">
                        <FormGroup>
                          {!user.occupation ? (
                            <label>
                              Occupation <span className="red-text"> * </span>
                            </label>
                          ) : (
                            <label>Occupation </label>
                          )}

                          <Input
                            defaultValue={user.occupation}
                            placeholder="Occupation"
                            type="text"
                            value={occupation}
                            onChange={(e) =>
                              setstate({ ...state, occupation: e.target.value })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col className="px-1" md="4">
                        <FormGroup>
                          {!user.placeOfWork ? (
                            <label>
                              Place of Work{" "}
                              <span className="red-text"> * </span>
                            </label>
                          ) : (
                            <label>Place of Work</label>
                          )}

                          <Input
                            defaultValue={user.placeOfWork}
                            placeholder="Place of work"
                            type="text"
                            value={placeOfWork}
                            onChange={(e) =>
                              setstate({
                                ...state,
                                placeOfWork: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="4">
                        <FormGroup>
                          <Label for="County">County</Label>

                          <Dropdown
                            options={counties}
                            onChange={(e) => {
                              axios
                                .get(
                                  `${serverAddress}/apartments/county/${e.value}`
                                )
                                .then((res) => {
                                  setOptions(
                                    res.data.map((a) => a.name_of_apartment)
                                  );

                                  setstate({
                                    ...state,
                                    county: e.value,
                                    flag: false,
                                  });
                                });
                            }}
                            value={county}
                            placeholder="Select County"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {!dob ? (
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label>Date of Birth: YYYY-MM-DD</label>
                            <Input
                              placeholder="YYYY-MM-DD"
                              type="text"
                              value={dob}
                              pattern="(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))"
                              onChange={(e) =>
                                setstate({ ...state, dob: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    ) : null}
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          {!user.id_number ? (
                            <label>
                              National Identification Number{" "}
                              <span className="red-text"> * </span>
                            </label>
                          ) : (
                            <label>National Identification Number</label>
                          )}

                          <Input
                            defaultValue={user.id_number}
                            placeholder="ID Number"
                            type="number"
                            value={id_number}
                            onChange={(e) =>
                              setstate({ ...state, id_number: e.target.value })
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12">
                        <FormGroup>
                          {!user.emergency_contact ? (
                            <label>
                              Emergency Contact{" "}
                              <span className="red-text"> * </span>
                            </label>
                          ) : (
                            <label>Emergency Contact</label>
                          )}

                          <Input
                            defaultValue={user.emergency_contact}
                            placeholder="Emergency Contact"
                            type="text"
                            value={emergency_contact}
                            onChange={(e) =>
                              setstate({
                                ...state,
                                emergency_contact: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <p>
                      <span className="red-text"> * </span> Required fields
                    </p>

                    <Row>
                      <div className="update ml-auto mr-auto">
                        {!loading ? (
                          <Button
                            className="btn-round"
                            color="primary"
                            type="submit"
                          >
                            Update Profile
                          </Button>
                        ) : (
                          <Spinner color="primary" />
                        )}
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { setUser, setSelectedApartment })(
  User
);
