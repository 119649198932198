import React, { useEffect } from "react";

import Home from "../Home/Home";
import { connect } from "react-redux";
import { setContext } from "../../actions/context";

const Landing = ({ context, setContext }) => {
  useEffect(() => {
    if (context !== "default") {
      setContext("default");
    }
  }, [context, setContext]);
  return (
    <div>
      <Home />
    </div>
  );
};

const mapStateToProps = (state) => ({
  context: state.context,
});

export default connect(mapStateToProps, { setContext })(Landing);
