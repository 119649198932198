import React from "react";
import "./landing.css";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import withAutoplay from "react-awesome-slider/dist/autoplay";

const Homelanding = () => {
  const AutoplaySlider = withAutoplay(AwesomeSlider);
  return (
    <div className="l-slider">
      <AutoplaySlider
        bullets={false}
        play={true}
        cancelOnInteraction={false}
        mobileTouch={false}
        interval={5000}
      >
        <div className="pic ">
          <div className="first">
            <div>
              <h1>Rent, on your terms.</h1>
              <p>
                With Rentizy you pay a portion of your rent each time you get
                paid, rather than on the first of the month.
              </p>
            </div>
          </div>
        </div>
        <div className="pic ">
          <div className="second">
            <div>
              <h1>No late fees or evictions.</h1>
              <p>
                As long as you are successfully enrolled and making payments.
              </p>
            </div>
          </div>
        </div>
        <div className="pic">
          <div className="third">
            <div>
              <h1>Take Control.</h1>
              <p>
                We automate your payments reminders and enable you to pay rent
                on your new schedule. If you keep on track, you won't pay late
                fees!
              </p>
            </div>
          </div>
        </div>
      </AutoplaySlider>
    </div>
  );
};

export default Homelanding;
