import React, { useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { serverAddress } from "../config/Config";

import { setSelectedLoan } from "../actions/selectedLoan";
import { Link } from "react-router-dom";

import dayjs from "dayjs";

// reactstrap components
import {
  Card,
  CardBody,
  Table,
  CardHeader,
  CardTitle,
  Row,
  Col,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const AdminLoans = ({ user, setSelectedLoan }) => {
  const [dropdownOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);
  const [state, setstate] = useState({
    loans: [],
    flag: false,
    filter: "",
  });
  let { loans, flag, filter } = state;
  if (user && !flag) {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    axios.get(`${serverAddress}/admin/loan`, config).then((res) => {
      console.log(res.data);
      if (filter !== "") {
        setstate({
          ...state,
          loans: res.data.filter((l) => l.status === filter),
          flag: true,
        });
      } else setstate({ ...state, loans: res.data, flag: true });
    });
  }

  // let approveLoan = (id) => {
  //   let config = {
  //     headers: {
  //       Authorization: `Bearer ${user.token}`,
  //     },
  //   };
  //   axios
  //     .get(`${serverAddress}/admin/loan/approve`, config)
  //     .then((res) => {
  //       console.log(res.data);
  //       toast.success("Loan approved successfuly");
  //       setstate({ ...state, loans: res.data, flag: false });
  //     })
  //     .catch((err) => {
  //       toast.error("Loan approval failed");
  //     });
  // };
  // let rejectLoan = (id) => {
  //   let config = {
  //     headers: {
  //       Authorization: `Bearer ${user.token}`,
  //     },
  //   };
  //   axios
  //     .post(`${serverAddress}/admin/loan/reject`, { id }, config)
  //     .then((res) => {
  //       console.log(res.data);
  //       toast.success("Loan rejected successfully");
  //       setstate({ ...state, flag: false });
  //     })
  //     .catch((err) => {
  //       toast.error("Loan rejection failed");
  //     });
  // };
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Loans</CardTitle>
                <p className="card-category">Tenant Loans</p>
                <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle caret>
                    {filter === "" ? (
                      <span>Filter by status</span>
                    ) : (
                      <span>{`${filter} loans`}</span>
                    )}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={(e) => {
                        setstate({
                          ...state,
                          filter: "PENDING",
                          flag: false,
                        });
                      }}
                    >
                      Pending
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      onClick={(e) => {
                        setstate({ ...state, filter: "APPROVED", flag: false });
                      }}
                    >
                      Approved
                    </DropdownItem>
                    <DropdownItem
                      onClick={(e) => {
                        setstate({ ...state, filter: "REJECTED", flag: false });
                      }}
                    >
                      Rejected
                    </DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Id</th>
                      <th>Amount</th>
                      <th>Net Amount</th>
                      <th>Interest</th>
                      <th>Status</th>
                      <th>Timestamp</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loans.map((i) => (
                      <tr>
                        <td>
                          <Link
                            onClick={(e) => setSelectedLoan(i)}
                            to="selected-loan"
                          >
                            {i.id.substring(0, 8) + "..."}
                          </Link>{" "}
                        </td>
                        <td>{i.loanAmount}</td>
                        <td>{i.netAmount}</td>
                        <td>{i.interestAmount}</td>
                        <td>{i.status}</td>
                        <td>
                          {" "}
                          {` ${dayjs(i.timestamp).format(
                            "D MMM , YYYY h:mm A"
                          )}`}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { setSelectedLoan })(AdminLoans);
