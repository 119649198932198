import React, { useState} from "react";
import "./selectedApartment.css";
import { connect } from "react-redux";
import { Card, CardBody, Row, Col } from "reactstrap";
import ApartmentUnits from "../../views/ApartmentUnits";
import { FormGroup, Input } from "reactstrap";


const SelectedApartment = ({ apartment, user }) => {
  const [state] = useState({ owner: null });

  let { owner } = state;

  return (
    <>
      <div className="content selected-apartment">
        <Row>
          <Col md="12">
            <Card className="card-chart">
              <CardBody>
                <div className="tenant">
                  <Row>
                    <Col md="6">
                      <h5>Apartment Details</h5>
                      <FormGroup>
                        <label>Name</label>
                        <Input
                          disabled
                          placeholder="Name"
                          type="text"
                          value={apartment.name_of_apartment}
                          required
                        />
                      </FormGroup>
                      <FormGroup>
                        <label>No of houses</label>
                        <Input
                          disabled
                          type="text"
                          value={apartment.no_of_houses}
                          required
                        />
                      </FormGroup>
                      <FormGroup>
                        <label>Location</label>
                        <Input
                          disabled
                          type="text"
                          value={apartment.location}
                          required
                        />
                      </FormGroup>

                      <FormGroup>
                        <label>Address</label>
                        <Input
                          disabled
                          type="text"
                          value={apartment.address}
                          required
                        />
                      </FormGroup>

                      <FormGroup>
                        <label>County</label>
                        <Input
                          disabled
                          type="text"
                          value={apartment.county}
                          required
                        />
                      </FormGroup>
                    </Col>
                    {owner ? (
                      <Col md="6">
                        <h5>Landlord Details</h5>
                        <FormGroup>
                          <label>First Name</label>
                          <Input
                            disabled
                            type="text"
                            value={owner.first_name}
                            required
                          />
                        </FormGroup>
                        <FormGroup>
                          <label>Last Name</label>
                          <Input
                            disabled
                            type="text"
                            value={owner.last_name}
                            required
                          />
                        </FormGroup>
                        <FormGroup>
                          <label>Phone Number</label>
                          <Input
                            disabled
                            type="text"
                            value={owner.phone_number}
                            required
                          />
                        </FormGroup>

                        <FormGroup>
                          <label>Email</label>
                          <Input
                            disabled
                            type="text"
                            value={owner.email}
                            required
                          />
                        </FormGroup>
                      </Col>
                    ) : null}
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ApartmentUnits />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  apartment: state.apartment,
  user: state.user,
});

export default connect(mapStateToProps)(SelectedApartment);
