import React from "react";

const Contact = () => {
  return (
    <div>
      <h2>Consent to be Contacted</h2>
      <p>
        By accepting the Terms of Use, you have provided consent to Rentizy Inc.
        (collectively, along with any direct or indirect subsidiaries,
        “Rentizy”) to contact you by any method, including email, telephone,
        text, SMS, regular mail, and App notifications. This consent authorizes
        Rentizy (and Rentizy’s affiliates, agents, assigns, service providers,
        or anyone calling on our behalf, collectively, “we”, “us” and “our” ) to
        contact you at any phone or mobile number you provide us or that we can
        reasonably associate with your account (for example, through a skip
        trace or caller ID), including by using automated, autodialed, or
        prerecorded (including artificial prerecorded) phone calls even if you
        have opted into the Do Not Call List administered by the Communication
        authority of Kenya or any equivalent organization. You also authorize us
        to send you SMS/text messages, including through an automated texting
        system with information or questions about your application, loan,
        account or use of the Services. You understand that anyone with access
        to your mail, telephone or email account may listen to or read the
        messages we leave or send you, and you agree that we will have no
        liability for anyone accessing such messages.
      </p>
      <p>
        By providing us a mobile number, you represent and warrant you are the
        owner or regular user of that number and acknowledge that your carrier’s
        message and data rates will apply. You also agree to notify us
        immediately whenever you stop using the mobile number. Where allowed by
        law, we also may contact other individuals who may be able to provide
        updated employment, location, and contact information for you.
      </p>
      <p>
        Consent to receive SMS/text messages are not required as a condition of
        purchase. You may withdraw your consent to receive SMS/text messages
        from us (other than for fraud alerts) by replying to any SMS/text
        message we have sent you with the message STOP. This withdrawal will be
        limited to text messages for the product to which the text message
        relates.
      </p>
      <h2>Marketing and Telemarketing</h2>
      <p>
        If you provide consent to be contacted by phone or text for marketing
        purposes, you agree that we may call, email or text you to describe
        products or services (including third-party products and services) that
        may be of interest to you, including by using automated, autodialed,
        prerecorded (including artificial prerecorded) phone calls even if you
        have opted into the Do Not Call List administered by the communication
        authority of Kenya or any organization equivalent. This consent is not a
        condition of using the Services and may be revoked at any time.
      </p>
      <h2>Opting Out</h2>
      <p>
        You can revoke your consent by contacting us via email at{" "}
        <a href="mailto:info@rentizy.co.ke">info@rentizy.co.ke</a>
      </p>
      <h2>Call Recording and Monitoring</h2>
      <p>
        You consent and expressly authorize us to record and monitor any call
        between you and us.
      </p>
    </div>
  );
};

export default Contact;
