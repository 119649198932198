import React from "react";
import "./home.css";
import calendar from "../../assets/img/calendar.png";
import { Button } from "reactstrap";
import { BiTimeFive } from "react-icons/bi";
import { FaUserCheck } from "react-icons/fa";
import { FcCalendar } from "react-icons/fc";
import { useHistory } from "react-router-dom";
import Homelanding from "../Landing/Homelanding";

const Home = () => {
  let history = useHistory();
  return (
    <div className="home">
      <Homelanding />
      <div className="calendar">
        <div className="l-header">
          <h1
            style={{
              fontFamily: "'Poppins Bold', sans-serif",
              fontWeight: 700,
              color: "#F08705",
            }}
          >
            Pay rent on your schedule
          </h1>
          <p>
            Rentizy is a rent payment solution that helps you budget, save and
            pay for rent. Renter trusted, landlord approved.
          </p>
          <Button
            onClick={(e) => history.push("/register")}
            className="btn-round"
            color="primary"
          >
            Join Rentizy
          </Button>
        </div>
        <div className="img">
          <img src={calendar} alt="calendar" />
        </div>
      </div>
      <div className="more">
        <div>
          <BiTimeFive size={50} style={{ margin: "auto" }} />
          <h4>Everyone is approved</h4>
          <p>Rentizy is here to make your rent experience amazing</p>
        </div>
        <div>
          <FaUserCheck size={50} style={{ margin: "auto" }} />
          <h4>No credit check</h4>
          <p>
            Everyone with income can be approved. And choosing Rentizy can
            actually help you avoid using a high-cost loan!
          </p>
        </div>
        <div>
          <FcCalendar size={50} style={{ margin: "auto" }} />
          <h4>Autopay. Set it, and forget it.</h4>
          <p>
            Online, automatic payments make sure you’ll never forget. We also
            offer debit and credit card payment options.
          </p>
        </div>
      </div>

      <div className="learn">
        <h3>
          Rentizy is here to make your rental experience and financial life
          amazing.
        </h3>
        <p>
          We work with you to establish flexible payment schedules that work.
          With Rentizy, rent becomes an afterthought. We automate your payments,
          and ensure you’re never hit with late fees or evicted while enrolled.
        </p>
        <div>
          <Button
            onClick={(e) => history.push("/platform")}
            className="btn-round"
            color="primary"
          >
            Learn More
          </Button>
        </div>
      </div>

      <div className="testimonials">
        <h4>What People Are Saying</h4>
        <div className="t-content">
          <div>
            <p>
              “Rentizy is the best thing that has happened since this pandemic.
              They give you the option of paying your rent in pieces or all at
              once. I do not know what I would do without their help. I thank
              God for them every day.”
            </p>
            <h5>— Austine Gwa, Tenant</h5>
          </div>
          <div>
            <p>
              “Rentizy has completely altered the way we approach rent
              collection, but more importantly, it has improved our fundamental
              relationship with our tenants.”
            </p>
            <h5>— Chando Steve, Landlord</h5>
          </div>
          <div>
            <p>
              “Working with Rentizy has helped my family stay in our home, pay
              on time and avoid eviction. Being able to divide my rent over the
              month to correlate with my paychecks has allowed me to be ahead of
              schedule on rent now.”
            </p>
            <h5>— Collins, Tenant</h5>
          </div>
        </div>
      </div>

      <div className="mission">
        <div className="m-content">
          <p className="p-mission">OUR MISSION</p>
          <h1>Pay, stay, and thrive.</h1>
        </div>
        <div>
          <h5>
            The home is the foundation for every family. We believe it should
            also be a financial foundation.
          </h5>
          <p>
            The home is the foundation for every family. We believe it should
            also be a financial foundation.
          </p>
          <div>
            <Button
              onClick={(e) => history.push("/register")}
              className="btn-round"
              color="primary"
            >
              Join now
            </Button>
          </div>
        </div>
      </div>
      <div className="value">
        <div>
          <p
            style={{
              color: "white",

              textTransform: "uppercase",
            }}
            className="p-mission"
          >
            For owners and tenants
          </p>
          <h4>Maximize the value of your communities</h4>
          <h5 style={{ color: "#E9711E", letterSpacing: "0.05em" }}>
            When your tenant succeed, you succeed.{" "}
          </h5>
          <p>
            We partner with you to improve on-time collections with our
            personalized payment schedules, using innovative tech, data and
            analytics to optimize your tenant ability and willingness to pay.
          </p>
          <div>
            <Button
              onClick={(e) => history.push("/contact")}
              className="btn-round"
              color="primary"
            >
              Schedule a Demo
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
