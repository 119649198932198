import "./App.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";

import "react-phone-number-input/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-notification-alert/dist/animate.css";
import { Provider } from "react-redux";
import "react-dropdown/style.css";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import store from "./store";
//import "assets/scss/paper-dashboard.scss?v=1.3.0";

import "perfect-scrollbar/css/perfect-scrollbar.css";
import "./assets/css/custom.css";
import "./assets/css/paper-dashboard.css";
import "./assets/demo/demo.css";

import GlobalStyle from "./components/Landing/globalStyles";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";

import Products from "./pages/Products/Products";

import ScrollToTop from "./components/ScrollToTop";
import { Navbar, Footer } from "./components";

import AdminLayout from "./layouts/Admin.js";
import Landing from "./components/Landing/Landing";
import TenantDashboard from "./layouts/TenantDashboard";
import ChangePassword from "./components/Password/ChangePassword";
import ResetPassword from "./components/Password/ResetPassword";
import Disclosure from "./components/Policies/Disclosure";
import Careers from "./components/Careers/Careers";

import OwnerDashboard from "./layouts/OwnerDashboard";
import FlexibleRent from "./components/FlexiblePayment/FlexibleRent";
import Operators from "./components/Operators/Operators";
import About from "./components/About/About";
import Platform from "./components/Platform/Platform";
import Budget from "./components/Budget/Budget";
import Landlord from "./components/Landloard/Landlord";
import Testimonials from "./components/Testimonials/Testimonials";
import Policy from "./components/Policies/Policy";
import Contact from "./components/Contact/Contact";
import ScrollTop from "./components/ScrollTop/ScrollTop";
import FaqComponent from "./components/Faq/FaqComponent";
import PrivacyPolicy from "./components/Policies/PrivacyPolicy";
import TermsOfService from "./components/Policies/TermsOfService";
import LandlordTerms from "./components/Policies/LandlordTerms";
import MoreInfo from "./components/MoreInfo/MoreInfo";
import { analytics } from "./config/FirebaseConfig";
import PropertyListing from "./components/listing/PropertyListing.js";

function App() {
  analytics.setAnalyticsCollectionEnabled(true);

  return (
    <Provider store={store.store}>
      <div className="App">
        <ToastContainer />
        <Router basename={"app"}>
          <GlobalStyle />
          <ScrollToTop />
          <Navbar />

          <Switch>
            <Route
              path="/admin"
              render={(props) => <AdminLayout props={props} />}
            />
            <Route
              path="/tenant"
              render={(props) => <TenantDashboard props={props} />}
            />
            <Route
              path="/owner"
              render={(props) => <OwnerDashboard props={props} />}
            />

            <Route path="/services" component={About} />
            <Route path="/listing"  component={PropertyListing} />
            <Route path="/platform" component={Platform} />
            <Route path="/landlords" component={Landlord} />
            <Route path="/budget" component={Budget} />
            <Route path="/tenants" component={FlexibleRent} />
            <Route path="/owner-operator" component={Operators} />
            <Route path="/testimonials" component={Testimonials} />
            <Route path="/consent-to-be-contacted" component={Policy} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/terms-of-service" component={TermsOfService} />
            <Route
              path="/terms-of-service-owners-and-operators"
              component={LandlordTerms}
            />
            <Route path="/careers" component={Careers} />
            <Route path="/products" component={Products} />
            <Route path="/esign-consent" component={Disclosure} />
            <Route path="/register" component={Register} />
            <Route path="/contact" component={Contact} />
            <Route path="/faq" component={FaqComponent} />
            <Route path="/why" component={MoreInfo} />
            <Route path="/login" component={Login} />
            <Route path="/change-password" component={ChangePassword} />
            <Route path="/reset-password/:id" component={ResetPassword} />
            <Route exact path="/" component={Landing} />
          </Switch>
          <Footer />
          <ScrollTop />
        </Router>
      </div>
    </Provider>
  );
}

export default App;
