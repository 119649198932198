import React, { useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import dayjs from "dayjs";
import { serverAddress } from "../config/Config";

// reactstrap components
import { Card, CardBody, Table, Row, Col } from "reactstrap";

const Withdrawals = ({ user }) => {
  const [state, setstate] = useState({
    withdrawal: [],
    flag: false,
  });
  let { withdrawal, flag } = state;
  if (user && !flag) {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    let url = `${serverAddress}/admin/payment/withdraw`;
    if (user.type === "ADMIN") {
      url = `${serverAddress}/admin/payment/withdraw`;
    } else {
      url = `${serverAddress}/admin/payment/withdraw`;
    }

    axios.get(url, config).then((res) => {
      console.log(res.data);
      setstate({ ...state, withdrawal: res.data, flag: true });
    });
  }
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Transaction Id</th>
                      <th>Apartment</th>
                      <th>Phone Number</th>
                      <th>Bank Account</th>
                      <th>Amount</th>
                      <th>Initiator</th>
                      <th>Note</th>
                      <th>Timestamp</th>
                    </tr>
                  </thead>
                  <tbody>
                    {withdrawal.map((i) => (
                      <tr>
                        <td>{`${i.transactionId}`}</td>
                        <td>{i.apartment}</td>
                        <td>{i.PhoneNumber}</td>
                        <td>{i.bankAccount}</td>

                        <td>{`Ksh ${i.amount}`}</td>
                        <td>{i.initiator}</td>
                        <td>{i.note}</td>
                        <td>
                          {dayjs(i.timestamp).format("D MMM , YYYY h:mm A")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Withdrawals);
