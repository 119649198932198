import React, { useState } from "react";
import axios from "axios";
import { serverAddress } from "../config/Config";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { setSelectedStaff } from "../actions/staff";
import { setSelectedUser } from "../actions/selectedUser";
import { toast } from "react-toastify";
import { setDialog } from "../actions/dialog";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
} from "reactstrap";
import Dialog from "../components/Dialog/Dialog";

const StaffView = ({ user, setSelectedUser, setSelectedStaff, setDialog }) => {
  let history = useHistory();
  const [state, setstate] = useState({
    flag: false,

    staff: [],
    id: "",
  });

  let { flag, staff, id } = state;

  if (user && !flag) {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    let url = `${serverAddress}/admin/staff`;

    axios.get(url, config).then((res) => {
      setstate({
        ...state,
        staff: res.data,
        flag: true,
      });
    });
  }

  let deleteStaff = (id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    axios
      .post(`${serverAddress}/admin/staff/delete`, { id }, config)
      .then((res) => {
        toast.warning(res.data);
        setstate({
          ...state,

          flag: false,
        });
      });
  };

  return (
    <>
      <Dialog param={id} callback={deleteStaff} />
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <Row>
                  <Col md={6}>
                    <CardTitle tag="h4">Staff</CardTitle>
                  </Col>
                  <Col md={6}>
                    <div className="update ml-auto mr-auto">
                      <Button
                        onClick={(e) => {
                          setSelectedStaff({});
                          history.push("add-staff");
                        }}
                        className="btn-round"
                        color="primary"
                      >
                        Add Staff
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Full Name</th>
                      <th>Email</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {staff
                      .filter((s) => s.appUser &&  s.appUser.email !== user.appUser.email)
                      .map((e) => (
                        <tr>
                          <td>
                            <Link
                              onClick={(event) => setSelectedUser(e)}
                              to={"/admin/selected-user"}
                            >{`${e.first_name} ${e.last_name}`}</Link>
                          </td>
                          <td>{e.appUser.email}</td>
                          <td>
                            <div className="update ml-auto mr-auto">
                              <Button
                                onClick={(event) => {
                                  setSelectedStaff(e);
                                  history.push("add-staff");
                                }}
                                className="btn-round"
                                color="primary"
                              >
                                Edit Staff
                              </Button>
                              <Button
                                onClick={(event) => {
                                  setDialog({
                                    message: `Do you want to delete staff ${e.first_name} ${e.last_name}`,
                                    show: true,
                                  });
                                  setstate({ ...state, id: e.admin_id });
                                }}
                                className="btn-round"
                                color="danger"
                              >
                                Delete Staff
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {
  setSelectedUser,
  setSelectedStaff,
  setDialog,
})(StaffView);
