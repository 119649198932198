import React from "react";
import "./terms.css";

const Disclosure = () => {
  return (
    <div className="terms">
      <p>
        <strong>Consent to Electronic Disclosure</strong>
      </p>
      <p>
        <strong>Last modified: August 22, 2021</strong>
      </p>
      <p>
        Rentizy offers loans and financial products and services only through
        its Site or App. To obtain a loan or access our Services, you must
        consent to transact with us electronically. This requires that (1) you
        create an account with us online, (2) have access to a computer or
        device that meets the hardware and software requirements necessary to
        access the Site and Services, and (3) consent to receive all notices and
        disclosures from us electronically.
      </p>
      <p>By accessing our Services, you agree:</p>
      <ul>
        <li>
          to receive all communications related to the Services electronically
          by email or through the Site or App;
        </li>
        <li>
          to provide us with a valid email address to receive communications
          regarding the Services and to keep this email address current always;
        </li>
        <li>
          that you have the ability to access the communications or notices
          provided to you through the Site or App.
        </li>
      </ul>
      <p>
        In addition, we have provided certain disclosures required by the
        Information and Communications Act in Kenyan law below under the heading
        "ESIGN Disclosures and Consent&rdquo;. You acknowledge having received
        these disclosures and provide the consent outlined in those ESIGN
        disclosures.
      </p>
      <p>
        <strong>ESIGN Disclosures and Consent</strong>
      </p>
      <p>
        Please read these&nbsp;<strong>"ESIGN Consent Terms"</strong>
        &nbsp;carefully and retain a copy for your records. We require your
        approval of these ESIGN Consent Terms to use the Services.
      </p>
      <p>
        These ESIGN Consent Terms apply to each disclosure, notice, agreement,
        fee schedule, statement, record, document, and other information we
        provide to you, or that you sign or submit or agree to at our request,
        in connection with the Services (<strong>"Communications&rdquo;</strong>
        ).
      </p>
      <p>
        <strong>Consent and Agreement</strong>
      </p>
      <p>
        You consent and agree to receive all Communications related to the
        Services electronically (
        <strong>"Electronic Communications&rdquo;</strong>), whether via email,
        by access or link to a site we provide in an email or other
        Communication, by access to a mobile app or site we designate in advance
        such purpose, or otherwise. You agree that Electronic Communications
        have the same meaning and effect as if we provided paper documents to
        you, regardless of whether you choose to view or access the documents.
      </p>
      <p>
        By providing your consent, you are also confirming that you have the
        hardware and software described below, that you are able to receive and
        review electronic records, and that you have an active email account.
      </p>
      <p>
        <strong>Hardware and Software Requirements</strong>
      </p>
      <p>To receive Electronic Communications, you must have access to:</p>
      <ul>
        <li>
          a Current Version (defined below) of an Internet browser we support,
        </li>
        <li>a connection to the Internet,</li>
        <li>
          a Current Version of a program that accurately reads and displays PDF
          files (such as Adobe Acrobat Reader), and
        </li>
        <li>
          a computer or electronic device and an operating system capable of
          supporting all of the above. You will also need a printer if you wish
          to print out and retain records on paper, and electronic storage if
          you wish to retain records in electronic form.
        </li>
      </ul>
      <p>You must also have an active email address.</p>
      <p>
        In some cases, you may also need a specific brand or type of device that
        can support a particular software application, including an application
        intended for particular mobile or handheld devices.
      </p>
      <p>
        By "Current Version,&rdquo; we mean a version of the software that is
        currently being supported by its publisher. We currently support the
        following Internet browsers: Firefox, Google Chrome, Safari, and
        Microsoft Edge.
      </p>
      <p>
        From time to time, we may offer services or features that require that
        your Internet browser be configured in a particular way, such as
        permitting the use of JavaScript or cookies. If we detect that your
        Internet browser is not properly configured, we will provide you with a
        notice and advice on how to update your configuration.
      </p>
      <p>
        We reserve the right to discontinue support of a Current Version of
        software if, in our sole opinion, it suffers from a security flaw or
        other flaw that makes it unsuitable for use with the Services.
      </p>
      <p>
        <strong>Up-to-date Email and Mailing Address</strong>
      </p>
      <p>
        You must keep us informed of any changes to your email or mailing
        address. You can change this information by logging into the Site or by
        sending us a written update by mail. If we send an Electronic
        Communication to the email address we have on file and it bounces back,
        you agree that you will be deemed to have received the Electronic
        Communication.
      </p>
      <p>
        <strong>Paper Copies and Withdrawing Consent</strong>
      </p>
      <p>
        You may request a paper copy of an Electronic Communication by
        delivering a written request to our Notice Address. We will provide
        paper copies of Communications upon request but reserve the right to
        charge a reasonable fee for such request, as determined in our sole
        discretion.
      </p>
      <p>
        You have the right to withdraw your consent to these ESIGN Consent Terms
        at any time by delivering written notice of such withdrawal to our
        Notice Address. Your withdrawal of consent will be effective after we
        have had a reasonable time to process it. If you withdraw your consent,
        you will no longer be able to use certain features of the Services,
        which may take up to five days to terminate after we receive your
        withdrawal of consent.
      </p>
      <p>
        <strong>How to Contact Us</strong>
      </p>
      <p>
        You may contact us via email at&nbsp;
        <a href="mailto:info@rentizy.co.ke">info@rentizy.co.ke</a>&nbsp;for
        questions regarding electronic disclosures and this consent.
      </p>
    </div>
  );
};

export default Disclosure;
