import React, { useState } from "react";
import { Row, Col } from "reactstrap";

import { toast } from "react-toastify";
import { connect } from "react-redux";

import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import "./reset.css";

import axios from "axios";
import { serverAddress } from "../../config/Config";
import { setContext } from "../../actions/context";

const ResetPassword = ({ setUser, setContext }) => {
  const [state, setstate] = useState({
    email: "",
  });
  let { email } = state;

  let login = (e) => {
    e.preventDefault();

    axios
      .post(`${serverAddress}/auth/change-password`, state)
      .then((res) => {
        toast.success(res.data);
      })

      .catch((err) => {
        toast.error("Can't send Request please try again");
      });
  };
  return (
    <div className="login">
      <Row>
        <Col className="l-space" xs="12" sm="12" md="8">
          <div className="login-logo"></div>
        </Col>
        <Col xs="12" sm="12" md="4">
          <div className="login-form">
              <div className="logo">
                <h4>Reset Password</h4>
              </div>
              <Form onSubmit={login}>
                <FormGroup>
                  <Label for="exampleEmail">Email</Label>
                  <Input
                    required
                    value={email}
                    onChange={(e) =>
                      setstate({ ...state, email: e.target.value })
                    }
                    type="email"
                    name="email"
                    id="exampleEmail"
                    placeholder="email"
                  />
                </FormGroup>

                <div className="action">
                  <Button>Reset Password</Button>
                </div>
              </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default connect(null, { setContext })(ResetPassword);
