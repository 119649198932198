import React from "react";
// javascript plugin used to create scrollbars on windows

import { Route, Switch, useLocation } from "react-router-dom";

import DemoNavbar from "../components/Navbars/DemoNavbar.js";
import Footer from "../components/Footer/Footer.js";
import Sidebar from "../components/Sidebar/Sidebar.js";

import routes from "../ownerRoutes.js";
import AddApartment from "../views/AddApartment.js";
import { connect } from "react-redux";
import { setContext } from "../actions/context";
import { useHistory } from "react-router-dom";

function Dashboard({ context, setContext, user, props }) {
  let history = useHistory();
  if (!user && !user.token) {
    history.push("/login");
  }

  const mainPanel = React.useRef();
  const location = useLocation();

  React.useEffect(() => {
    mainPanel.current.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);

  React.useEffect(() => {
    if (context !== "dashboard") {
      setContext("dashboard");
    }
  }, [context, setContext]);

  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        routes={routes}
        bgColor={"black"}
        activeColor={"info"}
      />
      <div className="main-panel" ref={mainPanel}>
        <DemoNavbar {...props} />
        <Switch>
          {routes.map((prop, key) => {
            return (
              <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            );
          })}

          <Route path={"/admin/add-apartments"} component={AddApartment} />
        </Switch>
        <Footer fluid />
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  context: state.context,
  user: state.user,
});

const mapDispatchToProps = {
  setContext,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
