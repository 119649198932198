import { SET_CONTEXT } from "../actions/types";

let initialState = "default";

const context = (state = initialState, action) => {
  let { type, payload } = action;
  switch (type) {
    case SET_CONTEXT:
      return payload;

    default:
      return state;
  }
};
export default context;
