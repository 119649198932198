import axios from "axios";

import React, { useState } from "react";

import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { serverAddress } from "../../config/Config";

const Contact = () => {
  let history = useHistory();
  const [state, setstate] = useState({
    name: "",
    phone: "",
    email: "",
    subject: "",
    message: "",

    loading: false,
  });
  let { name, phone, email, subject, message, loading } = state;

  let sendMessage = (e) => {
    e.preventDefault();
    setstate({ ...state, loading: true });
    let obj = {
      ...state,
    };

    axios
      .post(`${serverAddress}/contact`, obj)
      .then((res) => {
        console.log(res.data);
        history.push("/");
      })
      .catch((err) => {
        toast.error("Error occured while sending message");
        setstate({ ...state, loading: false });
      });
  };

  return (
    <div className="content center">
      <Row>
        <Col md="8" sm="12" style={{ margin: "auto" }}>
          <Card className="card-user">
            <CardHeader>
              <CardTitle tag="h5">Contact Us</CardTitle>
            </CardHeader>
            <CardBody>
              <Form onSubmit={sendMessage}>
                <Row>
                  <Col className="pr-1" md="12">
                    <FormGroup>
                      <label>Name</label>
                      <Input
                        placeholder="Name"
                        type="text"
                        value={name}
                        required
                        onChange={(e) =>
                          setstate({ ...state, name: e.target.value })
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Email</label>
                      <Input
                        placeholder="Email"
                        type="email"
                        required
                        value={email}
                        onChange={(e) =>
                          setstate({ ...state, email: e.target.value })
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Phone</label>
                      <Input
                        placeholder="Phone"
                        type="text"
                        value={phone}
                        required
                        onChange={(e) =>
                          setstate({ ...state, phone: e.target.value })
                        }
                      />
                    </FormGroup>

                    <FormGroup>
                      <label>Subject</label>
                      <Input
                        placeholder="Subject"
                        type="text"
                        value={subject}
                        required
                        onChange={(e) =>
                          setstate({ ...state, subject: e.target.value })
                        }
                      />
                    </FormGroup>

                    <FormGroup>
                      <label>Message</label>
                      <Input
                        placeholder="Message"
                        type="textarea"
                        value={message}
                        required
                        onChange={(e) =>
                          setstate({ ...state, message: e.target.value })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <div className="update ml-auto mr-auto">
                    {!loading ? (
                      <Button
                        style={{ backgroundColor: "#e9711e" }}
                        className="btn-round"
                        color="primary"
                        type="submit"
                      >
                        Send
                      </Button>
                    ) : (
                      <Spinner color="primary" />
                    )}
                  </div>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Contact;
