import axios from "axios";

import React, { useState } from "react";

import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { serverAddress } from "../../config/Config";
import { connect } from "react-redux";

const AddLandlord = ({ user, landlord }) => {
  let history = useHistory();
  const [state, setstate] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    loading: false,
    flag: false,
  });
  let { first_name, email, phone_number, last_name, loading, flag } = state;
  if (landlord && !flag) {
    setstate({
      ...state,
      ...landlord,

      flag: true,
    });
  }

  let addLandlord = (e) => {
    e.preventDefault();
    setstate({ ...state, loading: true });
    let obj = {
      ...state,
    };

    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    if (landlord.owner_id) {
      axios
        .post(`${serverAddress}/owner/update`, obj, config)
        .then((res) => {
          console.log(res.data);
          history.push("/admin/landlords");
        })
        .catch((err) => {
          toast.error("Error occured while updating landlord");
          setstate({ ...state, loading: false });
        });
    } else {
      axios
        .post(`${serverAddress}/auth/register`, obj, config)
        .then((res) => {
          console.log(res.data);
          history.push("/admin/landlords");
        })
        .catch((err) => {
          toast.error("Error occured while adding landlord");
          setstate({ ...state, loading: false });
        });
    }
  };
  return (
    <div className="content center">
      <Row>
        <Col md="8" sm="12" style={{ margin: "auto" }}>
          <Card className="card-user">
            <CardHeader>
              {landlord.owner_id ? (
                <CardTitle tag="h5">Edit Landlord</CardTitle>
              ) : (
                <CardTitle tag="h5">Add Landlord</CardTitle>
              )}
            </CardHeader>
            <CardBody>
              <Form onSubmit={addLandlord}>
                <Row>
                  <Col className="pr-1" md="12">
                    <FormGroup>
                      <label>First Name</label>
                      <Input
                        placeholder="First Name"
                        type="text"
                        value={first_name}
                        required
                        onChange={(e) =>
                          setstate({
                            ...state,
                            first_name: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Last Name</label>
                      <Input
                        placeholder="Last Name"
                        type="text"
                        value={last_name}
                        onChange={(e) =>
                          setstate({ ...state, last_name: e.target.value })
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Email</label>
                      <Input
                        placeholder="Email"
                        type="email"
                        value={email}
                        required
                        onChange={(e) =>
                          setstate({ ...state, email: e.target.value })
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Phone Number</label>
                      <Input
                        placeholder="Phone Number"
                        type="text"
                        value={phone_number}
                        required
                        onChange={(e) =>
                          setstate({ ...state, phone_number: e.target.value })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <div className="update ml-auto mr-auto">
                    {!loading ? (
                      <Button
                        className="btn-round"
                        color="primary"
                        type="submit"
                      >
                        {landlord.owner_id ? (
                          <span>Edit Landlord</span>
                        ) : (
                          <span>Add Landlord</span>
                        )}
                      </Button>
                    ) : null}
                  </div>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  landlord: state.landlord,
});

export default connect(mapStateToProps)(AddLandlord);
