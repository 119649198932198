import React, { useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { serverAddress } from "../config/Config";
import { getTenants } from "../variables/charts.js";
import dayjs from "dayjs";
import { Line } from "react-chartjs-2";

const Tenants = ({ user }) => {
  const [state, setstate] = useState({
    flag: false,
    t: {
      labels: [],
      data: [],
    },
  });
  let { flag, t } = state;
  if (user && !flag) {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    axios
      .get(`${serverAddress}/admin/metric/monthly-tenant-count`, config)
      .then((res) => {
        setstate({
          ...state,
          flag: true,
          t: {
            labels: Object.keys(res.data).sort(
              (a, b) => dayjs(`1 ${a}`).toDate() - dayjs(`1 ${b}`).toDate()
            ),
            data: Object.keys(res.data)
              .sort(
                (a, b) => dayjs(`1 ${a}`).toDate() - dayjs(`1 ${b}`).toDate()
              )
              .map((key) => res.data[key]),
          },
        });
      });
  }

  return (
    <div>
      <Line
        data={getTenants.data(t)}
        options={getTenants.options}
        width={400}
        height={100}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Tenants);
