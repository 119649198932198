import Dashboard from "./views/Dashboard.js";
import Notifications from "./views/Notifications.js";
import ScheudulePayment from "./views/ScheudulePayment.js";
import Records from "./views/Records";

import UserPage from "./views/User.js";

import Payments from "./views/Payments";
import PayInfo from "./views/PayInfo.js";
import CalenderComponent from "./components/Calendar/CalenderComponent.js";
import TenantSubscriptions from "./views/TenantSubscriptions.js";
import ChangePassword from "./views/ChangePassword.js";
import TenantApartments from "./views/TenantApartments";

var routes = [
  {
    path: "/dashboard",
    name: "Home",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/tenant",
  },
  {
    path: "/apartments",
    name: "Apartments",
    icon: "nc-icon nc-bank",
    component: TenantApartments,
    layout: "/tenant",
  },
  {
    path: "/user-page",
    name: "Profile",
    icon: "nc-icon nc-single-02",
    component: UserPage,
    layout: "/tenant",
  },

  {
    path: "/payments",
    name: "Payments",
    icon: "nc-icon nc-pin-3",
    component: Payments,
    layout: "/tenant",
  },
  {
    path: "/subscription",
    name: "Subscriptions",
    icon: "nc-icon nc-pin-3",
    component: TenantSubscriptions,
    layout: "/tenant",
  },
  {
    path: "/schedule",
    name: "Schedule",
    icon: "nc-icon nc-pin-3",
    component: ScheudulePayment,
    layout: "/tenant",
  },
  {
    path: "/records",
    name: "Records",
    icon: "nc-icon nc-pin-3",
    component: Records,
    layout: "/tenant",
  },
  {
    path: "/pay",
    name: "Pay",
    icon: "nc-icon nc-pin-3",
    component: PayInfo,
    layout: "/tenant",
  },
  {
    path: "/calendar",
    name: "Calendar",
    icon: "fa fa-calendar",
    component: CalenderComponent,
    layout: "/tenant",
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: "nc-icon nc-bell-55",
    component: Notifications,
    layout: "/tenant",
  },
  {
    path: "/change-password",
    name: "Change Password",
    icon: "nc-icon nc-pin-3",
    component: ChangePassword,
    layout: "/tenant",
  },
];
export default routes;
