import React, { useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { serverAddress } from "../config/Config";

// reactstrap components
import { Card, CardBody, Table, Row, Col } from "reactstrap";

function UnclearedMembers({ user }) {
  const [state, setstate] = useState({
    tenants: [],
    flag: false,
  });
  let { tenants, flag } = state;
  if (user && !flag) {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    axios
      .get(`${serverAddress}/admin/metric/un-cleared`, config)
      .then((res) => {
        console.log(res.data);
        setstate({ ...state, tenants: res.data, flag: true });
      });
  }
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Name</th>
                      <th>Apartment</th>

                      <th>Phone Number</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tenants.map((t) => (
                      <tr>
                        <td>{`${t.firstname} ${t.lastname}`}</td>
                        <td>{t.apartment}</td>

                        <td>{t.phone}</td>
                        <td>{t.balance}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(UnclearedMembers);
