import React, { useState } from "react";
import axios from "axios";
import { serverAddress } from "../config/Config";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Button,
  Row,
  Col,
} from "reactstrap";
import { useHistory } from "react-router-dom";

import { setSelectedApartment } from "../actions/selecteApartment";
import { toast } from "react-toastify";
import { setDialog } from "../actions/dialog";
import Dialog from "../components/Dialog/Dialog";

const Apartments = ({ user, setSelectedApartment, setDialog }) => {
  let history = useHistory();
  const [state, setstate] = useState({
    apartments: [],
    flag: false,
    id: "",
    pageCount: 0,
    pageSize: 25,
    pageData: [],
  });

  let { apartments, flag, id,pageCount, pageSize, pageData } = state;

  if (user && !flag) {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    axios
      .get(`${serverAddress}/admin/apartments`, config)
      .then((res) => setstate({ ...state, apartments: res.data, flag: true,pageCount: Math.ceil(res.data.length / pageSize),
      pageData: res.data.slice(0, 0 + pageSize) }));
  }
  let handlePageClick = (pageData) => {
    let selected = pageData.selected;
    let offset = selected * pageSize;
    let a = apartments.slice(offset, offset + pageSize);

    setstate({ ...state, pageData: a });
  }
  let deleteApartment = (id) => {
    axios.post(`${serverAddress}/apartments/delete`, { id: id }).then((res) => {
      console.log(res.data);
      toast.warning("Apartment deleted successfuly");
      setstate({
        ...state,

        flag: false,
      });
    });
  };

  let verifyApartment = (id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    axios
      .post(
        `${serverAddress}/admin/apartments/verify-apartment`,
        {
          apartment_id: id,
        },
        config
      )
      .then((res) => {
        toast.warning("Apartment Verified successfuly");
        setstate({
          ...state,

          flag: false,
        });
      });
  };
  return (
    <>
      <Dialog param={id} callback={deleteApartment} />

      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <Row>
                  <Col md={6}>
                    <CardTitle tag="h4">Apartments</CardTitle>
                  </Col>
                  <Col md={6}>
                    <div className="update ml-auto mr-auto">
                      <Button
                        onClick={(e) => {
                          setSelectedApartment({});
                          history.push("add-apartments");
                        }}
                        className="btn-round"
                        color="primary"
                      >
                        Add Apartment
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Name</th>
                      <th>Location</th>
                      <th>Number of Houses</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pageData.map((a) => {
                      return (
                        <tr>
                          <td>
                            <Link
                              onClick={(event) => setSelectedApartment(a)}
                              to={"/admin/selected-apartment"}
                            >
                              {a.name_of_apartment}
                            </Link>
                          </td>
                          <td>{a.location}</td>
                          <td>{a.no_of_houses}</td>
                          <td>{a.status}</td>
                          <td>
                            <div className="update ml-auto mr-auto">
                              <Button
                                onClick={(e) => {
                                  setSelectedApartment(a);
                                  history.push("add-apartments");
                                }}
                                className="btn-round"
                                color="primary"
                              >
                                Edit Apartment
                              </Button>

                              {a.status === "PENDING" ? (
                                <Button
                                  onClick={(e) => {
                                    setstate({ ...state, id: a.apartment_id });
                                    verifyApartment(a.apartment_id);
                                  }}
                                  className="btn-round"
                                  color="primary"
                                >
                                  Verify Apartment
                                </Button>
                              ) : null}
                              <Button
                                onClick={(e) => {
                                  setstate({ ...state, id: a.apartment_id });
                                  setDialog({
                                    message: `Do you want to delete apartment ${a.name_of_apartment} `,
                                    show: true,
                                  });
                                }}
                                className="btn-round"
                                color="danger"
                              >
                                Delete Apartment
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <ReactPaginate
                previousLabel={"<<"}
                nextLabel={">>"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { setSelectedApartment, setDialog })(
  Apartments
);
