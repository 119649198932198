import React, { useState } from "react";
// react plugin used to create charts
import CountUp from "react-countup";

import { serverAddress } from "../config/Config";
import { connect } from "react-redux";
import Tenants from "./Tenants";

// reactstrap components
import UnclearedMembers from "./UnclearedMembers";
import Invoices from "./Invoices";
import Loans from "./Loans";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
// core components
import AppartmentStats from "./AppartmentStats";

import axios from "axios";
import { useHistory } from "react-router";
import UserSubscription from "../components/Subscription/UserSubscription";

function Dashboard({ user }) {
  let history = useHistory();
  const [state, setstate] = useState({
    flag: false,
    metric: null,
  });

  let { flag, metric } = state;
  if (user && !flag) {
    if (user.type === "TENANT") {
      if (
        !user.location ||
        !user.occupation ||
        !user.address ||
        !user.placeOfWork ||
        !user.emergency_contact ||
        !user.apartmentUnit ||
        !user.id_number === 0
      ) {
        history.push("user-page");
      }
    }

    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    if (user.type === "TENANT") {
      axios.get(`${serverAddress}/tenant/metric`, config).then((res) => {
        setstate({ ...state, metric: res.data, flag: true });
      });
    } else {
      axios.get(`${serverAddress}/admin/metric`, config).then((res) => {
        setstate({ ...state, metric: res.data, flag: true });
      });
    }
  }

  return (
    <div className="content">
      <Row>
        <Col lg="3" md="6" sm="6">
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col md="4" xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-globe text-warning" />
                  </div>
                </Col>
                <Col md="8" xs="7">
                  <div className="numbers">
                    <p className="card-category">Total Amount Paid</p>
                    <CardTitle tag="p">
                      Ksh <CountUp end={metric ? metric.paid : 0} />
                    </CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col lg="3" md="6" sm="6">
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col md="4" xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-money-coins text-success" />
                  </div>
                </Col>
                <Col md="8" xs="7">
                  <div className="numbers">
                    <p className="card-category">Expected Amount</p>
                    <CardTitle tag="p">
                      Ksh <CountUp end={metric ? metric.expected : 0} />
                    </CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        {user && user.type === "TENANT" ? (
          <Col lg="6" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <UserSubscription />
              </CardBody>
            </Card>
          </Col>
        ) : null}

        {user && user.type !== "TENANT" ? (
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-vector text-danger" />
                    </div>
                  </Col>

                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Apparments Count</p>
                      <CardTitle tag="p">
                        <CountUp end={metric ? metric.apartments : 0} />
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        ) : null}
        {user && user.type !== "TENANT" ? (
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-favourite-28 text-primary" />
                    </div>
                  </Col>

                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Tenants Count</p>
                      <CardTitle tag="p">
                        <CountUp end={metric ? metric.tenants : 0} />
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        ) : null}
      </Row>

      {user && user.type === "TENANT" ? (
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Invoices</CardTitle>
              </CardHeader>
              <CardBody>
                <Invoices />
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fa fa-history" /> Updated 3 minutes ago
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      ) : null}
      {user && user.type === "TENANT" ? (
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Loans</CardTitle>
              </CardHeader>
              <CardBody>
                <Loans />
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fa fa-history" /> Updated 3 minutes ago
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      ) : null}
      {user && user.type !== "TENANT" ? (
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Uncleared Members</CardTitle>
              </CardHeader>
              <CardBody>
                <UnclearedMembers />
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fa fa-history" /> Updated 3 minutes ago
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      ) : null}
      {user && user.type !== "TENANT" ? (
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Tenants</CardTitle>
              </CardHeader>
              <CardBody>
                <Tenants />
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fa fa-history" /> Updated 3 minutes ago
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      ) : null}
      {user && user.type !== "TENANT" ? (
        <Row>
          <Col md="12">
            <Card className="card-chart">
              <CardHeader>
                <CardTitle tag="h5">Appartments</CardTitle>
                <p className="card-category">Monthly appartments count</p>
              </CardHeader>
              <CardBody>
                <AppartmentStats />
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Dashboard);
