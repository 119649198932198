import React from "react";
import Contact from "./Contact";
import "./policy.css";

const Policy = () => {
  return (
    <div className="policy">
      <Contact />
    </div>
  );
};

export default Policy;
