/*eslint-disable*/
import React, { useState } from "react";

import { CardTitle, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import dayjs from "dayjs";
import { serverAddress } from "../config/Config";
import axios from "axios";
import { useHistory } from "react-router";

const Notifications = ({ user }) => {
  let history = useHistory();
  const [state, setstate] = useState({
    notifications: [],
    flag: false,
  });
  let { flag, notifications } = state;

  if (user && !flag) {
    if (user.type === "TENANT") {
      if (
        !user.location ||
        !user.occupation ||
        !user.address ||
        !user.placeOfWork ||
        !user.emergency_contact ||
        !user.apartmentUnit ||
        !user.id_number === 0
      ) {
        history.push("user-page");
      }
    }
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    axios.get(`${serverAddress}/notifications`, config).then((res) => {
      console.log(res.data);
      setstate({ ...state, notifications: res.data, flag: true });
    });
  }

  return (
    <>
      <div className="content">
        <h4>Your Notifications</h4>
        <Row>
          {notifications.map((n) => (
            <Col md="10" style={{ margin: "auto" }}>
              <div>
                <div className="notification">
                  <Row>
                    <Col className="ml-auto mr-auto " md="10">
                      <CardTitle tag="h5">{n.title}</CardTitle>
                    </Col>
                    <p>{n.message}</p>
                    <div className="notification-action">
                      <p>{dayjs(n.timestamp).format("D MMM , YYYY h:mm A")}</p>
                    </div>
                  </Row>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Notifications);
