import React from "react";
import "./platform.css";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import { BiTimeFive } from "react-icons/bi";
import { FaUserCheck } from "react-icons/fa";
import { FcCalendar } from "react-icons/fc";

const Platform = () => {
  let history = useHistory();
  return (
    <div className="platform">
      <div className="main">
        <h1 style={{ color: "#ee721c" }}>
          We work with you to ensure you can pay rent on time, every month.
        </h1>
        <h5>
          With Rentizy, you can alleviate the stress that comes with lump-sum
          payments and avoid late fees when life gets in the way. We help you
          build towards a better financial future with budgeting, automated
          payments, and rent reporting.
        </h5>
        <Button
          onClick={(e) => history.push("/register")}
          className="btn-round"
          color="primary"
        >
          Join Now
        </Button>
      </div>
      <div className="more-content">
        <h4>Why Choose Rentizy</h4>
        <div className="more">
          <div>
            <BiTimeFive size={50} style={{ margin: "auto" }} />
            <h4>No more bulk payments.</h4>
            <p>
              You pay your rent evenly over the month, rather than all at once.
              This helps reduce the time and stress of thinking about your
              finances.
            </p>
            <div>
              <Button
                onClick={(e) => history.push("/register")}
                className="btn-round"
                color="primary"
              >
                Learn More
              </Button>
            </div>
          </div>
          <div>
            <FaUserCheck size={50} style={{ margin: "auto" }} />
            <h4>We’ve got your back.</h4>
            <p>
              We know life can bring us all surprises - especially financial
              ones. With Rent Protection, we make sure you can pay rent by the
              1st, even if you come up a little short.
            </p>
            <div>
              <Button
                onClick={(e) => history.push("/register")}
                className="btn-round"
                color="primary"
              >
                Learn More
              </Button>
            </div>
          </div>
          <div>
            <FcCalendar size={50} style={{ margin: "auto" }} />
            <h4>Build a better financial future.</h4>
            <p>
              When we partner with your property, we report your successful rent
              payments to the credit bureau. Your on-time payments help build
              your credit history.
            </p>
            <div>
              <Button
                onClick={(e) => history.push("/register")}
                className="btn-round"
                color="primary"
              >
                Learn More
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="who">
        <p>HOW IT WORKS</p>
        <h1>Rent, on your terms.</h1>
        <h5>
          Rentizy creates a payment schedule that aligns with your income to
          help you better budget and save for rent. Each payment gets reported
          to the credit bureaus, and we cover you in case of emergency!
        </h5>
      </div>

      <div className="process">
        <div className="first">
          <div className="number-badge">
            <span>1</span>
          </div>
          <h4>Apply to pay with Rentizy</h4>
          <p>
            If we partner with your landlord, we match you to the correct
            property through our quick and easy application process here!
          </p>
        </div>
        <div className="second">
          <div className="number-badge">
            <span>2</span>
          </div>
          <h4>Build your schedule.</h4>
          <p>
            We create and share a custom rent schedule that works for you. You
            review and accept it.
          </p>
        </div>
        <div className="third">
          <div className="number-badge">
            <span>3</span>
          </div>
          <h4>Take Control.</h4>
          <p>
            We automate your payments to pay rent on your new schedule. If you
            keep on track, you won't pay late fees!
          </p>
        </div>
      </div>

      <div className="testimonials">
        <h4>What People Are Saying</h4>
        <div className="t-content">
          <div>
            <p>
              “Rentizy is the best thing that has happened since this pandemic.
              They give you the option of paying your rent in pieces or all at
              once. I do not know what I would do without their help. I thank
              God for them every day.”
            </p>
            <h5>Austine Gwa ,Tenant</h5>
          </div>
          <div>
            <p>
              “Rentizy has completely altered the way we approach rent
              collection, but more importantly, it has improved our fundamental
              relationship with our residents.”
            </p>
            <h5>— Chando Steve, Landlord</h5>
          </div>
          <div>
            <p>
              “Working with Rentizy has helped my family stay in our home, pay
              on time and avoid eviction. Being able to divide my rent over the
              month to correlate with my paychecks has allowed me to be ahead of
              schedule on rent now.”
            </p>
            <h5>— Collins, Tenant</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Platform;
