import React from "react";
import { Container } from "../Landing/globalStyles";
import product from "../../assets/img/product.svg";
import {
  InfoSec,
  InfoRow,
  InfoColumn,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ImgWrapper,
  Img,
  ListItems,
  ListMenu,
} from "./Product.element";

function Product({
  primary,
  lightBg,
  topLine,
  lightTopLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
  start,
}) {
  return (
    <>
      <InfoSec>
        <Container>
          <InfoRow imgStart={imgStart}>
            <InfoColumn>
              <TextWrapper>
                <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
                <Heading>{headline}</Heading>
                <Subtitle>{description}</Subtitle>
              </TextWrapper>
              <ListMenu>
                <ListItems>Choose from flexible payment plans</ListItems>
                <ListItems>Reschedule payments when life happens</ListItems>
                <ListItems>
                  Protect your funds with Overdraft Protection
                </ListItems>
                <ListItems>Plan ahead with Flex’s Rainy Day mode</ListItems>
              </ListMenu>
            </InfoColumn>
            <InfoColumn>
              <ImgWrapper start={start}>
                <Img src={product} alt={alt} />
                <Img src={img} alt={alt} />
              </ImgWrapper>
            </InfoColumn>
          </InfoRow>
        </Container>
      </InfoSec>
    </>
  );
}

export default Product;
