import React from "react";
import "./review.css";

const Testimonials = () => {
  return (
    <div className="review">
      <div className="testimonials">
        <h4>What People Are Saying</h4>
        <div className="t-content">
          <div>
            <p>
              “Rentizy is the best thing that has happened since this pandemic.
              They give you the option of paying your rent in pieces or all at
              once. I do not know what I would do without their help. I thank
              God for them every day.”
            </p>
            <h5>— Austine Gwa, Tenant</h5>
          </div>
          <div>
            <p>
              “Rentizy has completely altered the way we approach rent
              collection, but more importantly, it has improved our fundamental
              relationship with our residents.”
            </p>
            <h5>— Chando Steve, Tenant</h5>
          </div>
          <div>
            <p>
              “Working with Rentizy has helped my family stay in our home, pay
              on time and avoid eviction. Being able to divide my rent over the
              month to correlate with my paychecks has allowed me to be ahead of
              schedule on rent now.”
            </p>
            <h5>— Collins, Tenant</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
