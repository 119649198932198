import React from "react";
import "./about.css";

const About = () => {
  return (
    <div className="about">
      <div className="about-main">
        <h1 style={{ color: "#ee721c" }}>
          Transforming the rental experience with personalized financial
          solutions.
        </h1>
        <h5>We’re here to help.</h5>
      </div>
      <div className="who">
        <p>WHO WE ARE</p>
        <h4>
          We are a mission-driven team that has set out to change the way
          renters pay, stay, and thrive in their home with access to financial
          services.
        </h4>
        <hr />
        <h4>Teaming up to re-define our industry.</h4>
        <div>
          <p>
            We help residents stabilize and enhance their financial lives with
            solutions to stay current on rent and weather unforeseen financial
            circumstances.
          </p>
          <p>
            We bring products to landlords that improve their ability to
            understand and work with their residents while improving the
            resident's ability to pay rent.
          </p>
        </div>
      </div>

      <div className="values">
        <p>WHAT WE STAND FOR</p>
        <h2>Our Core Values.</h2>
        <div>
          <div>
            <h4>Customer Centric</h4>
            <p>
              We strive to understand, build trust, encourage, and educate our
              three customers: residents, landlords, and our team members.
              Customer success means creating and delivering solutions only if
              they improve and enhance outcomes.
            </p>
          </div>
          <div>
            <h4>Relentless Grit</h4>
            <p>
              We are an action-oriented team that is committed to rapidly
              learning and bringing positive energy every day.
            </p>
          </div>
          <div>
            <h4>Integrity</h4>
            <p>
              We obsess over always doing what is right and best for our
              customer.
            </p>
          </div>
          <div>
            <h4>Solutions Oriented</h4>
            <p>
              We seek to understand root problems deeply. From there, we
              brainstorm solutions, communicate internally and externally to
              ideate and for buy-in, then rapidly execute.
            </p>
          </div>
          <div>
            <h4>Passionate</h4>
            <p>
              We are focused on our mission, and it grounds all the things we
              do.
            </p>
          </div>
          <div>
            <h4>Innovative</h4>
            <p>
              We are using data and analytics to innovate and create
              opportunities for consumers that have historically been left out
              of traditional banking.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
