import React from "react";
import "./selectedUser.css";
import { connect } from "react-redux";
import { FormGroup, Input } from "reactstrap";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import Payments from "../../views/Payments";

const SelectedUser = ({ user }) => {
  return (
    <>
      <div className="content selected-user">
        <Row>
          <Col md="6" style={{ margin: "auto" }}>
            <Card className="card-chart">
              <CardHeader>
                {user.type !== "ADMIN" ? (
                  <CardTitle tag="h5">Tenant</CardTitle>
                ) : (
                  <CardTitle tag="h5">Staff</CardTitle>
                )}
              </CardHeader>
              <CardBody>
                <div className="tenant">
                  <FormGroup>
                    <label>First Name</label>
                    <Input
                      disabled
                      type="text"
                      value={user.first_name}
                      required
                    />
                  </FormGroup>

                  <FormGroup>
                    <label>Last name</label>
                    <Input
                      disabled
                      type="text"
                      value={user.last_name}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <label> Email</label>
                    <Input
                      disabled
                      type="text"
                      value={user.appUser.email}
                      required
                    />
                  </FormGroup>

                  {user && user.type !== "ADMIN" ? (
                    <div>
                      <FormGroup>
                        <label> Phone number</label>
                        <Input
                          disabled
                          type="text"
                          value={user.phone_number}
                          required
                        />
                      </FormGroup>

                      <FormGroup>
                        <label> Apartment name</label>
                        <Input
                          disabled
                          type="text"
                          value={user.apartment_name}
                          required
                        />
                      </FormGroup>

                      <FormGroup>
                        <label> Unit Name</label>
                        <Input
                          disabled
                          type="text"
                          value={user.apartmentUnit}
                          required
                        />
                      </FormGroup>

                      <FormGroup>
                        <label> Address</label>
                        <Input
                          disabled
                          type="text"
                          value={user.address}
                          required
                        />
                      </FormGroup>
                      <FormGroup>
                        <label>County</label>
                        <Input
                          disabled
                          type="text"
                          value={user.county}
                          required
                        />
                      </FormGroup>
                      <FormGroup>
                        <label>Date of birth</label>
                        <Input disabled type="text" value={user.dob} required />
                      </FormGroup>

                      <FormGroup>
                        <label>Emergency contact</label>
                        <Input
                          disabled
                          type="text"
                          value={user.emergency_contact}
                          required
                        />
                      </FormGroup>
                      <FormGroup>
                        <label>Place of work</label>
                        <Input
                          disabled
                          type="text"
                          value={user.placeOfWork}
                          required
                        />
                      </FormGroup>
                    </div>
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="12">{user.type === "TENANT" ? <Payments /> : null}</Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.selectedUser,
});

export default connect(mapStateToProps)(SelectedUser);
