import axios from "axios";
import { Spinner } from "reactstrap";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";

import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { serverAddress, weekDays } from "../config/Config";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Dropdown from "react-dropdown";
import { useHistory } from "react-router";

const SchedulePayment = ({ user }) => {
  let history = useHistory();
  const [state, setstate] = useState({
    start: "",
    end: "",
    title: "",
    loading: false,
    description: "",
    eventType: "",
    flag: false,
    weekday: "",
    day: 1,
  });
  let {
    end,
    start,
    weekday,
    day,
    title,
    description,
    eventType,
    loading,
    flag,
  } = state;
  const [startDate, setStartDate] = useState(new Date());

  if (user && !flag) {
    if (user.type === "TENANT") {
      if (
        !user.location ||
        !user.occupation ||
        !user.address ||
        !user.placeOfWork ||
        !user.emergency_contact ||
        !user.apartmentUnit ||
        !user.id_number === 0
      ) {
        history.push("user-page");
      }
    }
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    axios.get(`${serverAddress}/events`, config).then((res) => {
      console.log(res.data);
      setstate({ ...state, ...res.data, flag: true });
    });
  }

  let schedulePayment = (e) => {
    e.preventDefault();
    let w = 0;
    weekDays.filter((we) => we.name === weekday).forEach((we) => (w = we));
    let obj = {
      ...state,
      dayOfMonth: Number.parseInt(day),
      dayOfWeek: Number.parseInt(w),
      start: dayjs(startDate).format(),
    };

    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    setstate({ ...state, loading: true });
    console.log(obj);

    axios
      .post(`${serverAddress}/events/create`, obj, config)
      .then((res) => {
        setstate({ ...state, ...res.data });
        toast.success("Payment schedule updated successfuly");
      })
      .catch((err) => {
        toast.error("Failed to update payment schedule");
        setstate({ ...state, loading: false });
      });
  };
  return (
    <div className="content center">
      <Row>
        <Col md="8" sm="12" style={{ margin: "auto" }}>
          <Card className="card-user">
            <CardHeader>
              <CardTitle tag="h5">Schedule Payment</CardTitle>
            </CardHeader>
            <CardBody>
              <Form onSubmit={schedulePayment}>
                <Row>
                  <Col className="pr-1" md="12">
                    <FormGroup>
                      <label>Title</label>
                      <Input
                        placeholder="Title"
                        type="text"
                        value={title}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Description</label>
                      <Input
                        placeholder="Description"
                        type="text"
                        value={description}
                        required
                      />
                    </FormGroup>
                    <label>Schedule</label>

                    <Dropdown
                      options={[
                        "WEEKLY",
                        "TWO_WEEKS",
                        "MONTHLY",
                        "THREE_MONTHS",
                      ]}
                      onChange={(e) =>
                        setstate({ ...state, eventType: e.value })
                      }
                      value={eventType}
                      placeholder="Select Scheudule"
                    />
                    {eventType === "WEEKLY" || eventType === "TWO_WEEKS" ? (
                      <Dropdown
                        options={weekDays.map((w) => w.name)}
                        onChange={(e) =>
                          setstate({ ...state, weekday: e.value })
                        }
                        value={weekday}
                        placeholder="Select week day"
                      />
                    ) : (
                      <Dropdown
                        style={{ marginTop: "1.5em" }}
                        options={[...Array(32).keys()]
                          .filter((i) => i > 0)
                          .map((i) => "" + i)}
                        onChange={(e) => setstate({ ...state, day: e.value })}
                        value={1}
                        placeholder="Select day of the month"
                      />
                    )}
                    <FormGroup>
                      <label>Start Date</label>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                      />
                    </FormGroup>

                    <FormGroup>
                      <label>From</label>
                      <Input
                        placeholder="start"
                        type="text"
                        value={` ${dayjs(start).format("D MMM , YYYY h:mm A")}`}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>To</label>
                      <Input
                        placeholder="end"
                        type="text"
                        value={` ${dayjs(end).format("D MMM , YYYY h:mm A")}`}
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <div className="update ml-auto mr-auto">
                    {!loading ? (
                      <Button
                        className="btn-round"
                        color="primary"
                        type="submit"
                      >
                        Schedule
                      </Button>
                    ) : (
                      <Spinner color="primary" />
                    )}
                  </div>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(SchedulePayment);
