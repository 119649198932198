import React from "react";
import { GrLocation } from "react-icons/gr";
import "./careers.css";

const Careers = () => {
  return (
    <div className="careers">
      <h2>Current Openings</h2>
      <div>
        <div className="job">
          <h4>Business Development Representative</h4>
          <p>
            <GrLocation size={25} />
            Nairobi, Kenya
          </p>
        </div>
        <div className="job">
          <h4>Business Development Representative</h4>
          <p>
            <GrLocation size={25} />
            Nairobi, Kenya
          </p>
        </div>
        <div className="job">
          <h4>Full Stack Software Engineer</h4>
          <p>
            <GrLocation size={25} />
            Nairobi, Kenya
          </p>
        </div>
        <div className="job">
          <h4>Growth Marketing Associate</h4>
          <p>
            <GrLocation size={25} />
            Nairobi, Kenya
          </p>
        </div>
        <div className="job">
          <h4>Onboarding and Training Associate</h4>
          <p>
            <GrLocation size={25} />
            Nairobi, Kenya
          </p>
        </div>
      </div>
    </div>
  );
};

export default Careers;
