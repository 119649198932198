import React, { useState, useEffect } from "react";
import axios from "axios";
import { serverAddress } from "../../config/Config";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setSelectedApartment } from "../../actions/selecteApartment";
import Dialog from "../Dialog/Dialog";
import { setDialog } from "../../actions/dialog";
import dayjs from "dayjs";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Button,
  Row,
  Col,
} from "reactstrap";
import { useHistory } from "react-router-dom";

import { setSelectedLandlord } from "../../actions/landlord";
import { toast } from "react-toastify";

const Landlords = ({
  user,
  setSelectedLandlord,
  setSelectedApartment,
  setDialog,
}) => {
  let history = useHistory();
  const [state, setstate] = useState({
    landlords: [],
    flag: false,
    id: "",
  });

  let { landlords, flag, id } = state;

  useEffect(() => {
    landlords.forEach((l) => {
      axios
        .get(`${serverAddress}/owner/myapartments/${l.owner_id}`)
        .then((res) => {
          l.apartments = res.data;
          setstate((s) => {
            return { ...s, landlords: landlords };
          });
        });

      return l;
    });
  }, [landlords]);

  if (user && !flag) {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    axios.get(`${serverAddress}/admin/owners`, config).then((res) => {
      setstate({ ...state, landlords: res.data, flag: true });
    });
  }
  let deleteLandlord = (id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    axios
      .post(
        `${serverAddress}/owner/delete`,
        {
          id: id,
        },
        config
      )
      .then((res) => {
        toast.warning("Landlord deleted successfuly");
        setstate({
          ...state,
          flag: false,
        });
      });
  };
  return (
    <>
      <Dialog param={id} callback={deleteLandlord} />
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <Row>
                  <Col md={6}>
                    <CardTitle tag="h4">Landlords</CardTitle>
                  </Col>
                  <Col md={6}>
                    <div className="update ml-auto mr-auto">
                      <Button
                        onClick={(e) => {
                          setSelectedLandlord({});
                          history.push("add-landlord");
                        }}
                        className="btn-round"
                        color="primary"
                      >
                        Add Landlord
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>FirstName</th>
                      <th>LastName</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Reg Date</th>
                      <th>Apartments</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {landlords.map((a) => {
                      return (
                        <tr>
                          <td>{a.first_name}</td>
                          <td>{a.last_name}</td>
                          <td>{a.email}</td>
                          <td>{a.phone_number}</td>
                          <td className="text-right">
                                {` ${dayjs(a.timestamp).format(
                                  "D MMM , YYYY h:mm A"
                                )}`}
                              </td>

                          <td>
                            {a.apartments ? (
                              <div>
                                {a.apartments.map((l) => (
                                  <span>
                                    <Link
                                      onClick={(e) => setSelectedApartment(l)}
                                      to="selected-apartment"
                                    >
                                      {`${l.name_of_apartment}`}
                                    </Link>{" "}
                                  </span>
                                ))}
                              </div>
                            ) : null}
                          </td>
                          <td>
                            <div className="update ml-auto mr-auto">
                              <Button
                                onClick={(e) => {
                                  setSelectedLandlord(a);
                                  history.push("add-landlord");
                                }}
                                className="btn-round"
                                color="primary"
                              >
                                Edit
                              </Button>
                              <Button
                                onClick={(e) => {
                                  setstate({ ...state, id: a.owner_id });
                                  setDialog({
                                    message: `Do you want to delete landlord ${a.first_name} ${a.last_name}`,
                                    show: true,
                                  });
                                }}
                                className="btn-round"
                                color="danger"
                              >
                                Delete
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {
  setSelectedLandlord,
  setSelectedApartment,
  setDialog,
})(Landlords);
