import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { connect } from "react-redux";
import logo from "../../assets/img/logo.png";
import "./nav.css";
import { useLocation } from "react-router-dom";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtnLink,
  NavItemBtn,
  Button,
} from "./Navbar.elements";

const Navbar = ({ context }) => {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const location = useLocation();

  const handleClick = () => setClick(!click);
  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };
  useEffect(() => {
    showButton();
  }, []);
  window.addEventListener("resize", showButton);
  console.log(location.pathname);
  return (
    <>
      {context === "default" ? (
        <IconContext.Provider value={{ color: "#000" }}>
          <Nav>
            <NavbarContainer>
              <NavLogo to="/">
                <img src={logo} alt="rentizy logo" style={{ height: "110%" }} />
              </NavLogo>
              <MobileIcon onClick={handleClick}>
                {click ? <FaTimes /> : <FaBars />}
              </MobileIcon>
              <NavMenu onClick={handleClick} click={click}>
                <NavItem>
                  <NavLinks to="/">
                    <span
                      className={location.pathname === "/" ? "current-nav" : ""}
                    >
                      Home
                    </span>
                  </NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks to="/tenants">
                    <span
                      className={
                        location.pathname === "/tenants" ? "current-nav" : ""
                      }
                    >
                      Tenants
                    </span>
                  </NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks to="/landlords">
                    <span
                      className={
                        location.pathname === "/landlords" ? "current-nav" : ""
                      }
                    >
                      Landlords
                    </span>
                  </NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks to="/listing">
                    <span
                      className={
                        location.pathname === "/listing" ? "current-nav" : ""
                      }
                    >
                      Listing
                    </span>
                  </NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks to="/services">
                    <span
                      className={
                        location.pathname === "/services" ? "current-nav" : ""
                      }
                    >
                      About Us
                    </span>
                  </NavLinks>
                </NavItem>
                {/* <NavItem>
                <NavLinks to="/products"></NavLinks>
              </NavItem> */}
                <NavItemBtn>
                  {button ? (
                    <NavBtnLink to="/login">
                      <Button primary>My Account</Button>
                    </NavBtnLink>
                  ) : (
                    <NavBtnLink to="/login">
                      <Button fontBig primary>
                        Sign In
                      </Button>
                    </NavBtnLink>
                  )}
                </NavItemBtn>
              </NavMenu>
            </NavbarContainer>
          </Nav>
        </IconContext.Provider>
      ) : null}
    </>
  );
};
const mapStateToProps = (state) => ({
  context: state.context,
});

export default connect(mapStateToProps)(Navbar);
