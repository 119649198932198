
export const serverAddress = "https://api.rentizy.co.ke/rentizy-backend/api";

export const weekDays = [
  { id: 0, name: "Sunday" },
  { id: 1, name: "Monday" },
  { id: 2, name: "Tuesday" },
  { id: 0, name: "Wednesday" },
  { id: 0, name: "Thursday" },
  { id: 0, name: "Friday" },
  { id: 0, name: "Saturday" },
];
