import React, { useState } from "react";
import { Row, Col } from "reactstrap";

import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { Nav } from "reactstrap";

import { Spinner } from "reactstrap";

import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import { useHistory, Link } from "react-router-dom";
import { months } from "../../config/Dates";
import { toast } from "react-toastify";
import { serverAddress } from "../../config/Config";
import { counties } from "../../config/counties";
import Dropdown from "react-dropdown";
import { connect } from "react-redux";
import dayjs from "dayjs";
import axios from "axios";
import "./register.css";
import { setUser } from "../../actions/user";
import { setContext } from "../../actions/context";

const Register = ({ setUser, setContext }) => {
  let history = useHistory();

  const [options, setOptions] = useState([]);
  const [terms, setTerms] = useState(false);
  const [state, setstate] = useState({
    phone: "",
    firstname: "",
    lastname: "",
    email: "",
    apartment: "",
    password: "",
    password1: "",
    year: "",
    month: "",
    day: "",
    county: "",
    flag: false,
    loading: false,
  });
  const [apartments, setApartments] = useState([]);
  let years = Array.from({ length: 100 }).map((d, index) => {
    return { name: new Date().getFullYear() - 18 - index };
  });

  let days = Array.from({ length: 31 }).map((d, index) => {
    return { name: index + 1 };
  });
  let {
    phone,
    firstname,
    loading,
    lastname,
    email,
    password,
    password1,
    apartment,
    county,
    day,
    month,
    year,
    flag,
  } = state;
  if (county !== "" && !flag)
    axios.get(`${serverAddress}/apartments/county/${county}`).then((res) => {
      setstate({ ...state, flag: true });
      setOptions(res.data.map((a) => a.name_of_apartment));
      setApartments(res.data);
    });

  let register = (e) => {
    e.preventDefault();

    let a = "";

    if (
      password === password1 &&
      password.length > 7 &&
      terms &&
      day !== "" &&
      month !== "" &&
      year !== ""
    ) {
      setstate({ ...state, loading: true });
      apartments
        .filter((ap) => ap.name === apartment)
        .forEach((ap) => (a = ap.address));

      let obj = {
        ...state,
        address: a,
        phone_number: phone,
        first_name: firstname,
        last_name: lastname,
        apartment_name: apartment,
        dob: dayjs(
          `${year}-${months.map((m) => m.name).indexOf(month) + 1}-${day}`
        ).format(),
      };

      axios
        .post(`${serverAddress}/auth/tenant/register`, obj)
        .then((res) => {
          axios
            .post(`${serverAddress}/login`, { email, password })
            .then((re) => {
              let config = {
                headers: {
                  Authorization: `Bearer ${re.headers.authorization}`,
                },
              };
              axios.get(`${serverAddress}/auth`, config).then((r) => {
                setUser({ ...r.data, token: re.headers.authorization });
                if (r.data.type === "ADMIN") {
                  history.push("/admin/dashboard");
                } else if (r.data.type === "TENANT") {
                  history.push("/tenant/dashboard");
                } else {
                  history.push("/owner/dashboard");
                }
                console.log(r.data.type);
                setContext("dashboard");
              });
            });
        })
        .catch((err) => {
          setstate({ ...state, loading: false });
          toast.error("Registration failed");
        });
    } else if (day === "" || month === "" || year === "") {
      toast.error("Please provide a valid date of birth");
    } else if (!terms) {
      toast.error("Please accept terms and conditions");
    } else if (password !== password1) {
      toast.error("Passwords do not match");
    } else if (password.length < 8) {
      toast.error("Password should be atleast 8 characters");
    }
  };

  return (
    <div className="register">
      <Row>
        <Col xs="12" sm="12" md="6" style={{ margin: "auto" }}>
          <div className="register-form">
            <div className="logo">
              <h4>RENTIZY</h4>
            </div>

            <Nav tabs style={{ marginBottom: "2em" }}></Nav>

            <Row>
              <Col sm="12">
                <h5>Tenant Register</h5>
                <Form onSubmit={register}>
                  <FormGroup>
                    <Label for="fmname">First Name</Label>
                    <Input
                      type="text"
                      required
                      name="firstname"
                      id="fname"
                      value={firstname}
                      onChange={(e) =>
                        setstate({ ...state, firstname: e.target.value })
                      }
                      placeholder="First Name"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="lname">Last Name</Label>
                    <Input
                      type="text"
                      required
                      name="lastname"
                      id="lname"
                      value={lastname}
                      onChange={(e) =>
                        setstate({ ...state, lastname: e.target.value })
                      }
                      placeholder="Last name"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      type="text"
                      required
                      name="email"
                      value={email}
                      onChange={(e) =>
                        setstate({ ...state, email: e.target.value })
                      }
                      id="email"
                      placeholder="Email"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="County">County</Label>

                    <Dropdown
                      options={counties}
                      onChange={(e) =>
                        setstate({ ...state, county: e.value, flag: false })
                      }
                      value={county}
                      placeholder="Select County"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="phone">Enter phone number</Label>
                    <PhoneInput
                      id="phone"
                      international
                      defaultCountry="KE"
                      flags={flags}
                      value={phone}
                      onChange={(e) => setstate({ ...state, phone: e })}
                      placeholder="Enter phone number"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Date of Birth</Label>

                    <div className="dob">
                      <Dropdown
                        value={day}
                        options={days.map((d) => "" + d.name)}
                        onChange={(e) => setstate({ ...state, day: e.value })}
                        placeholder="Select day"
                      />
                      <Dropdown
                        value={month}
                        options={months.map((m) => "" + m.name)}
                        onChange={(e) => setstate({ ...state, month: e.value })}
                        optionLabel="name"
                        placeholder="Select month"
                      />
                      <Dropdown
                        value={year}
                        options={years.map((y) => "" + y.name)}
                        onChange={(e) => setstate({ ...state, year: e.value })}
                        optionLabel="name"
                        placeholder="Select year"
                        autoWidth
                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label for="apartment">Apartment</Label>
                    {county && options.length === 0 ? (
                      <p>{`No apartments available in ${county}`}</p>
                    ) : null}

                    {options.length > 0 ? (
                      <Dropdown
                        options={options}
                        onChange={(e) =>
                          setstate({ ...state, apartment: e.value })
                        }
                        value={apartment}
                        placeholder="Select apartment"
                      />
                    ) : null}
                  </FormGroup>

                  <FormGroup>
                    <Label for="password">Password</Label>
                    <Input
                      type="password"
                      required
                      value={password}
                      onChange={(e) =>
                        setstate({ ...state, password: e.target.value })
                      }
                      name="password"
                      id="password"
                      placeholder="Password"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="password1">Confirm Password</Label>
                    <Input
                      type="password"
                      required
                      value={password1}
                      onChange={(e) =>
                        setstate({ ...state, password1: e.target.value })
                      }
                      name="password1"
                      id="password1"
                      placeholder="Confirm Password"
                    />
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="terms"
                        value={terms}
                        onChange={(e) => setTerms(e.target.value)}
                      />{" "}
                      <span>
                        By creating an account, you agree to Rentizy's{" "}
                        <Link to="/terms-of-service">Terms of Service</Link>,{" "}
                        <Link to="/esign-consent">
                          E-SIGN ACT Consent and Electronic Communications
                          Policy
                        </Link>
                        ,{" "}
                        <Link to="/consent-to-be-contacted">
                          Consent to be Contacted
                        </Link>
                        , and <Link to="/privacy-policy">Privacy Policy</Link>.
                      </span>
                    </Label>
                  </FormGroup>
                  <div className="acti0n">
                    {!loading ? (
                      <Button>Register</Button>
                    ) : (
                      <Spinner color="primary" />
                    )}
                  </div>
                  <p>
                    Already have an account? <Link to="/login">Login</Link>
                  </p>
                </Form>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default connect(null, { setUser, setContext })(Register);
