import React from "react";
import { useHistory } from "react-router-dom";
import "./landlord.css";
import CountUp from "react-countup";

import { Button } from "reactstrap";
const Landlord = () => {
  let history = useHistory();
  return (
    <div className="landlord">
      <div className="main">
        <h1>Together, let’s change the future of rent.</h1>
        <h5>
          Rentizy Improves your renter’s financial stability and your returns.
        </h5>
        <Button
          onClick={(e) => history.push("/contact")}
          className="btn-round"
          color="primary"
        >
          Join now
        </Button>
      </div>

      <div className="stats">
        <div>
          <h1>
            <CountUp end={20} />
            -
            <CountUp end={50} />%
          </h1>
          <p>Increase in on-time collections</p>
        </div>
        <div>
          <h1>
            <CountUp end={97} /> %{" "}
          </h1>
          <p>Renter payment success</p>
        </div>
        <div>
          <h1>
            <CountUp end={70} /> %{" "}
          </h1>
          <p>Reduction in time spent managing delinquency</p>
        </div>
      </div>

      <div className="work">
        <h1>Rentizy works with landlords to change the way rent works.</h1>
        <p>
          We partner with you to increase on-time collections & simplify and
          streamline your workload. Rentizy is building a new future of rent,
          with products that help 100% of renters pay rent 100% of the time.
        </p>
      </div>

      <div className="process-reg">
        <div className="first">
          <div className="number-badge">
            <span>1</span>
          </div>
          <h4>Landlords sign up for Rentizy</h4>
          <p>Landlords offer Rentizy’s personalized payment plans.</p>
          <hr />
        </div>
        <div className="second">
          <div className="number-badge">
            <span>2</span>
          </div>
          <h4>Renters apply at rentizy.co.ke</h4>
          <p>Renters fill out a 5-minute online application.</p>
          <hr />
        </div>
        <div className="third">
          <div className="number-badge">
            <span>3</span>
          </div>
          <h4>Financial Intergration</h4>
          <p>
            Rentizy ascertains and integrates renters financial information.
          </p>
          <hr />
        </div>

        <div className="third">
          <div className="number-badge">
            <span>4</span>
          </div>
          <h4>Create intelligent schedules!</h4>
          <p>
            Our proprietary underwriting builds intelligent rent schedules that:
          </p>
          <ol>
            <li>Turn delinquent renters into consistent, on-time payers</li>
            <li>Increase on-time collections 20 - 50%</li>
            <li>
              Reduce 70% of site team work spent collecting from delinquent
              renters
            </li>
          </ol>
          <hr />
        </div>
        <div className="second">
          <div className="number-badge">
            <span>5</span>
          </div>
          <h4>Rent is PAID</h4>
          <p>
            We reduce site team efforts by ledgering the payments directly to
            the property systems. Renters benefit from waived late fees and
            eviction filings when they are successful.
          </p>
        </div>
      </div>

      <div className="why-content">
        <h2>Why Choose Rentizy</h2>
        <div>
          <div>
            <h4>Completely Free</h4>
            <p>Financial convenience and hustle free.</p>
          </div>
          <div>
            <h4>Increased Cashflow</h4>
            <p>
              Offer innovative services that set you apart from your
              competition.
            </p>
          </div>
          <div>
            <h4>Analytics</h4>
            <p>Program usage and property performance analytics.</p>
          </div>
          <div>
            <h4>Increased Property Value</h4>
            <p>Lowers delinquency, bad debt and turnover.</p>
          </div>
        </div>
      </div>
      <div className="testimonials">
        <h4>What People Are Saying</h4>
        <div className="t-content">
          <div>
            <p>
              “Rentizy is the best thing that has happened since this pandemic.
              They give you the option of paying your rent in pieces or all at
              once. I do not know what I would do without their help. I thank
              God for them every day.”
            </p>
            <h5>— Austine Gwa, Tenant</h5>
          </div>
          <div>
            <p>
              “Rentizy has completely altered the way we approach rent
              collection, but more importantly, it has improved our fundamental
              relationship with our residents.”
            </p>
            <h5>— Chando Steve, Landlord</h5>
          </div>
          <div>
            <p>
              “Working with Rentizy has helped my family stay in our home, pay
              on time and avoid eviction. Being able to divide my rent over the
              month to correlate with my paychecks has allowed me to be ahead of
              schedule on rent now.”
            </p>
            <h5>— Collins, Tenant</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landlord;
