import React from "react";
import { connect } from "react-redux";
import { FaRegArrowAltCircleUp } from "react-icons/fa";
import "./scroll.css";

const ScrollTop = ({ context }) => {
  return (
    <div onClick={(e) => window.scrollTo(0, 0)} className="scroll-top">
      <FaRegArrowAltCircleUp size={25} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  context: state.context,
});

export default connect(mapStateToProps)(ScrollTop);
