import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { Card, CardBody, Row, Col } from "reactstrap";

import { FormGroup, Input } from "reactstrap";
import dayjs from "dayjs";
import axios from "axios";
import { serverAddress } from "../config/Config";

const SelectedLoan = ({ loan }) => {
  const [state, setstate] = useState({ user: null });

  let { user } = state;

  useEffect(() => {
    if (loan && loan.userId) {
      axios
        .get(`${serverAddress}/tenant/dashboard/${loan.userId}`)
        .then((res) =>
          setstate((s) => {
            return { ...s, user: res.data };
          })
        );
    }
  }, [loan]);

  return (
    <>
      <div className="content selected-loan">
        <Row>
          <Col md="12">
            <Card className="card-chart">
              <CardBody>
                <div className="tenant">
                  <Row>
                    <Col md="6">
                      <h5>Loan Details</h5>
                      <FormGroup>
                        <label>Loan Amount</label>
                        <Input
                          disabled
                          type="text"
                          value={`Ksh ${loan.loanAmount}`}
                          required
                        />
                      </FormGroup>
                      <FormGroup>
                        <label>Net Amount</label>
                        <Input
                          disabled
                          type="text"
                          value={`Ksh ${loan.netAmount}`}
                          required
                        />
                      </FormGroup>
                      <FormGroup>
                        <label>Interest Amount</label>
                        <Input
                          disabled
                          type="text"
                          value={`Ksh ${loan.interestAmount}`}
                          required
                        />
                      </FormGroup>

                      <FormGroup>
                        <label>Status</label>
                        <Input
                          disabled
                          type="text"
                          value={loan.status}
                          required
                        />
                      </FormGroup>

                      <FormGroup>
                        <label>Pay By</label>
                        <Input
                          disabled
                          type="text"
                          value={dayjs(loan.payBy).format(
                            "D MMM , YYYY h:mm A"
                          )}
                          required
                        />
                      </FormGroup>
                      {loan && loan.datePaid ? (
                        <FormGroup>
                          <label>Date paid</label>
                          <Input
                            disabled
                            type="text"
                            value={dayjs(loan.datePaid).format(
                              "D MMM , YYYY h:mm A"
                            )}
                            required
                          />
                        </FormGroup>
                      ) : null}
                    </Col>
                    {user ? (
                      <Col md="6">
                        <h5>User Details</h5>
                        <FormGroup>
                          <label>First Name</label>
                          <Input
                            disabled
                            type="text"
                            value={user.first_name}
                            required
                          />
                        </FormGroup>
                        <FormGroup>
                          <label>Last Name</label>
                          <Input
                            disabled
                            type="text"
                            value={user.last_name}
                            required
                          />
                        </FormGroup>
                        <FormGroup>
                          <label>Phone Number</label>
                          <Input
                            disabled
                            type="text"
                            value={user.phone_number}
                            required
                          />
                        </FormGroup>

                        <FormGroup>
                          <label>Email</label>
                          <Input
                            disabled
                            type="text"
                            value={user.email}
                            required
                          />
                        </FormGroup>
                      </Col>
                    ) : null}
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  loan: state.selectedLoan,
});

export default connect(mapStateToProps)(SelectedLoan);
