import React, { useState } from "react";
import { connect } from "react-redux";
import { serverAddress } from "../../config/Config";
import axios from "axios";
import { useHistory } from "react-router";
import { Button } from "reactstrap";
import dayjs from "dayjs";

const UserSubscription = ({ user }) => {
  let history = useHistory();
  const [state, setstate] = useState({
    flag: false,
    subsciption: null,
  });
  let { subsciption, flag } = state;

  if (user && !flag) {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    axios.get(`${serverAddress}/subscription/active`, config).then((res) => {
      setstate({ ...state, subsciption: res.data, flag: true });
    });
  }

  return (
    <div className="user-sub">
      {subsciption ? (
        <div>
          <h5>Your current subscription</h5>
          <p>{subsciption.name}</p>
          <p>{subsciption.description}</p>
          <p>{`Price Ksh ${subsciption.price}`}</p>
          <p>{`Start ${dayjs(subsciption.start).format(
            "D MMM , YYYY h:mm A"
          )}`}</p>
          <p>{`End ${dayjs(subsciption.end).format("D MMM , YYYY h:mm A")}`}</p>
        </div>
      ) : (
        <div>
          <h5>You do not have an active subscription</h5>
          <Button
            onClick={(e) => {
              history.push("packages");
            }}
            className="btn-round"
            color="primary"
          >
            Renew Subscription
          </Button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(UserSubscription);
