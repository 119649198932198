export const SET_USER = "SET_USER";
export const SET_CONTEXT = "SET_CONTEXT";
export const SET_SELECTED_USER = "SET_SELECTED_USER";
export const SET_SELECTED_APARTMENT = "SET_SELECTED_APARTMENT";
export const SET_SELECTED_STAFF = "SET_SELECTED_STAFF";
export const SET_SELECTED_UNIT = "SET_SELECTED_UNIT";
export const SET_SELECTED_SUBSCRIPTION = "SET_SELECTED_SUBSCRIPTION";
export const SET_SELECTED_LANDLORD = "SET_SELECTED_LORD";
export const SET_SELECTED_EVENT = "SET_SELECTED_EVENT";
export const SET_SELECTED_LOAN = "SET_SELECTED_LOAN";
export const SET_SELECTED_BALANCE = "SET_SELECTED_BALANCE";
export const SET_DIALOG = "SET_DIALOG";
