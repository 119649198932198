import React from "react";
import "./terms.css";

const TermsOfService = () => {
  return (
    <div className="terms">
      <p>
        <strong>Terms of service.</strong>
      </p>
      <p>
        <strong>Terms of service - For Site Visitors and Residents.</strong>
      </p>
      <p>
        Welcome to the Rentizy Inc. (collectively, &ldquo;Rentizy&rdquo;)
        website located at{" "}
        <a href="https://rentizy.co.ke">https://rentizy.co.ke</a> (the
        &ldquo;Site&rdquo;). Please read these Terms of Service (the
        &ldquo;Terms&rdquo;) and our{" "}
        <a href="https://www.rentizy.co.ke/privacy-policy">Privacy Policy</a>{" "}
        carefully because they govern your use of our Site and our services
        accessible via our mobile device application (the &ldquo;App&rdquo;), if
        any and when available. These Terms also govern your use of any products
        or services provided by us through the Site, App or otherwise, if any
        and when available, including any application or information relating to
        such products or services, (excluding Third-Party Services, the
        &ldquo;Products&rdquo;).
      </p>
      <p>
        To make these terms easier to read, the Site, our services and App (and
        any Product or Third-Party Services available through the Site, services
        or App) are collectively called the &ldquo;Services.&rdquo; Unless
        otherwise specified in these Terms,
      </p>
      <p>
        These terms cover each use of the Services by you, as a visitor of the
        Site or App or a resident utilizing one or more of the Products provided
        as part of the Services (&ldquo;Resident&rdquo; or &ldquo;User&rdquo;).
        If you are an owner, operator, or property manager of rental housing, or
        an individual using the Services on behalf of an owner, operator, or
        property manager of rental housing, your use of the Service is governed
        by our{" "}
        <a href="https://www.rentizy.co.ke/terms-of-service-owners-and-operators">
          Terms of Service for Owners and Operators
        </a>
        .&nbsp;
      </p>
      <p>
        You may enter additional agreements with us. If there is a conflict
        between these Terms and such other agreement, the other agreement shall
        control and take precedence over these Terms.
      </p>
      <ol>
        <li>
          <strong> Acceptance of Terms</strong>
        </li>
      </ol>
      <p>
        By accessing the Site (regardless of whether you register an account
        with us), creating an account, using the Services, clicking to accept or
        agree to these Terms when this option is made available to you (such as
        by clicking to &ldquo;Continue&rdquo; or a similar button when provided
        with access to these Terms), or using any Product or Third-Party
        Services, you accept and agree to be bound and abide by these Terms. If
        you do not agree to these Terms, do not access this Site or App, use the
        Services, click &ldquo;Continue,&rdquo; or use any Product or
        Third-Party Services.
      </p>
      <p>
        Certain features of the Services may be subject to additional
        guidelines, terms, or rules, which may be set forth in a separate
        agreement, or posted on the Site or App in connection with such
        features. All such additional terms, guidelines, and rules are
        incorporated by reference into these Terms.&nbsp;
      </p>
      <p>
        ARBITRATION NOTICE AND CLASS ACTION WAIVER: EXCEPT FOR CERTAIN TYPES OF
        DISPUTES DESCRIBED IN THE ARBITRATION SECTION BELOW, YOU AGREE THAT
        DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING, INDIVIDUAL
        ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION
        LAWSUIT OR CLASS-WIDE ARBITRATION.
      </p>
      <ol start="2">
        <li>
          <strong> Eligibility</strong>
        </li>
      </ol>
      <p>
        You may use the Services only if you: (i) are 18 years of age or older
        and legally able to form a binding contract with us, (ii) reside in the
        republic of Kenya or any of its territories or possessions to use the
        Services, and (iii) are not prohibited by law from receiving the
        Services. By using the Services, you represent and warrant that you meet
        all of these requirements. If you do not meet all of these requirements,
        you must not access or use the Services.
      </p>
      <p>
        We are based in and provide the Services from Kenya. We provide these
        Services for use only by persons located in Kenya. We make no claims
        that the Services are accessible or appropriate outside of Kenya. Access
        to the Services may not be legal by certain persons or in certain
        countries. If you access the Services from outside Kenya, you do so on
        your own initiative and are responsible for compliance with local laws.
      </p>
      <ol start="3">
        <li>
          <strong> Changes to Terms or Services</strong>
        </li>
      </ol>
      <p>
        We may change or discontinue all or any portion of the Services or these
        Terms in our sole discretion and without notice to you. We will not be
        liable to you or any third party for any modification, suspension, or
        discontinuance of the Services or any Product or component of the
        Services. If we modify these Terms, we&rsquo;ll let you know either by
        posting the modified Terms on our Site or App or through other
        reasonable means. All changes are effective immediately when we post or
        send them and apply to all use of the Services thereafter. If you
        continue to use the Services after we modify these Terms, you accept and
        agree to the changes. Any such modifications will not apply to any
        dispute between you and us arising prior to the date on which we posted
        the revised Terms incorporating such changes, or otherwise notified you
        of such modifications.
      </p>
      <p>
        We cannot guarantee the Services will be available at all times. We may
        encounter unexpected technical problems or need to conduct maintenance
        related to the Services, which may result in interruptions, delays, or
        errors. You agree that we have no liability whatsoever for any loss,
        damage, or inconvenience caused by your inability to access or use the
        Services during any downtime or discontinuance of the Services. Nothing
        in these Terms will be construed to obligate us to maintain and support
        the Services or to supply any corrections, updates, or releases in
        connection with the Services.
      </p>
      <ol start="4">
        <li>
          <strong> Creating and Maintaining your Account</strong>
        </li>
      </ol>
      <p>
        To use certain Services as a Resident, you will need to create an
        account with us through the Site or App. You will need to provide us
        with the information requested during the registration process in order
        to create an account. Certain Products available through the Services
        may require that you provide additional information beyond the
        information you provided to create an account, and you understand and
        agree that use of those Products is contingent on your providing such
        additional information. In all cases you agree to provide only accurate,
        current and complete information, and to keep your information current
        at all times. If you do not, we may suspend or terminate any account you
        have with us.&nbsp;
      </p>
      <p>
        Some functionality of the Services (including Third-Party Services) will
        involve the collection and transmission of information that personally
        identifies you (including information that we obtain directly from your
        computer or device) or your account information (collectively{" "}
        <strong>&ldquo;Your Personal Information&rdquo;</strong>). You represent
        that you are a legal owner of, and that you are authorized to provide us
        with, Your Personal Information and all other information necessary to
        facilitate your use of the Services.
      </p>
      <p>
        Please review our{" "}
        <a href="https://www.rentizy.co.ke/privacy-policy">Privacy Policy</a>{" "}
        and any privacy notice or disclosure relating to the Third-Party
        Services you apply for or obtain through the Service for more
        information about how we or our third-party providers collect, use and
        share Your Personal Information. By using the Service, you are
        consenting to our Privacy Policy, which is incorporated into these Terms
        by reference.&nbsp;
      </p>
      <p>
        For our compliance purposes and in order to provide the Services to you,
        you hereby authorize us (or our third-party service providers) to
        obtain, verify, and record information and documentation that helps us
        verify your identity and details about your Linked Account (defined
        below). When you create your account and from time to time thereafter,
        we may require you to, and you hereby agree to, provide and/or confirm
        information and documentation that will allow us to identify you, such
        as:
      </p>
      <ul>
        <li>
          - A copy of your government-issued photo ID, such as a passport or
          driver&rsquo;s license;
        </li>
        <li>
          - A copy of bank statement, Mpesa statement, affidavit, or other bill,
          dated within 3 months of our request, with your name and Kenya address
          on it; and
        </li>
        <li>
          - Such other information and documentation that we may require from
          time to time.
        </li>
      </ul>
      <ol start="5">
        <li>
          <strong> Service Offerings</strong>
        </li>
      </ol>
      <p>
        We partner with owners and managers of rental housing (each a{" "}
        <strong>&ldquo;Property&rdquo;</strong>) across Kenya to help them offer
        and administer programs for their residents. We may, from time to time
        and subject to these Terms, make available certain products, features,
        and services through and as part of the Services, including those
        products and services described below.&nbsp;
      </p>
      <p>
        <strong>5.1 Disbursement of Funds to the Property</strong>&nbsp;
      </p>
      <p>
        Rentizy will disburse funds to the Property to pay your rent only when
        there are sufficient funds in your Rentizy &ldquo;budget and save&rdquo;
        account to pay your rent in full, and you hereby authorize Rentizy to
        make such payments to your Property. If sufficient funds are present at
        the time Rentizy remits funds to your Property, Rentizy will send the
        funds to the Resident&rsquo;s Property on or before your rent due date.
        Please review Section 5.2.1 regarding what happens if you do not have
        sufficient funds in your account, and Section 5.7 regarding your
        personal responsibility to ensure your rent is always paid on time.
      </p>
      <p>
        <strong>5.2.1 Insufficient Funds</strong>
      </p>
      <p>
        If, for any reason, you do not have sufficient funds in your Budget
        &amp; Save account to cover your rent and all other fees owed to your
        Property in full by the end of the month -- e.g., there are additional
        fees applied to your rent or if you have removed funds from your Budget
        &amp; Save account such that there are not sufficient funds to cover
        your rent in full-- we will first attempt to notify you so that you can
        transfer additional funds into your Budget &amp; Save account and we can
        remit your rent payment to your Property. Incase you are unable to raise
        part or whole amount of rent due please review section 5.3 regarding
        what happens if you do not have sufficient funds in your account. As the
        Resident, you are always responsible for ensuring that your rent is paid
        on time and in full. If we do not remit payment to your property in a
        given month due to insufficient funds in your Budget &amp; Save account,
        you are solely responsible to pay your property in full in accordance
        with the terms of your lease. Rentizy will not be liable to you or the
        Property for any late or failed rent payments due to insufficiency of
        funds in your Budget &amp; Save account.&nbsp;
      </p>
      <p>
        <strong>5.3 Rent Protection Program</strong>
      </p>
      <p>The following terms are applicable to the Rent Protection Program.</p>
      <p>
        Rentizy&rsquo;s Rent Protection Program allows Residents who participate
        in the Budget &amp; Save Program to obtain a cash advance funded by
        Rentizy to pay rent on time and in full.&nbsp;
      </p>
      <p>
        <strong>
          5.3.1 Eligibility &amp; Enrollment in the Rent Protection Program
        </strong>
      </p>
      <p>
        The Rent Protection Program will only be available as an add-on to
        Rentizy&rsquo;s Budget &amp; Save Program. Eligibility for
        Rentizy&rsquo;s Rent Protection program will be determined at
        Rentizy&rsquo;s sole discretion. With the Resident&rsquo;s consent,
        Rentizy may also access credit reporting agency data to determine
        eligibility.&nbsp;
      </p>
      <p>
        A Resident may apply to participate in Rentizy&rsquo;s Rent Protection
        Program when they apply to participate in the Budget &amp; Save Program,
        or at a later time through their Rentizy account. Not all Residents may
        be eligible or approved for the Rent Protection Program.&nbsp;
      </p>
      <p>
        <strong>5.3.2 Rent Protection Advances</strong>
      </p>
      <p>
        Rentizy will notify you of the maximum amount you may opt to receive as
        an advance if Rentizy determines you are eligible to receive an advance
        as part of the Rent Protection Program.&nbsp;
      </p>
      <p>
        If Rentizy advances funds to you as part of the Rent Protection Program,
        you will be expected to repay the advanced amount over subsequent months
        in addition to paying your rent in full and on time in accordance with
        the Budget &amp; Save Program and your Budget &amp; Save payment
        schedule.&nbsp;
      </p>
      <p>
        You will not be eligible to receive another advance of funds until you
        have repaid all funds received as part of an outstanding advance.
      </p>
      <p>
        <strong>5.3.3 Rent Protection Payment Schedules</strong>
      </p>
      <p>
        After you receive an advance as part of the Rent Protection Program,
        your Budget &amp; Save payment schedule will be adjusted to help you pay
        back the advance in subsequent month(s) in addition to paying your rent
        in full and on time.&nbsp;
      </p>
      <p>
        If you are removed from the Rent Protection Program, you will be
        responsible for paying your rent directly to you Property, as well as
        repaying Rentizy for any amounts outstanding that Rentizy advanced to
        you as part of the Rent Protection Program.
      </p>
      <p>
        <strong>A note about credit reporting</strong>: Rentizy will not report
        to the credit reporting agencies any advance it makes to Residents as
        part of the Rent Protection program as a new or separate tradeline.
      </p>
      <p>
        <strong>5.4 Alternative Payment Plans&nbsp;</strong>
      </p>
      <p>
        Rentizy may work with you to accommodate an alternative payment plan if
        your Property offers or allows for alternative payment plans, such as
        paying rent in multiple installments each month (each a &ldquo;
        <strong>Flex Payment Plan</strong>&rdquo;). In order to pay your rent
        under a Flex Payment Plan, you will have to enter into a separate
        agreement with Rentizy. The terms of that separate agreement will govern
        your participation in the Flex Payment Plan <u>in addition to</u> these
        Terms. In the event of a conflict between any such separate agreement
        and these Terms, the terms of the separate agreement will take
        precedence.&nbsp;
      </p>
      <p>
        <strong>5.5 Autopay</strong>
      </p>
      <p>
        Residents who participate in the Services that involve payment of funds
        to a Property may opt to enroll enrolled in auto-pay so that funds can
        be saved to their Budget &amp; Save account in accordance with their
        payment schedule. Residents may stop auto-pay at any point and start
        making payments manually through Rentizy&rsquo;s online Resident
        dashboard or by contacting Rentizy&rsquo;s customer success team.&nbsp;
      </p>
      <p>
        <strong>5.6 Schedule adjustments</strong>
      </p>
      <p>
        If Rentizy becomes aware of changes to your upcoming rent obligations
        (eg, an increase in your monthly rent), or your payments do not process
        successfully, we can adjust your payment schedule as needed to ensure
        sufficient funds are available to pay the Property on time. Rentizy may
        make these adjustments automatically and notify you of the adjustment,
        or you may request an adjustment to your payment schedule at any
        time.&nbsp;
      </p>
      <p>
        <strong>5.7 Resident Funds &amp; Responsibility&nbsp;</strong>
      </p>
      <p>
        All funds saved into your Budget &amp; Save account remain yours until
        we remit the funds to your property. That means you can withdraw funds
        you have saved into your Budget &amp; Save account during the month;
        however, it is your responsibility to make sure you have sufficient
        funds in your Budget &amp; Save account to pay your rent in full and on
        time each month.&nbsp;
      </p>
      <p>
        In providing the Services, Rentizy has no knowledge of, and is not
        responsible for any violation of any contractual obligations you may
        have with your property. Rentizy will make reasonable efforts to ensure
        that a Resident&rsquo;s payment schedule sufficiently covers their rent
        payments and additional fees that Rentizy is aware of (such as
        utilities, parking fees, pet fees, insurance, etc). However, it is
        ultimately your responsibility as the Resident to ensure that all rent
        and fees are paid in full and on time to your Property in accordance
        with the terms of your lease. If your payment schedule doesn&rsquo;t
        cover the full amount you owe to your property or if you have removed
        funds that you previously saved to your Budget &amp; Save account, you
        must explicitly notify Rentizy and change your payment schedule to cover
        the full amount owed, OR, you must pay any additional amount owed
        directly to your Property.&nbsp;
      </p>
      <p>
        Rentizy&rsquo;s ability to provide the Services as described in these
        Terms is dependent upon your timely provision of funds and any other
        information required by Rentizy, and you, as the Resident, remain fully
        liable for payment of all amounts due under your lease, including in the
        event you fail to timely provide the funds and required information to
        Rentizy.
      </p>
      <p>
        <strong>5.8 Relationship with your Property</strong>
      </p>
      <p>
        You understand and agree that your decision to participate in the
        Services creates a relationship between you and Rentizy only, and that
        your property is not party to that arrangement. As such, you agree to
        hold your Property harmless from any Claims (as defined below) you may
        have that arise out of your participation in the Services. Your property
        is an intended third-party beneficiary of the preceding sentence.
      </p>
      <p>
        <strong>5.9 Rewards for Rent</strong>
      </p>
      <p>
        Rentizy may offer certain financial incentives and rewards to Residents
        who participate in Rentizy&rsquo;s Services (each a &ldquo;
        <strong>Reward Program</strong>&rdquo;). Rentizy has sole discretion to
        determine the availability, terms, and conditions of any Reward Program.
        Rentizy may modify or cancel any Reward Program at any time without
        notice or liability to any Residents. Rentizy&rsquo;s current Reward
        Program is outlined below.&nbsp;
      </p>
      <ul>
        <li>
          If a Resident is successfully enrolled in the Budget &amp; Save
          Program for 12 months, they will earn Ksh. 20,000 reward.
        </li>
        <li>
          If a Resident is successfully enrolled in the Budget &amp; Save
          Program for 24 months, they will earn a Ksh. 50,000 bonus reward.
        </li>
        <li>
          All Residents who are: (1) enrolled in the Budget &amp; Save Program
          for at least one full month before the month Rentizy announces FREE
          Rent Promotion winners, and (2) still enrolled in the Budget &amp;
          Save Program at the time Rentizy announces winners for a given month
          will be eligible for Rentizy&rsquo;s Monthly FREE Rent Promotion. For
          the FREE Rent Promotion, Rentizy will choose three (3) Residents who
          will each have their rent paid by Rentizy.
        </li>
        <li>
          If a Resident is successfully enrolled in the Budget &amp; Save
          Program for 12 months, they will earn 1-month FREE rent if they are
          moving to an already participating property, if the property
          isn&rsquo;t participating yet, the renter/resident can contact Rentizy
          to bring it on board and the renter/resident will qualify
          automatically for the offer
        </li>
      </ul>
      <ul>
        <li>
          <strong>
            5.10 The Services Are for General Informational Purposes Only
          </strong>
        </li>
      </ul>
      <p>
        Rentizy is not a financial advisor, lawyer, tax professional, or broker,
        and the Services are not intended to provide financial, legal or tax
        advice. Any information you receive as part of the Services is intended
        as general information only, and is not a substitute for personalized
        professional advice based on your individual situation. The Services are
        intended only to provide general assistance in organizing your finances
        and helping you budget. Before making any financial decisions or
        implementing any particular strategy, you should consider obtaining
        additional information and advice from your accountant or other
        financial advisers who are fully aware of your individual circumstances.
      </p>
      <ol start="6">
        <li>
          <strong> Platform Fees</strong>
        </li>
      </ol>
      <p>
        Rentizy will charge a flat monthly fee (&ldquo;
        <strong>Rentizy Fee</strong>&rdquo;) for a Resident to participate in
        the Budget &amp; Save Program and the Rent Protection Program
        (collectively, the &ldquo;<strong>Subscription Services</strong>
        &rdquo;). Rentizy may modify fees in its discretion, but will attempt to
        notify you prior to fee modifications. Any fees that you owe to Rentizy
        for the Services will be charged and collected separately from any
        amounts collected and paid to your property for your rent.&nbsp;
      </p>
      <p>
        If you enroll in the Subscription Services, you authorize Rentizy to
        electronically bill/debit your designated payment method for the Rentizy
        Fee each month. You acknowledge that, as applicable, the electronic
        authorization contained in this Section represents your written
        authorization for automated clearinghouse (&ldquo;ACH&rdquo;) and debit
        card transactions as provided herein and will remain in full force and
        effect until you notify Rentizy that you wish to revoke this
        authorization by contacting Rentizy&rsquo;s support team at{" "}
        <a href="mailto:info@rentizy.co.ke">info@rentizy.co.ke</a>. You warrant
        and represent to Rentizy that you have the right to authorize Rentizy to
        charge and credit your designated payment method for payments due to us
        under these Terms.&nbsp;
      </p>
      <ol start="7">
        <li>
          <strong> Third-Party Services</strong>
        </li>
      </ol>
      <p>
        Through the Service, we may make products and services available to you
        that are provided by a third-party provider; or, we may utilize
        technology or technical integrations provided by third-party providers
        to help facilitate the Service (collectively,{" "}
        <strong>&ldquo;Third-Party Services&rdquo;</strong>).&nbsp;
      </p>
      <p>
        For example, we use Third Party Services to access and facilitate
        integrations with one or more accounts you maintain with a financial
        institution (each a &ldquo;<strong>Linked Account</strong>&rdquo;).{" "}
        <strong>
          Rentizy does not store your Mpesa/bank login information or access
          credentials.
        </strong>{" "}
        Each Linked Account must be a personal account with you as an account
        holder, and may not be a third-party account. If the Linked Account is a
        joint account with more than one account holder, you agree to indemnify
        and hold us harmless from any Claim of an account holder other than you
        with respect to such Linked Account.&nbsp;
      </p>
      <p>
        When you add a Linked Account to the Services, you authorize and direct
        us and our third-party service providers to access information,
        databases and other services relating to the Linked Account, and to
        retrieve and use your information from those sites, databases and other
        services for the purposes of providing our Services.&nbsp;
      </p>
      <p>
        You give us and our Third-Party Service providers a limited power of
        attorney, and appoint us and our Third-Party Service providers as your
        true and lawful attorney-in-fact and agent to act on your behalf and
        access, transmit, and use your information as necessary to provide the
        Services. You agree to the transfer, storage, and processing of your
        information by the Third-Party Service providers in accordance with
        their respective privacy policies, including Plaid&rsquo;s privacy
        policy (as described in more detail below). We have no liability to you
        for any damages you may suffer as a result of any such Third-Party
        Service provider&rsquo;s actions or inactions or from inaccurate account
        information. We make no effort to review information obtained from the
        financial institution holding your Linked Account and other third-party
        websites and databases for any purpose, including, but not limited to,
        accuracy, legality, or non-infringement.
      </p>
      <p>
        A few Third-Party Services we use are provided by Dwolla, Loanpro, and
        Plaid.
      </p>
      <p>
        <strong>7.1.1. Dwolla</strong>
      </p>
      <p>
        We have partnered with Dwolla to provide a fast and easy way for you to
        transfer funds to your Landlord if you participate in the Budget &amp;
        Save Program or Rent Protection Program. We expect our integration with
        Dwolla to be complete later this year. If you apply to use the payment
        functionality of our Service, you will open an account at Dwolla in your
        name through our Service at the time your Rentizy account is
        created.&nbsp;
      </p>
      <p>
        In order to use the payment functionality of our Service, you must open
        a "Dwolla Platform" account provided by Dwolla, Inc. and you must accept
        the Dwolla{" "}
        <a href="https://www.dwolla.com/legal/tos">Terms of Service</a> and{" "}
        <a href="https://www.dwolla.com/legal/privacy">Privacy Policy</a>. Any
        funds held in the Dwolla account are held by Dwolla's financial
        institution partners as set out in the Dwolla{" "}
        <a href="https://www.dwolla.com/legal/tos">Terms of Service</a> . You
        authorize us to collect and share with Dwolla your personal information
        including full name, date of birth, social security number, physical
        address, email address and financial information, and you are
        responsible for the accuracy and completeness of that data. You
        understand that you will access and manage your Dwolla account through
        our Service, and Dwolla account notifications will be sent by us, not
        Dwolla. We will provide customer support for your Dwolla account
        activity, and can be reached at www.rentizy.co.ke,{" "}
        <a href="mailto:info@rentizy.co.ke">info@rentizy.co.ke</a> and/or
        678-506-0269.
      </p>
      <p>
        <strong>7.1.2. Plaid</strong>
      </p>
      <p>
        Plaid allows us to link to your bank account to help us facilitate the
        Services.
      </p>
      <p>
        By submitting your information, which may include your bank account
        credentials, to Plaid, you acknowledge and agree to the terms of{" "}
        <a href="https://plaid.com/legal/#end-user-privacy-policy">
          Plaid&rsquo;s Privacy Policy
        </a>
        , and you expressly grant Plaid the right, power, and authority to
        access and transmit your information to us as reasonably necessary to
        provide the Service to you. We do not collect or maintain your bank
        account credentials when you submit them to Plaid for the purpose of
        account linking. You can read more about what information we collect
        from Plaid in our Privacy Policy.
      </p>
      <p>
        <strong>7.1.3. No Responsibility for Third-Party Services</strong>
      </p>
      <p>
        We do not control or endorse, nor are we responsible for, any
        Third-Party Services, including the accuracy, validity, timeliness,
        completeness, reliability, integrity, quality, legality, usefulness or
        safety of Third-Party Services, or any intellectual property rights
        therein. Certain Third-Party Services may, among other things, be
        inaccurate, misleading or deceptive. Nothing in these Terms shall be
        deemed to be a representation or warranty by us with respect to any
        Third-Party Services. We have no obligation to monitor Third Party
        Services, and we may block or disable access to any Third-Party Services
        (in whole or part) through the Service at any time. In addition, the
        availability of any Third-Party Services through the Service does not
        imply our endorsement of, or our affiliation with, any provider of such
        Third-Party Service. YOUR USE OF ANY THIRD-PARTY SERVICE IS AT YOUR OWN
        RISK AND IS SUBJECT TO ANY ADDITIONAL TERMS, CONDITIONS AND POLICIES
        APPLICABLE TO SUCH THIRD-PARTY SERVICE (SUCH AS TERMS OF SERVICE OR
        PRIVACY POLICIES OF THE PROVIDERS OF SUCH THIRD-PARTY SERVICE).
      </p>
      <p>
        <strong>7.1.4. Third-Party Sites</strong>
      </p>
      <p>
        The Service may contain links and other functionality that connect with
        certain sites and applications not provided by us, including social
        media sites and sites hosted by a Third-Party Service provider
        (&ldquo;Third Party Sites&rdquo;). We are providing these links and
        functionality solely as a convenience to you. We are not responsible for
        and have no liability for the content, features, products, services,
        privacy policies or terms of service of any Third-Party Sites. The fact
        that we have provided a link to a Third-Party Site is not an endorsement
        of that Third-Party Site (including any information or content made
        available throughout such site) or its owners, sponsors or operators. We
        have not tested all information, software or products found on any
        Third-Party Site and therefore do not make any representations about
        those sites or any associated products or services.
      </p>
      <ol start="8">
        <li>
          <strong> License to Services</strong>
        </li>
      </ol>
      <p>
        The Services, including the Site and the App, and their entire contents,
        features and functionality (including to all information, software,
        text, displays, images, video and audio, and the design, selection and
        arrangement thereof), are owned by us or our licensors or other
        providers of such material and are protected by Kenya and international
        copyright, trademark, patent, trade secret and other intellectual
        property or proprietary rights laws. We hereby grant you a limited,
        personal, non-exclusive, non-transferable, non-sublicensable, revocable
        license to use the Services for your personal, non-commercial use only.
      </p>
      <ol start="9">
        <li>
          <strong> User Contributions</strong>
        </li>
      </ol>
      <p>
        <strong>9.1. In General</strong>
      </p>
      <p>
        The Services may enable you to post, submit, publish, display or
        transmit to other persons text, photos, videos, links, information,
        ideas, suggestions, content or materials (collectively,{" "}
        <strong>&ldquo;User Contributions&rdquo;</strong>) on or through the
        Services, including through our interactive features or functionality
        such as support tickets, surveys, chat features, message boards, chat
        rooms, and other interactive features, if any and when available
        (collectively, &ldquo;Interactive Services&rdquo;).&nbsp;
      </p>
      <p>
        By providing any User Contributions through use of the Services, you
        hereby grant us a worldwide, royalty-free, fully paid-up, non-exclusive,
        perpetual, irrevocable, transferable and fully sublicensable (through
        multiple tiers) license, without additional consideration to you or any
        third party, to use, copy, reproduce, modify, create derivative works
        based upon, perform, publicly display, distribute and otherwise disclose
        your User Contributions for any purpose.
      </p>
      <p>
        You are solely responsible for your User Contributions. You represent
        and warrant that you own or control all rights in and to your User
        Contributions and have the right to grant the license granted above to
        us. You also represent and warrant that neither your User Contributions,
        nor your use and provision of your User Contributions to be made
        available through the Services, nor any use of your User Contributions
        by us on or through the Services will infringe, misappropriate or
        violate a third party&rsquo;s intellectual property rights, or rights of
        publicity or privacy, or result in the violation of any applicable law
        or regulation. User Contributions must in their entirety comply with all
        applicable federal, state, local and international laws and regulations.
      </p>
      <p>
        You understand and acknowledge that you are responsible for any User
        Contributions you submit or contribute, and you, not the Company, have
        full responsibility for such content, including its legality,
        reliability, accuracy and appropriateness. We are not responsible, or
        liable to any third party, for the content or accuracy of any User
        Contributions posted by you or any other user of the Services.
      </p>
      <p>
        <strong>9.2. Monitoring and Enforcement</strong>
      </p>
      <p>We have the right to:</p>
      <ul>
        <li>
          Remove or refuse to post any User Contributions for any or no reason
          in our sole discretion.
        </li>
        <li>
          Take any action with respect to any User Contribution that we deem
          necessary or appropriate in our sole discretion, including if we
          believe that such User Contribution violates these Terms, infringes
          any intellectual property right or other right of any person or
          entity, threatens the personal safety of users of the Services or the
          public or could create liability for the Company.
        </li>
        <li>
          Disclose your identity or other information about you to any third
          party who claims that material posted by you violates their rights,
          including their intellectual property rights or their right to
          privacy.
        </li>
        <li>
          Take appropriate legal action, including without limitation, referral
          to law enforcement, for any illegal or unauthorized use of the
          Services.
        </li>
        <li>
          Terminate or suspend your access to all or part of the Services for
          any or no reason, including any violation of these Terms.
        </li>
        <li>
          Fully cooperate with any law enforcement authorities or court order
          requesting or directing us to disclose the identity or other
          information of anyone posting any materials on or through the
          Services.
        </li>
      </ul>
      <p>
        YOU WAIVE AND HOLD HARMLESS THE INDEMNIFIED PARTIES FROM ANY CLAIMS
        RESULTING FROM ANY ACTION TAKEN BY ANY OF THEM DURING OR AS A RESULT OF
        ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF
        INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
      </p>
      <p>
        However, we do not undertake to review any or all material before it is
        posted and cannot ensure prompt removal of objectionable material after
        it has been posted. Accordingly, we assume no liability for any action
        or inaction regarding transmissions, communications or content provided
        by any user or third party. We have no liability or responsibility to
        anyone for performance or nonperformance of the activities described in
        this section.
      </p>
      <ol start="10">
        <li>
          <strong> Prohibited Uses</strong>
        </li>
      </ol>
      <p>
        You may use the Services only for lawful purposes and in accordance with
        these Terms. You agree not to use the Services:
      </p>
      <ul>
        <li>
          In any way that violates any applicable local or international law or
          regulation (including, without limitation, any laws regarding the
          export of data or software to and from Kenya or other countries).
        </li>
        <li>
          For the purpose of exploiting, harming or attempting to exploit or
          harm minors in any way by exposing them to inappropriate content,
          asking for personally identifiable information or otherwise.
        </li>
        <li>
          To send, knowingly receive, upload, download, use or re-use any
          material which does not comply with the Content Standards set out in
          these Terms.
        </li>
        <li>
          To transmit, or procure the sending of, any advertising or promotional
          material without our prior written consent, including any &ldquo;junk
          mail&rdquo;, &ldquo;chain letter&rdquo; or &ldquo;spam&rdquo; or any
          other similar solicitation.
        </li>
        <li>
          To impersonate or attempt to impersonate us, one of our employees,
          another user or any other person or entity (including, without
          limitation, by using e-mail addresses or screen names associated with
          any of the foregoing).
        </li>
        <li>
          To engage in any other conduct that restricts or inhibits
          anyone&rsquo;s use or enjoyment of the Services, or which, as
          determined by us, may harm the us or users of the Services or expose
          them to liability.
        </li>
      </ul>
      <p>Additionally, you agree not to:</p>
      <ul>
        <li>
          Use the Services in any manner that could disable, overburden, damage,
          or impair the site or interfere with any other party&rsquo;s use of
          the Services, including their ability to engage in real time
          activities through the Site or App.
        </li>
        <li>
          Use any robot, spider or other automatic device, process or means to
          access the Services for any purpose, including monitoring or copying
          any of the material on the Site or App or otherwise available through
          the Services.
        </li>
        <li>
          Use any manual process to monitor or copy any of the material on the
          Services or for any other unauthorized purpose without our prior
          written consent.
        </li>
        <li>
          Use any device, software or routine that interferes with the proper
          working of the Services.
        </li>
        <li>
          Introduce any viruses, trojan horses, worms, logic bombs or other
          material which is malicious or technologically harmful.
        </li>
        <li>
          Attempt to gain unauthorized access to, interfere with, damage or
          disrupt any parts of the Services, the server on which the Site or App
          is stored, or any server, computer or database connected to the
          Services.
        </li>
        <li>
          Attack the Services via a denial-of-service attack or a distributed
          denial-of-service attack.
        </li>
        <li>
          Otherwise attempt to interfere with the proper working of the
          Services.
        </li>
      </ul>
      <ol start="11">
        <li>
          <strong> Termination</strong>
        </li>
      </ol>
      <p>
        We may terminate your access to and use of the Services, at our sole
        discretion, at any time and without notice to you. You may cancel your
        account at any time by contacting us via the &ldquo;Contact
        Support&rdquo; link in the help center section of the App or by emailing{" "}
        <a href="mailto:info@rentizy.co.ke">info@rentizy.co.ke</a>
      </p>
      <p>
        Upon any termination, discontinuation or cancellation of the Services or
        your Account, the following provisions of these Terms will survive:
        &ldquo;Linked Bank Account&rdquo;, &ldquo;User Contributions&rdquo;,
        &ldquo;Termination&rdquo;, &ldquo;Limitation of Liability&rdquo;,
        &ldquo;Dispute Resolution&rdquo; and &ldquo;General Terms&rdquo;.
      </p>
      <ol start="12">
        <li>
          <strong> Security of Login Credentials</strong>
        </li>
      </ol>
      <p>
        You must follow any password security guidelines that we establish. You
        agree that you are solely responsible for keeping your login credentials
        to the Services (<strong>&ldquo;Rentizy Credentials&rdquo;</strong>)
        safe and secure, and preventing unauthorized access to your Retizy
        Credentials and your account with us, including by maintaining the
        security of your computer and other devices used to access the Services.
        It is assumed that any communications received through use of your
        Rentizy Credentials were sent or authorized by you. If your Rentizy
        Credentials are compromised for any reason, you must change your
        password. In addition, you must notify us if you become aware of any
        unauthorized access to your account. YOU AGREE THAT WE ARE NOT
        RESPONSIBLE FOR THE SECURITY OF YOUR RENTIZY CREDENTIALS.
      </p>
      <ol start="13">
        <li>
          <strong> Warranties; Disclaimers</strong>
        </li>
      </ol>
      <p>
        You understand that we cannot and do not guarantee or warrant that files
        available for downloading from the internet or the Site or App will be
        free of viruses or other destructive code. You are responsible for
        implementing sufficient procedures and checkpoints to satisfy your
        particular requirements for anti-virus protection and accuracy of data
        input and output, and for maintaining a means external to Services for
        any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS
        OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR
        OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
        EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO
        YOUR USE OF THE SERVICES OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED
        ON OR LINKED TO THE SERVICES.
      </p>
      <p>
        YOUR USE OF THE PRODUCTS AND SERVICES IS ENTIRELY AT YOUR OWN RISK.
        EXCEPT AS DESCRIBED IN THESE TERMS, THE PRODUCTS AND SERVICES (INCLUDING
        ANY PRODUCTS AND SERVICES PROVIDED BY A THIRD PARTY) ARE PROVIDED
        &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE.&rdquo; TO THE MAXIMUM
        EXTENT PERMITTED BY APPLICABLE LAW, WE, OUR AFFILIATES, AND EACH OF OUR
        THIRD-PARTY PROVIDERS, SUBCONTRACTORS, LICENSORS, DISTRIBUTORS, OR
        SUPPLIERS (COLLECTIVELY <strong>&ldquo;SUPPLIERS&ldquo;</strong>)
        DISCLAIM ALL WARRANTIES, EXPRESS, IMPLIED, OR STATUTORY, INCLUDING ANY
        WARRANTY THAT THE PRODUCTS AND SERVICES ARE FIT FOR A PARTICULAR
        PURPOSE; ALL WARRANTIES OF TITLE, MERCHANTABILITY, DATA LOSS, OR
        NON-INTERFERENCE WITH OR NON-INFRINGEMENT OF ANY INTELLECTUAL PROPERTY
        RIGHTS; AND ANY GUARANTEE OF THE ACCURACY, RELIABILITY, QUALITY, OR
        CONTENT IN OR LINKED TO THE PRODUCTS OR SERVICES. WE, OUR AFFILIATES,
        AND OUR SUPPLIERS DO NOT WARRANT THE COMPREHENSIVENESS, COMPLETENESS,
        CORRECTNESS, LEGALITY, OR ACCURACY OF THE PRODUCTS AND SERVICES, OR THAT
        THE PRODUCTS AND SERVICES ARE SECURE OR FREE FROM BUGS, VIRUSES,
        INTERRUPTION, ERRORS, THEFT, OR DESTRUCTION OF YOUR COMPUTER SYSTEM OR
        OTHER DEVICE, OR LOSS OF DATA, RESULTING FROM YOUR USE OF THE PRODUCTS
        AND SERVICES OR THIRD-PARTY PRODUCTS AND SERVICES.
      </p>
      <p>
        WE, OUR AFFILIATES, AND EACH OF OUR SUPPLIERS DISCLAIM ANY
        REPRESENTATIONS OR WARRANTIES THAT YOUR USE OF THE PRODUCTS AND SERVICES
        WILL SATISFY OR ENSURE COMPLIANCE WITH ANY LEGAL OBLIGATIONS OR LAWS OR
        REGULATIONS.
      </p>
      <p>
        THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR
        LIMITED UNDER APPLICABLE LAW. IF THE EXCLUSIONS FOR IMPLIED WARRANTIES
        DO NOT APPLY TO YOU, ANY IMPLIED WARRANTIES ARE LIMITED TO 30 DAYS FROM
        YOUR FIRST USE OF THE PRODUCTS OR SERVICES TO WHICH SUCH CLAIM RELATES.
      </p>
      <ol start="14">
        <li>
          <strong> Limitation of Liability and Indemnification</strong>
        </li>
      </ol>
      <p>
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE ENTIRE LIABILITY
        OF US, OUR AFFILIATES, AND EACH OF OUR SUPPLIERS FOR ALL CLAIMS RELATING
        TO THIS AGREEMENT SHALL BE LIMITED TO Ksh 5,000. SUBJECT TO APPLICABLE
        LAW, WE, OUR AFFILIATES, AND EACH OF OUR SUPPLIERS ARE NOT LIABLE FOR
        ANY OF THE FOLLOWING: (A) INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, OR
        CONSEQUENTIAL DAMAGES; (B) DAMAGES RELATING TO FAILURES OF
        TELECOMMUNICATIONS, THE INTERNET, ELECTRONIC COMMUNICATIONS, CORRUPTION,
        SECURITY, LOSS OR THEFT OF DATA, VIRUSES, OR SPYWARE; (C) LOSS OF
        BUSINESS, REVENUE, PROFITS OR INVESTMENT; OR (D) USE OF THE PRODUCTS AND
        SERVICES WITH SOFTWARE OR HARDWARE THAT DOES NOT MEET OUR REQUIREMENTS.
        THE ABOVE LIMITATIONS APPLY EVEN IF WE AND OUR AFFILIATES AND SUPPLIERS
        HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS AGREEMENT
        SETS FORTH THE ENTIRE LIABILITY OF US, OUR AFFILIATES, AND EACH OF OUR
        SUPPLIERS, AND YOUR EXCLUSIVE REMEDY WITH RESPECT TO THE PRODUCTS AND
        SERVICES.
      </p>
      <p>
        You agree to defend, indemnify and hold harmless us, our affiliates and
        Suppliers, and our and their respective officers, directors, employees,
        contractors, agents, licensors, suppliers, successors and assigns (the{" "}
        <strong>&ldquo;Indemnified Parties&rdquo;</strong>) from and against any
        claims, demands, liabilities, damages, judgments, awards, losses, costs,
        expenses or fees (including reasonable attorneys&rsquo; fees)
        (&ldquo;Claims&rdquo;) arising out of or relating to your violation of
        these Terms or your use of the Products or Services, including your User
        Contributions or your use of any information obtained through the
        Services. In addition, you agree to indemnify, defend, and hold the
        Indemnified Parties harmless from all Claims arising out of your use of
        Third-Party Products and Services. We reserve the right, in our sole
        discretion and at our own expense, to assume the exclusive defense and
        control of any Claims. You agree to reasonably cooperate as requested by
        us or our Suppliers in the defense of any Claims.
      </p>
      <ol start="15">
        <li>
          <strong> Dispute Resolution</strong>
        </li>
      </ol>
      <p>
        This section of the Terms may be referred to as the{" "}
        <strong>"Arbitration Provision&rdquo;</strong>
      </p>
      <p>
        <strong>15.1. Agreement to Arbitrate</strong>
      </p>
      <p>
        You and Rentizy agree that any dispute, claim or controversy arising out
        of or relating to these Terms or the breach, termination, enforcement,
        interpretation or validity thereof or the use of the Services or Content
        (collectively, <strong>&ldquo;Disputes&rdquo;</strong>) will be settled
        by binding arbitration, except that each party retains the right: (i) to
        bring an individual action in small claims court and (ii) to seek
        injunctive or other equitable relief in a court of competent
        jurisdiction to prevent the actual or threatened infringement,
        misappropriation or violation of a party&rsquo;s copyrights, trademarks,
        trade secrets, patents or other intellectual property rights (the action
        described in the foregoing clause (ii), an{" "}
        <strong>&ldquo;IP Protection Action&rdquo;</strong>).
      </p>
      <p>
        Binding arbitration lets an independent third party resolve a Dispute
        without using the court system, judges or juries. Either you or we may
        require the submission of a Dispute to binding arbitration at any
        reasonable time, even if a lawsuit or other proceeding has begun. If
        either you or we don&rsquo;t submit to binding arbitration following a
        lawful demand, the one who fails to so submit bears all costs and
        expenses (including attorney&rsquo;s fees and expenses) incurred by the
        other in compelling arbitration.
      </p>
      <p>
        You will also have the right to litigate any other Dispute if you
        provide us with written notice of your desire to do so by email at
        info@rentizy.co.ke.com within thirty (30) days following the date you
        first agree to these Terms (such notice, an{" "}
        <strong>&ldquo;Arbitration Opt-out Notice&rdquo;</strong>). If you
        don&rsquo;t provide us with an Arbitration Opt-out Notice within the
        thirty (30) day period, you will be deemed to have knowingly and
        intentionally waived your right to litigate any Dispute except as
        expressly set forth in clauses (i) and (ii) above. The exclusive
        jurisdiction and venue of any IP Protection Action or, if you timely
        provide us with an Arbitration Opt-out Notice, will be the Nairobi
        County, Kenya and each of the parties hereto waives any objection to
        jurisdiction and venue in such courts. Unless you timely provide us with
        an Arbitration Opt-out
      </p>
      <p>
        Notice,{" "}
        <strong>
          YOU ACKNOWLEDGE AND AGREE THAT YOU AND RENTIZY ARE EACH WAIVING THE
          RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE AS A PLAINTIFF OR CLASS
          MEMBER IN ANY PURPORTED CLASS ACTION OR REPRESENTATIVE PROCEEDING.
          Further, unless both you and Rentizy otherwise agree in writing, the
          arbitrator may not consolidate more than one person&rsquo;s claims,
          and may not otherwise preside over any form of any class or
          representative proceeding.
        </strong>{" "}
        If this specific paragraph is held unenforceable, then the entirety of
        this &ldquo;Dispute Resolution&rdquo; section will be deemed void.
        Except as provided in the preceding sentence, this &ldquo;Dispute
        Resolution&rdquo; section will survive any termination of these Terms.
      </p>
      <p>
        <strong>15.2. Arbitration Rules</strong>
      </p>
      <p>
        The arbitration will be governed by the&nbsp;
        <a href="https://iaa-network.com/wp-content/uploads/2020/11/Kenya-Arbitration-Law.pdf">
          Arbitration Act, No. 4 of 1995
        </a>
        &nbsp;and its subsequent amendments (the &ldquo;
        <strong>Kenyan Arbitration Act</strong>&rdquo; or the &ldquo;
        <strong>KAA</strong>&rdquo;) You and we each agree that in this
        relationship:
      </p>
      <ul>
        <li>
          You and we are participating in transactions involving intercounty
          commerce.
        </li>
        <li>
          The Arbitrator will decide any Dispute regarding the enforceability of
          this Arbitration Provision.
        </li>
        <li>
          Each arbitration is governed by the provisions of the The Arbitration
          Act 1995, as amended in 2010 to the extent any provision of that Act
          is inapplicable, unenforceable or invalid, the laws governing the
          relationship between you and us about which the Dispute arose.
        </li>
      </ul>
      <p>
        <strong>15.3. Rights Preserved</strong>
      </p>
      <p>
        This Arbitration Provision and the exercise of any of the rights you and
        we have under this Arbitration Provision do not stop either of us from
        exercising any lawful rights to use other remedies available to:
      </p>
      <ul>
        <li>
          Preserve, foreclose, or obtain possession of real or personal
          property.
        </li>
        <li>
          Exercise self-help remedies, including setoff and repossession rights.
        </li>
        <li>
          Obtain provisional or ancillary remedies such as injunctive relief,
          attachment, garnishment or court appointment of a receiver by a court
          having jurisdiction.
        </li>
      </ul>
      <p>
        <strong>15.4. Fees and Expenses</strong>
      </p>
      <p>
        Arbitration fees shall be determined by KAA Rules, unless limited by
        applicable law. Please check with the KAA to determine the fees that
        apply to any arbitration you may file. If the law that applies to these
        Terms limits the amount of fees and expenses you have to pay, then no
        allocation of fees and expenses to you shall exceed this limitation.
        Unless inconsistent with applicable law, each of us shall bear the
        expense of our own attorney, expert and witness fees, regardless of
        which of us prevails in the arbitration.
      </p>
      <p>
        <strong>15.5. Miscellaneous</strong>
      </p>
      <p>
        You and we each agree to take all steps and execute all documents
        necessary for the implementation of arbitration proceedings. The
        arbitrator may hear and rule on appropriate dispositive motions as part
        of the arbitration proceeding, such as motions for judgments on the
        pleadings, summary judgment, or partial summary judgment.
      </p>
      <p>
        All parties (the arbitrators, you and we) must, to the extent feasible,
        take any action necessary to ensure that an arbitration proceeding, as
        described in this Arbitration Provision, is completed within 180 days of
        filing the Dispute. The parties must not disclose the existence,
        content, or results of the arbitration, except for disclosures of
        information required in the ordinary course of business or permitted by
        applicable law or regulation. This provision will be liberally construed
        to ensure the enforcement of this Arbitration Provision.
      </p>
      <p>
        Arbitration proceedings are conducted in the County where you reside or
        at a location determined by the <strong>arbitrator</strong> All statutes
        of limitations that apply to any Dispute apply to any arbitration
        between you and us.
      </p>
      <p>
        The provisions of this Arbitration Provision will survive termination,
        amendment, or expiration of your use of the Services, these Terms, any
        other agreement between you and us or any other relationship between you
        and us.
      </p>
      <p>
        This Arbitration Provision constitutes the entire agreement between you
        and us and supersedes all prior arrangements and other communications
        about dispute resolution. If more than one arbitration agreement
        mutually entered into by you and us potentially applies to a Dispute,
        the one most directly related to the Eligible Account or transaction
        that is the subject of the dispute will control.
      </p>
      <p>
        <strong>15.6. Changes</strong>
      </p>
      <p>
        Notwithstanding the provisions of the &ldquo;Changes to Terms or
        Services&rdquo; section above, if we change this &ldquo;Dispute
        Resolution&rdquo; section after the date you first accepted these Terms
        (or accepted any subsequent changes to these Terms), you may reject any
        such change by sending us written notice (including by email to
        info@rentizy.co.ke) within 30 days of the date such change became
        effective, as indicated in the &ldquo;Last Updated&rdquo; date above or
        in the date of our email to you notifying you of such change. By
        rejecting any change, you are agreeing that you will arbitrate any
        Dispute between you and us in accordance with the provisions of this
        &ldquo;Dispute Resolution&rdquo; section as of the date you first
        accepted these Terms (or accepted any subsequent changes to these
        Terms).
      </p>
      <ol start="16">
        <li>
          <strong> Reporting Claims of Copyright Infringement</strong>
        </li>
      </ol>
      <p>
        You may not use the Service for any purpose or in any manner that
        infringes the rights of any third party. We may terminate your access to
        the Service in the event of repeated violations.
      </p>
      <p>
        We take claims of copyright infringement seriously. We will respond to
        notices of alleged copyright infringement that comply with applicable
        law. If you believe any materials accessible on or through this Site or
        the Services infringe your copyright, you may request removal of those
        materials (or access to them) from this Site or the Services by
        submitting written notification to our copyright agent designated below.
        In accordance with the Copyright Act- Act No. 12 of 2001 must include
        substantially the following:
      </p>
      <ul>
        <li>Your physical or electronic signature.</li>
        <li>
          Identification of the copyrighted work you believe to have been
          infringed or, if the claim involves multiple works on the Website, a
          representative list of such works.
        </li>
        <li>
          Identification of the material you believe to be infringing in a
          sufficiently precise manner to allow us to locate that material.
        </li>
        <li>
          Adequate information by which we can contact you (including your name,
          postal address, telephone number, and, if available, email address).
        </li>
        <li>
          A statement that you have a good faith belief that use of the
          copyrighted material is not authorized by the copyright owner, its
          agent, or the law.
        </li>
        <li>
          A statement that the information in the written notice is accurate.
        </li>
        <li>
          A statement, under penalty of perjury, that you are authorized to act
          on behalf of the copyright owner.
        </li>
      </ul>
      <p>Our designated copyright agent to receive copyright Notices is:</p>
      <p>info@rentizy.co.ke</p>
      <p>
        If you fail to comply with all of the requirements of Section 22 of the
        Act&nbsp;your Notice may not be effective.
      </p>
      <p>
        Please be aware that if you knowingly materially misrepresent that
        material or activity available through the Services is infringing your
        copyright, you may be held liable for damages (including costs and
        attorneys&rsquo; fees) the Copyright Act- Act No. 12 of 2001
      </p>
      <ol start="18">
        <li>
          <strong> General Terms</strong>
        </li>
      </ol>
      <p>
        <strong>18.1. Governing Law</strong>
      </p>
      <p>
        All matters relating to the Services and these Terms and any dispute or
        claim arising therefrom or related thereto (in each case, including
        non-contractual disputes or claims), shall be governed by and construed
        in accordance with the internal laws of the republic of Kenya without
        giving effect to any choice or conflict of law provision or rule
        (whether of the republic of Kenya or any other jurisdiction).
      </p>
      <p>
        <strong>18.2 Waiver and Severability</strong>
      </p>
      <p>
        No waiver of by us of any term or condition set forth in these Terms
        shall be deemed a further or continuing waiver of such term or condition
        or a waiver of any other term or condition, and any failure of us to
        assert a right or provision under these Terms shall not constitute a
        waiver of such right or provision.
      </p>
      <p>
        If any provision of these Terms is held by a court or other tribunal of
        competent jurisdiction to be invalid, illegal or unenforceable for any
        reason, such provision shall be eliminated or limited to the minimum
        extent such that the remaining provisions of these Terms will continue
        in full force and effect.
      </p>
      <p>
        <strong>18.3. Entire Agreement</strong>
      </p>
      <p>
        These Terms constitute the entire and exclusive understanding and
        agreement between us and you regarding the Services and Products, and
        these Terms supersede and replace any and all prior oral or written
        understandings or agreements between us and you regarding the Services
        and Products.
      </p>
      <p>
        <strong>18.4. Assignment</strong>
      </p>
      <p>
        You may not assign or transfer these Terms, by operation of law or
        otherwise, without our prior written consent. Any attempt by you to
        assign or transfer these Terms, without such consent, will be null. We
        may freely assign or transfer these Terms without restriction. Subject
        to the foregoing, these Terms will bind and inure to the benefit of the
        parties, their successors, and permitted assigns.
      </p>
      <p>
        <strong>18.5. Notices</strong>
      </p>
      <p>
        Any notices or other communications provided by us under these Terms,
        including those regarding modifications to these Terms, will be given:
        (i) via email; or (ii) by posting to the Services. For notices made by
        e-mail, the date of receipt will be deemed the date on which such notice
        is transmitted.
      </p>
      <p>
        <strong>18.6. Contact Information</strong>
      </p>
      <p>
        If you have any questions about these Terms or the Services, please
        contact us via the &ldquo;Contact Support&rdquo; link in the help center
        section of the Site or App or by emailing{" "}
        <a href="mailto:infor@rentizy.co.ke">infor@rentizy.co.ke</a>.
      </p>
    </div>
  );
};

export default TermsOfService;
