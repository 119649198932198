import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";

function MoreInfo() {
  let history = useHistory();
  return (
    <>
      <div className="why">
        <h1>Why Choose Rentizy</h1>
        <div>
          <div>
            <h4>No more bulk payments.</h4>
            <p>
              You pay your rent evenly over the month, rather than all at once.
              This helps reduce the time and stress of thinking about your
              finances.
            </p>
          </div>
          <div>
            <h4>We meet you where you are.</h4>
            <p>
              Regardless of whether you pay on time or find yourself a little
              behind, Rentizy meets you where you are and helps you pay rent on
              your schedule.
            </p>
          </div>
          <div>
            <h4>Get back on track.</h4>
            <p>
              By avoiding late fees while enrolled, Rentizy frees up a little
              money each month to help you become an on-time payer.
            </p>
          </div>
        </div>
        <div>
          <Button
            onClick={(e) => history.push("/register")}
            className="btn-round"
            color="primary"
          >
            Get Started
          </Button>
        </div>
      </div>
    </>
  );
}

export default MoreInfo;
