import React, { useState } from "react";
import axios from "axios";
import { serverAddress } from "../config/Config";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { setSelectedBalance } from "../actions/balance";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Button,
  Row,
  Col,
} from "reactstrap";

const Records = ({ user, setSelectedBalance }) => {
  let history = useHistory();
  const [state, setstate] = useState({
    flag: false,
    data: {},
  });

  let { flag, data } = state;

  if (user && !flag) {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    let url = `${serverAddress}/admin/metric/monthly-payment`;

    if (user.type === "ADMIN") {
      url = `${serverAddress}/admin/payment/balance`;
    } else {
      url = `${serverAddress}/owner/metric/monthly-payment`;
    }
    axios.get(url, config).then((res) => {
      console.log(res.data);
      axios.get(`${serverAddress}/apartments/apartmentnames`).then((r) => {
        setstate({
          ...state,

          flag: true,
          data: res.data,
        });
      });
    });
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <CardTitle tag="h4">Balance</CardTitle>
                <div className="record-header"></div>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Apartment Name</th>
                      <th>Balance</th>
                      <th>Timestamp</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(data)

                      .map((key) => data[key])
                      .sort(
                        (a, b) =>
                          dayjs(`${b.apartment.timestamp}`).toDate() -
                          dayjs(`${a.apartment.timestamp}`).toDate()
                      )
                      .map((e) => (
                        <tr>
                          <td>{e.apartment.name_of_apartment}</td>
                          <td>{e.balance}</td>

                          <td className="text-right">
                            {` ${dayjs(e.timestamp).format(
                              "D MMM , YYYY h:mm A"
                            )}`}
                          </td>
                          {e.balance > 0 && user.type === "ADMIN" ? (
                            <td>
                              <Button
                                onClick={(event) => {
                                  setSelectedBalance(e);
                                  history.push("add-withdrawal");
                                }}
                              >
                                Withdraw
                              </Button>
                            </td>
                          ) : null}
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { setSelectedBalance })(Records);
