import React, { useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { serverAddress } from "../../config/Config";

import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { setEvent } from "../../actions/selectedEvent";

import moment from "moment";
import EventOverlay from "./EventOverlay";
import { useHistory } from "react-router";
const localizer = momentLocalizer(moment);

const CalenderComponent = ({ user, setEvent }) => {
  let history = useHistory();
  const [state, setstate] = useState({
    events: [],
    flag: false,
  });
  let { events, flag } = state;
  if (user && !flag) {
    if (user.type === "TENANT") {
      if (
        !user.location ||
        !user.occupation ||
        !user.address ||
        !user.placeOfWork ||
        !user.emergency_contact ||
        !user.apartmentUnit ||
        !user.id_number === 0
      ) {
        history.push("user-page");
      }
    }
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    axios.get(`${serverAddress}/calendar`, config).then((res) => {
      setstate({
        ...state,
        events: res.data,
        flag: true,
      });
    });
  }

  return (
    <>
      <div className="content">
        <EventOverlay />
        <Row>
          <Col md="12">
            <Card className="card-chart">
              <CardHeader>
                <CardTitle tag="h5">Calendar</CardTitle>
              </CardHeader>
              <CardBody>
                <Calendar
                  views={["month", "day"]}
                  popupOffset={{ x: 30, y: 20 }}
                  popup="true"
                  onSelectEvent={(e) => setEvent(e)}
                  localizer={localizer}
                  events={events.map((e) => {
                    e.eventClasses = "optionalEvent";
                    e.allDay = true;
                    return e;
                  })}
                  startAccessor="start"
                  endAccessor="end"
                  style={{ height: 500 }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { setEvent })(CalenderComponent);
