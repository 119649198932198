import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const Username = ({ user }) => {
  return (
    <div className="username">
      <span>Signed in as </span>{" "}
      {user && user.appUser ? (
        <span>
          {" "}
          <Link style={{ color: "#269DD9" }} to="user-page">
            {user.appUser.email}
          </Link>{" "}
        </span>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Username);
