export const counties = [
  "Mombasa County",
  "Kwale County",
  "Kilifi County",
  "Tana River County",
  "Lamu County",
  "Taita-Taveta County",

  "Garissa County",
  "Wajir County",
  "Mandera County",

  "Marsabit County",
  "Isiolo County",
  "Meru County",
  "Tharaka-Nithi County",
  "Embu County",
  "Kitui County",
  "Machakos County",
  "Makueni County",

  "Nyandarua County",
  "Nyeri County",
  "Kirinyaga County",
  "Murang’a County",
  "Kiambu County",

  "Turkana County",
  "West Pokot County",
  "Samburu County",
  "Trans Nzoia County",
  "Uasin Gishu County",
  "Elgeyo-Marakwet County",
  "Nandi County",
  "Baringo County",
  "Laikipia County",
  "Nakuru County",
  "Narok County",
  "Kajiado County",
  "Kericho County",
  "Bomet County",

  "Kakamega County",
  "Vihiga County",
  "Bungoma County",
  "Busia County",

  "Siaya County",
  "Kisumu County",
  "Homa Bay County",
  "Migori County",
  "Kisii County",
  "Nyamira County",

  "Nairobi County",
].sort((a, b) => {
  a = a.toLowerCase();
  b = b.toLowerCase();

  return b > a ? -1 : a > b ? 1 : 0;
});
