import Dashboard from "./views/Dashboard.js";
import Notifications from "./views/Notifications.js";

import Records from "./views/Records";
import Payments from "./views/Payments";
import ChangePassword from "./views/ChangePassword.js";

var routes = [
  {
    path: "/dashboard",
    name: "Home",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/owner",
  },

  {
    path: "/records",
    name: "Records",
    icon: "nc-icon nc-pin-3",
    component: Records,
    layout: "/owner",
  },
  {
    path: "/payments",
    name: "Payments",
    icon: "nc-icon nc-pin-3",
    component: Payments,
    layout: "/owner",
  },

  {
    path: "/notifications",
    name: "Notifications",
    icon: "nc-icon nc-bell-55",
    component: Notifications,
    layout: "/owner",
  },
  {
    path: "/change-password",
    name: "Change Password",
    icon: "nc-icon nc-pin-3",
    component: ChangePassword,
    layout: "/owner",
  },
];
export default routes;
