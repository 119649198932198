import React from "react";
import "./terms.css";

const LandlordTerms = () => {
  return (
    <div className="terms">
      <p>
        <strong>Terms of service - Owners and Operators.</strong>
      </p>
      <p>Last Updated: August 22, 2021</p>
      <p>
        Welcome to the Rentizy, Inc. (collectively, &ldquo;
        <strong>Rentizy</strong>&rdquo;) website located at www.rentizy.co.ke
        (the &ldquo;<strong>Site</strong>&rdquo;). Please read these Terms of
        Service (the &ldquo;<strong>Terms</strong>&rdquo;) and our{" "}
        <a href="https://www.rentizy.co.ke/privacy-policy">Privacy Policy</a>{" "}
        carefully because they govern your use of our Site and our services
        accessible via our mobile device application, if any and when available
        (the &ldquo;<strong>App</strong>&rdquo;). These Terms also govern your
        use of any products or services provided by us through the Site, App or
        otherwise, if any and when available (excluding Third-Party Services,
        the &ldquo;<strong>Products</strong>&rdquo;). To make these terms easier
        to read, the Site, our services and App (and any Product or Third-Party
        Services available through the Site, services or App) are collectively
        called the &ldquo;<strong>Services</strong>.&rdquo; We will refer to
        Rentizy (including our directors, officers, members, managers,
        employees, service providers, affiliates, successors and assigns) as
        &ldquo;our&rdquo;, &ldquo;we&rdquo;, or &ldquo;us&rdquo;; we will refer
        to users who are owners, operators, or property managers of rental
        housing, or individuals using the Services on behalf of owners,
        operators, or property managers of rental housing as &ldquo;
        <strong>you</strong>&rdquo; or &ldquo;<strong>Customer</strong>&rdquo;;
        and we will refer to residents of properties owned and/or operated by
        you as &ldquo;<strong>Residents</strong>.&rdquo;
      </p>
      <p>
        You may enter additional agreements with us. If there is a conflict
        between these Terms and such other agreement, the other agreement shall
        control and take precedence over these Terms.
      </p>
      <ol>
        <li>
          <strong> Acceptance of Terms</strong>
        </li>
      </ol>
      <p>
        These terms cover each use of the Services by you. By completing and
        submitting the Property Schedule and using the Services, clicking to
        accept or agree to these Terms when this option is made available to you
        (such as by clicking to &ldquo;Continue&rdquo; or a similar button when
        provided with access to these Terms), or using any Product or
        Third-Party Services, you accept and agree to be bound and abide by
        these Terms. If you do not agree to these Terms, do not use the
        Services, click &ldquo;Continue,&rdquo; or use any Product or
        Third-Party Services.
      </p>
      <p>
        Certain features of the Services may be subject to additional
        guidelines, terms, or rules, which may be set forth in a separate
        agreement, or posted on the Site or App in connection with such
        features. All such additional terms, guidelines, and rules are
        incorporated by reference into these Terms.
      </p>
      <ol start="2">
        <li>
          <strong> Eligibility</strong>
        </li>
      </ol>
      <p>
        You may use the Services only if you: (i) are 18 years of age or older
        and legally able to form a binding contract with us, (ii) reside in
        Kenya or any of its territories, and (iii) are not prohibited by law
        from receiving the Services. By using the Services, you represent and
        warrant that you meet all of these requirements. If you do not meet all
        of these requirements, you must not access or use the Services. If you
        use the Services on behalf of a company or other legal entity, you
        represent that you have the authority to bind such entity and its
        affiliates to these Terms, in which case the terms "you" or "your" shall
        refer to such entity and its affiliates.
      </p>
      <p>
        We are based in and provide the Services from Kenya. We provide these
        Services for use only by persons located in Kenya. We make no claims
        that the Services are accessible or appropriate outside of Kenya. Access
        to the Services may not be legal by certain persons or in certain
        countries. If you access the Services from outside Kenya, you do so on
        your own initiative and are responsible for compliance with local laws.
      </p>
      <ol start="3">
        <li>
          <strong> Changes to Terms or Services</strong>
        </li>
      </ol>
      <p>
        We may change or discontinue all or any portion of the Services or these
        Terms in our sole discretion and without notice to you. We will not be
        liable to you or any third party for any modification, suspension, or
        discontinuance of the Services or any Product or component of the
        Services.
      </p>
      <p>
        If we modify these Terms, we&rsquo;ll let you know either by posting the
        modified Terms on our Site or App or through other reasonable means. All
        changes are effective immediately when we post or send them and apply to
        all use of the Services thereafter. If you continue to use the Services
        after we modify these Terms, you accept and agree to the changes. Any
        such modifications will not apply to any dispute between you and us
        arising prior to the date on which we posted the revised Terms
        incorporating such changes, or otherwise notified you of such
        modifications.
      </p>
      <p>
        We cannot guarantee the Services will be available at all times. We may
        encounter unexpected technical problems or need to conduct maintenance
        related to the Services, which may result in interruptions, delays, or
        errors. You agree that we have no liability whatsoever for any loss,
        damage, or inconvenience caused by your inability to access or use the
        Services during any downtime or discontinuance of the Services. Nothing
        in these Terms will be construed to obligate us to maintain and support
        the Services or to supply any corrections, updates, or releases in
        connection with the Services.
      </p>
      <ol start="4">
        <li>
          <strong> Service Offerings</strong>
        </li>
      </ol>
      <p>
        We may, from time to time and subject to these Terms, make available
        certain features, products, and services through and as part of the
        Services, including those features and services described below.
      </p>
      <p>
        <strong>4.1 Budget &amp; Save Program</strong>
      </p>
      <p>
        The following terms are applicable to the Budget &amp; Save Program.
      </p>
      <p>
        Residents may opt in to Rentizy&rsquo;s Budget &amp; Save Program
        through their Rentizy account. The Budget &amp; Save Program allows
        Residents to budget and accrue funds in an escrow account; and, if
        sufficient funds are accrued, use them to pay future rent payments in
        full and on time. Residents will have the ability to withdraw submitted
        funds at any point prior to the rent being submitted. Budget &amp; Save
        escrow accounts will be set up in the Resident&rsquo;s name using
        Rentizy&rsquo;s payments and accounting partner. See Section 4.4 below
        for more details about how Rentizy handles Resident Payments.
      </p>
      <p>
        <strong>4.2 Rent Protection Program</strong>
      </p>
      <p>The following terms are applicable to the Rent Protection Program.</p>
      <p>
        Residents may opt in to Rentizy&rsquo;s Rent Protection Program through
        their Rentizy account. The Rent Protection Program allows Residents to
        obtain a Rentizyl funded advancement to pay rent on time and in full.
        With a Resident&rsquo;s consent, Rentizy may access the Resident&rsquo;s
        credit bureau data to determine eligibility for the Rent Protection
        Program. Not all Residents may be eligible or approved for the Rent
        Protection Program. Advancements will be collected by Rentizy from the
        Resident over subsequent months. See Section 4.5 below for more details
        about how Rentizy handles Resident Payments.
      </p>
      <p>
        <strong>4.3 Resident Communications </strong>
      </p>
      <p>
        Rentizy will have primary responsibility to communicate with Residents
        about the Services, and may communicate with Residents at its sole
        discretion and without restriction. Rentizy will inform each Resident by
        email, and will contact them to provide details on how to access their
        Rentizy customer account and other Rentizy Products and Services.
      </p>
      <p>
        With Customer consent, which it may withhold for any or no reason,
        Rentizy may use co-branded communications for Resident outreach efforts.
        Rentizy may request that Customer distribute physical program materials
        provided by Rentizy to its Residents or assistance in contacting
        Residents who have yet to access their Rentizy customer account.
        Customer will reasonably accommodate these requests.
      </p>
      <p>
        <strong>4.4 Resident Payments</strong>
      </p>
      <p>
        If a Resident enrolls in any Rentizy Services involving advances by
        Rentizy to Customer for the Resident&rsquo;s rent, this section applies.
        Rentizy will coordinate payments between the Resident and Customer
        through a third party payment processor. Rentizy will reflect these
        payments on the Resident&rsquo;s ledger through Customer&rsquo;s
        electronic property management system (&ldquo;<strong>ePMS</strong>
        &rdquo;) integration or, if such functionality is unavailable, will
        inform Customer of these payments via email for Customer to enter in the
        ePMS.
      </p>
      <p>
        <strong>4.5 Property Ownership Changes</strong>
      </p>
      <p>
        Customer must notify Rentizy at least thirty (30) days in advance of any
        sale or change in ownership of any Property for which Rentizy is
        providing Services. Rentizy requires this advance notice to ensure
        continuity of Services provided to Residents in the event of a change of
        ownership.
      </p>
      <ol start="5">
        <li>
          <strong> Systems Setup &amp; Data Management</strong>
        </li>
      </ol>
      <p>
        Customer must enable an application integration between Rentizy and
        Customer&rsquo;s electronic property management system (&ldquo;
        <strong>ePMS</strong>&rdquo;) for the Properties in order to provide
        Rentizy with certain data required for Rentizy to provide the Services
        to Residents. Rentizy will retrieve data from Customer through this
        integration, including Resident Data, to support provision of the
        Services to Residents. &ldquo;<strong>Resident Data</strong>&rdquo;
        means the names, personal information, address, contact information,
        lease information, current account status and balances, and rental
        performance data related to the Residents in the Properties. If Customer
        uses an ePMS with which Rentizy does not have an active integration,
        Customer will provide the Resident Data through flat file exports from
        the ePMS delivered through SFTP site, email, or otherwise as directed by
        Rentizy. Rentizy will extract, normalize, and load a copy of the
        Resident Data from Customer to a Rentizy database (with Customer&rsquo;s
        reasonable assistance).
      </p>
      <p>
        Rentizy depends on the accuracy, completeness, and integrity of the
        Resident Data provided by Customer for the Services to function
        appropriately. If Customer plans to change its ePMS or operating
        practices in a way that may impact the data it provides to Rentizy
        Customer will provide notice to Rentizy of the change two months in
        advance (or otherwise as soon as practicable).
      </p>
      <p>
        Rentizy and Customer will develop a secure mechanism for Customer to
        provide such information to Rentizy.
      </p>
      <p>
        If the Resident Data has errors (or is suspected of having errors),
        Rentizy and Customer will work together to identify the cause of the
        error and resolve it. Regardless of the mechanism or format Customer
        uses to transmit Resident Data to Rentizy, Customer shall at all times
        remain solely and exclusively responsible for the Resident Data that
        Customer provides to Rentizy Customer shall ensure that all Resident
        Data it transmits to Rentizy is complete, accurate, and compliant with
        all applicable laws.
      </p>
      <p>
        <strong>
          Rentizy can only provide the credit bureaus with the information that
          Rentizy receives from Customer, and Rentizy shall not be responsible
          or liable to Customer or any Resident for any inaccuracies in Resident
          Data or incomplete data that Rentizy receives from Customer and
          transmits to the credit bureaus.{" "}
        </strong>
      </p>
      <p>
        As a data furnisher under the CENTRAL BANK OF KENYA CREDIT REFERENCE
        BUREAU REGULATIONS, 2019 Rentizy has an obligation to respond to and
        resolve any disputes by a consumer regarding the data it provides to the
        credit bureaus. If a Resident raises such a dispute, Customer will work
        with Rentizy and provide the additional information or documentation
        required to respond to the dispute.
      </p>
      <p>
        Customer understands that the credit bureaus receiving the reported data
        will take non-exclusive ownership of that data. Rentizy&rsquo;s data
        furnishing arrangements with the credit bureaus require that Rentizy
        follows certain steps to verify each Enrolled Resident&rsquo;s identity.
        Customer agrees to provide reasonable assistance and information to
        entizy for this purpose.
      </p>
      <ol start="6">
        <li>
          <strong> Third-Party Services</strong>
        </li>
      </ol>
      <p>
        Through the Service, we may make products and services available to you
        and Residents that are provided or facilitated by a third-party provider
        (<strong>&ldquo;Third-Party Services&rdquo;</strong>).
      </p>
      <p>
        <strong>6.1. No Responsibility for Third-Party Services</strong>
      </p>
      <p>
        We do not control or endorse, nor are we responsible for, any
        Third-Party Services, including the accuracy, validity, timeliness,
        completeness, reliability, integrity, quality, legality, usefulness or
        safety of Third-Party Services, or any intellectual property rights
        therein. Certain Third-Party Services may, among other things, be
        inaccurate, misleading or deceptive. Nothing in these Terms shall be
        deemed to be a representation or warranty by us with respect to any
        Third-Party Services. We have no obligation to monitor Third Party
        Services, and we may block or disable access to any Third-Party Services
        (in whole or part) through the Service at any time. In addition, the
        availability of any Third-Party Services through the Service does not
        imply our endorsement of, or our affiliation with, any provider of such
        Third-Party Service, nor does such availability create any legal
        relationship between you and any such provider. YOUR USE OF ANY
        THIRD-PARTY SERVICE IS AT YOUR OWN RISK AND IS SUBJECT TO ANY ADDITIONAL
        TERMS, CONDITIONS AND POLICIES APPLICABLE TO SUCH THIRD-PARTY SERVICE
        (SUCH AS TERMS OF SERVICE OR PRIVACY POLICIES OF THE PROVIDERS OF SUCH
        THIRD-PARTY SERVICE).
      </p>
      <p>
        <strong>6.2 Third-Party Sites</strong>
      </p>
      <p>
        The Service may contain links and other functionality that connect with
        certain sites and applications not provided by us, including social
        media sites and sites hosted by a Third-Party Service provider (&ldquo;
        <strong>Third Party Sites</strong>&rdquo;). We are providing these links
        and functionality solely as a convenience to you and/or your Residents.
        We are not responsible for and have no liability for the content,
        features, products, services, privacy policies or terms of service of
        any Third-Party Sites. The fact that we have provided a link to a
        Third-Party Site is not an endorsement of that Third-Party Site
        (including any information or content made available throughout such
        site) or its owners, sponsors or operators. We have not tested all
        information, software or products found on any Third-Party Site and
        therefore do not make any representations about those sites or any
        associated products or services.
      </p>
      <ol start="7">
        <li>
          <strong> License to Services</strong>
        </li>
      </ol>
      <p>
        The Services, including the Products, Site, and App, and their entire
        contents, features, functionality (including to all information,
        software, text, displays, images, video and audio, and the design,
        selection and arrangement thereof), and underlying technology are owned
        by us or our licensors or other providers of such material, and are
        protected by United States and international copyright, trademark,
        patent, trade secret and other intellectual property or proprietary
        rights laws. Unless explicitly stated otherwise, any new Products or
        features we provide that add to or enhance the current Services shall
        also constitute the &ldquo;Services&rdquo; and shall be subject to these
        Terms. We hereby grant you a limited, personal, non-exclusive,
        non-transferable, non-sublicensable, revocable license to use the
        Services for your personal, non-commercial use only.
      </p>
      <p>
        Except as expressly provided in these Terms, no part of the Services may
        be copied, reproduced, reverse engineered, decompiled, aggregated,
        republished, uploaded, posted, publicly displayed, encoded, translated,
        transmitted, distributed, sold, licensed, or otherwise exploited for any
        commercial purpose whatsoever, without our express prior written
        permission. We reserve all rights not expressly granted to you under
        these Terms in and to the Services.
      </p>
      <ol start="8">
        <li>
          <strong> Prohibited Uses</strong>
        </li>
      </ol>
      <p>
        You may not access or use the Services for any purpose other than that
        for which we make the Services available. The Services may not be used
        in connection with any commercial endeavors except those that are
        specifically endorsed or approved by us.
      </p>
      <p>
        You may use the Services only for lawful purposes and in accordance with
        these Terms.
      </p>
      <p>You specifically agree not to:</p>
      <ol>
        <li>
          License, sublicense, sell, resell, rent, lease, transfer, assign,
          distribute, time share or otherwise commercially exploit or make the
          Services available to any third party, other than as expressly
          permitted by these Terms.
        </li>
        <li>
          Systematically or manually retrieve data or other content from the
          Services to create or compile, directly or indirectly, a collection,
          compilation, database, or directory except as permitted by us.
        </li>
        <li>
          Circumvent, bypass, disable, or otherwise interfere with security&shy;
          related features of the Services, including features that prevent or
          restrict the use or copying of any content or enforce limitations on
          the use of the Service.
        </li>
        <li>
          Copy or adapt the Services&rsquo; software, or engage in any automated
          use of the system, such as using scripts to send messages, or using
          any data mining and extraction tools.
        </li>
        <li>
          Attack the Services via a denial-of-service attack or a distributed
          denial-of-service attack.
        </li>
        <li>
          Use any device, software or routine that interferes with the proper
          working of the Services.
        </li>
        <li>
          Interfere with, disrupt, or create an undue burden on the Services or
          the networks or services connected to the Service.
        </li>
        <li>
          Use the Services or any information obtained from the Services in
          order to harass, abuse, or harm another person.
        </li>
        <li>
          Use the Services as part of any effort to compete with us or otherwise
          use the Services for any revenue &shy;generating endeavor or
          commercial enterprise.
        </li>
        <li>
          Decipher, decompile, disassemble, or reverse engineer any of the
          software or underlying technology comprising or in any way making up a
          part of the Services.
        </li>
        <li>
          Attempt to gain unauthorized access to, interfere with, damage or
          disrupt any parts of the Services, the server on which the Site or App
          is stored, or any server, computer or database connected to the
          Services.
        </li>
        <li>
          Delete any copyright or other proprietary rights notice posted in
          connection with the Services.
        </li>
        <li>
          Upload or transmit (or attempt to upload or to transmit) viruses,
          Trojan horses, or other material that makes automated use of the
          Services or interferes with any party&rsquo;s uninterrupted use and
          enjoyment of the Services or modifies, impairs, disrupts, alters, or
          interferes with the use, features, functions, operation, or
          maintenance of the Products, Site, or App.
        </li>
        <li>
          Use the Services in a manner not authorized by these Terms or
          otherwise inconsistent with any applicable laws or regulations.
        </li>
        <li>
          <strong> Termination</strong>
        </li>
      </ol>
      <p>
        Either party may terminate this Agreement upon thirty (30) days notice
        to the other party. Without limiting the foregoing, Rentizy may
        terminate Customer&rsquo;s access to and use of the Services, at
        Rentizy&rsquo;s sole discretion, if Rentizy reasonably suspects or
        determines that Customer has breached any of these Terms. In the event
        of termination by Rentizyl for breach of these Terms, the termination
        shall be effective immediately upon Rentizy notifying Customer of such
        termination. Upon any termination, discontinuation or cancellation of
        the Services for any reason, the following provisions of these Terms
        will survive: &ldquo;Limitation of Liability&rdquo;, &ldquo;Dispute
        Resolution&rdquo; and &ldquo;General Terms&rdquo;.
      </p>
      <ol start="10">
        <li>
          <strong> Security of Login Credentials</strong>
        </li>
      </ol>
      <p>
        You must follow any password security guidelines that we establish. You
        agree that you are solely responsible for keeping your login credentials
        to the Services (<strong>&ldquo;Rentizy Credentials&rdquo;</strong>)
        safe and secure, and preventing unauthorized access to your Rentizy
        Credentials and your account with us, including by maintaining the
        security of your computer and other devices used to access the Services.
        It is assumed that any communications received through use of your
        Rentizy Credentials were sent or authorized by you. If your Rentizy
        Credentials are compromised for any reason, you must change your
        password. In addition, you must notify us if you become aware of any
        unauthorized access to your account. YOU AGREE THAT WE ARE NOT
        RESPONSIBLE FOR THE SECURITY OF YOUR RENTIZY CREDENTIALS.
      </p>
      <ol start="11">
        <li>
          <strong> Warranties; Disclaimers</strong>
        </li>
      </ol>
      <p>
        YOUR USE OF THE PRODUCTS AND SERVICES IS ENTIRELY AT YOUR OWN RISK.
        EXCEPT AS DESCRIBED IN THESE TERMS, THE PRODUCTS AND SERVICES ARE
        PROVIDED &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE.&rdquo; TO THE
        MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, RENTIZY, OUR AFFILIATES, AND
        EACH OF OUR THIRD-PARTY PROVIDERS, SUBCONTRACTORS, LICENSORS,
        DISTRIBUTORS, OR SUPPLIERS (COLLECTIVELY{" "}
        <strong>&ldquo;SUPPLIERS&ldquo;</strong>) DISCLAIM ALL WARRANTIES,
        EXPRESS, IMPLIED, OR STATUTORY, INCLUDING ANY WARRANTY THAT THE PRODUCTS
        AND SERVICES ARE FIT FOR A PARTICULAR PURPOSE; ALL WARRANTIES OF TITLE,
        MERCHANTABILITY, DATA LOSS, OR NON-INTERFERENCE WITH OR NON-INFRINGEMENT
        OF ANY INTELLECTUAL PROPERTY RIGHTS; AND ANY GUARANTEE OF THE ACCURACY,
        RELIABILITY, QUALITY, OR CONTENT IN OR LINKED TO THE PRODUCTS OR
        SERVICES. WE, OUR AFFILIATES, AND OUR SUPPLIERS DO NOT WARRANT THE
        COMPREHENSIVENESS, COMPLETENESS, CORRECTNESS, LEGALITY, OR ACCURACY OF
        THE PRODUCTS AND SERVICES, OR THAT THE PRODUCTS AND SERVICES ARE SECURE
        OR FREE FROM BUGS, VIRUSES, INTERRUPTION, ERRORS, THEFT, OR LOSS OF
        DATA, RESULTING FROM YOUR USE OF THE PRODUCTS AND SERVICES.
      </p>
      <p>
        THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR
        LIMITED UNDER APPLICABLE LAW. IF THE EXCLUSIONS FOR IMPLIED WARRANTIES
        DO NOT APPLY TO YOU, ANY IMPLIED WARRANTIES ARE LIMITED TO 30 DAYS FROM
        YOUR FIRST USE OF THE PRODUCTS OR SERVICES TO WHICH SUCH CLAIM RELATES.
      </p>
      <ol start="12">
        <li>
          <strong> Limitation of Liability and Indemnification</strong>
        </li>
      </ol>
      <p>
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW AND EXCEPT FOR A
        PARTY&rsquo;S INDEMNIFICATION OBLIGATIONS UNDER THESE TERMS, THE ENTIRE
        LIABILITY OF ANY OF CUSTOMER, RENTIZY, RENTIZY&rsquo;S AFFILIATES, OR
        RENTIZY&rsquo;S SUPPLIERS FOR ALL CLAIMS RELATING TO THIS AGREEMENT
        SHALL BE LIMITED TO $Ksh 50,000 SUBJECT TO APPLICABLE LAW, CUSTOMER,
        RENTIZY, AND RENTIZY&rsquo;S AFFILIATES AND SUPPLIERS ARE NOT LIABLE FOR
        ANY OF THE FOLLOWING: (A) INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, OR
        CONSEQUENTIAL DAMAGES; (B) DAMAGES RELATING TO FAILURES OF
        TELECOMMUNICATIONS, THE INTERNET, ELECTRONIC COMMUNICATIONS, CORRUPTION,
        SECURITY, LOSS OR THEFT OF DATA, VIRUSES, OR SPYWARE; (C) LOSS OF
        BUSINESS, REVENUE, PROFITS OR INVESTMENT; OR (D) USE OF THE PRODUCTS AND
        SERVICES WITH SOFTWARE OR HARDWARE THAT DOES NOT MEET REQUIREMENTS. THE
        ABOVE LIMITATIONS APPLY EVEN IF RENTIZY, CUSTOMER, AND RENTIZY&rsquo;S
        AFFILIATES AND SUPPLIERS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
        DAMAGES. THIS AGREEMENT SETS FORTH THE ENTIRE LIABILITY OF RENTIZY,
        CUSTOMER, RENTIZY&rsquo;S AFFILIATES, AND RENTIZY&rsquo;S SUPPLIERS, AND
        EACH PARTY&rsquo;S EXCLUSIVE REMEDY WITH RESPECT TO THE PRODUCTS AND
        SERVICES.
      </p>
      <p>
        You agree to defend, indemnify and hold harmless us and our affiliates,
        and our and their respective officers, directors, employees,
        contractors, agents, licensors, suppliers, successors and assigns (the{" "}
        <strong>&ldquo;Indemnified Parties&rdquo;</strong>) from and against any
        claims, demands, liabilities, damages, judgments, awards, losses, costs,
        expenses or fees (including reasonable attorneys&rsquo; fees) (&ldquo;
        <strong>Claims</strong>&rdquo;) arising out of or relating to: (1) your
        violation of these Terms; (2) your use of the Products or Services; (3)
        your use of any information obtained through the Services; and (4) your
        violation of the rights of a third party, including but not limited to
        intellectual property rights. In addition, you agree to indemnify,
        defend, and hold the Indemnified Parties harmless from all Claims
        arising out of your use of Third-Party Products and Services. We reserve
        the right, in our sole discretion and at our own expense, to assume the
        exclusive defense and control of any Claims. You agree to reasonably
        cooperate as requested by us or our Suppliers in the defense of any
        Claims.
      </p>
      <ol start="13">
        <li>
          <strong> Dispute Resolution</strong>
        </li>
      </ol>
      <p>
        This section of the Terms may be referred to as the&nbsp;
        <strong>"Arbitration Provision&rdquo;</strong>
      </p>
      <p>
        <strong>13.1. Agreement to Arbitrate</strong>
      </p>
      <p>
        You and Rentizy agree that any dispute, claim or controversy arising out
        of or relating to these Terms or the breach, termination, enforcement,
        interpretation or validity thereof or the use of the Services
        (collectively, <strong>&ldquo;Disputes&rdquo;</strong>) will be settled
        by binding arbitration, except that each party retains the right: (i) to
        bring an individual action in small claims court and (ii) to seek
        injunctive or other equitable relief in a court of competent
        jurisdiction to prevent the actual or threatened infringement,
        misappropriation or violation of a party&rsquo;s copyrights, trademarks,
        trade secrets, patents or other intellectual property rights (the action
        described in the foregoing clause (ii), an{" "}
        <strong>&ldquo;IP Protection Action&rdquo;</strong>).
      </p>
      <p>
        <strong>
          YOU ACKNOWLEDGE AND AGREE THAT YOU AND RENTIZY ARE EACH WAIVING THE
          RIGHT TO A TRIAL OR TO PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN
          ANY PURPORTED CLASS ACTION OR REPRESENTATIVE PROCEEDING.{" "}
        </strong>
      </p>
      <p>
        <strong>13.2. Arbitration Rules</strong>
      </p>
      <ul>
        <li>
          The arbitration will be governed by the KAA (Arbitration Act, No 4 of
          1995). Arbitration Act will govern the interpretation and enforcement
          of this Section to the extent that there is any variance between the
          KAA Rules and this Arbitration Provision, this Arbitration Provision
          will control. Arbitrator(s) must be a member of the bar where the
          arbitration is held, with expertise in the substantive laws applicable
          to the subject matter of the Dispute.
        </li>
      </ul>
      <p>You and we each agree that in this relationship:</p>
      <p>
        You and we are participating in transactions involving intercounty
        commerce.
      </p>
      <p>
        The Arbitrator will decide any Dispute regarding the enforceability of
        this Arbitration Provision.
      </p>
      <p>
        Each arbitration is governed by the provisions of the Arbitration Act,
        No 4 of 1995 &ndash;except for the limitations set out in the Act, the
        Arbitration Act is supplemented by the Arbitration Rules 1997; to the
        extent any provision of that Act is inapplicable, unenforceable or
        invalid, the laws governing the relationship between you and us about
        which the Dispute arose.
      </p>
      <p>
        <strong>13.3. Fees and Expenses</strong>
      </p>
      <p>
        Arbitration fees shall be determined by KAA Rules, unless limited by
        applicable law. Please check with the KAA to determine the fees that
        apply to any arbitration you may file. If the law that applies to these
        Terms limits the amount of fees and expenses you have to pay, then no
        allocation of fees and expenses to you shall exceed this limitation.
        Unless inconsistent with applicable law, each of us shall bear the
        expense of our own advocate, expert and witness fees, regardless of
        which of us prevails in the arbitration.
      </p>
      <p>
        <strong>13.4. Miscellaneous</strong>
      </p>
      <p>
        All statutes of limitations that apply to any Dispute apply to any
        arbitration between you and us.
      </p>
      <p>
        The provisions of this Arbitration Provision will survive termination,
        amendment, or expiration of your use of the Services, these Terms, any
        other agreement between you and us or any other relationship between you
        and us.
      </p>
      <p>
        This Arbitration Provision constitutes the entire agreement between you
        and us and supersedes all prior arrangements and other communications
        about dispute resolution. If more than one arbitration agreement
        mutually entered into by you and us potentially applies to a Dispute,
        the one most directly related to the transaction that is the subject of
        the dispute will control.
      </p>
      <p>
        <strong>13.5. Changes</strong>
      </p>
      <p>
        Notwithstanding the provisions of the &ldquo;Changes to Terms or
        Services&rdquo; section above, if we change this &ldquo;Dispute
        Resolution&rdquo; section after the date you first accepted these Terms
        (or accepted any subsequent changes to these Terms), you may reject any
        such change by sending us written notice (including by email to
        info@rentizy.co.ke) within 30 days of the date such change became
        effective, as indicated in the &ldquo;Last Updated&rdquo; date above or
        in the date of our email to you notifying you of such change. By
        rejecting any change, you are agreeing that you will arbitrate any
        Dispute between you and us in accordance with the provisions of this
        &ldquo;Dispute Resolution&rdquo; section as of the date you first
        accepted these Terms (or accepted any subsequent changes to these
        Terms).
      </p>
      <ol start="14">
        <li>
          <strong> Reporting Claims of Copyright Infringement</strong>
        </li>
      </ol>
      <p>
        You may not use the Service for any purpose or in any manner that
        infringes the rights of any third party. We may terminate your access to
        the Service in the event of repeated violations.
      </p>
      <p>
        We take claims of copyright infringement seriously. We will respond to
        notices of alleged copyright infringement that comply with applicable
        law. If you believe any materials accessible on or through this Site or
        the Services infringe your copyright, you may request removal of those
        materials (or access to them) from this Site or the Services by
        submitting written notification to our copyright agent designated below.
        In accordance with The Copyright Act- Act No. 12 of 2001
      </p>
      <p>must include substantially the following:</p>
      <p>- Your physical or electronic signature.</p>
      <p>
        - Identification of the copyrighted work you believe to have been
        infringed or, if the claim involves multiple works on the Website, a
        representative list of such works.
      </p>
      <p>
        - Identification of the material you believe to be infringing in a
        sufficiently precise manner to allow us to locate that material.
      </p>
      <p>
        - Adequate information by which we can contact you (including your name,
        postal address, telephone number, and, if available, email address).
      </p>
      <p>
        - A statement that you have a good faith belief that use of the
        copyrighted material is not authorized by the copyright owner, its
        agent, or the law.
      </p>
      <p>
        - A statement that the information in the written notice is accurate.
      </p>
      <p>
        - A statement, under penalty of perjury, that you are authorized to act
        on behalf of the copyright owner.
      </p>
      <p>Our designated copyright agent to receive copyright Notices is:</p>
      <p>Chief Compliance Office</p>
      <p>Info@rentizy.co.ke</p>
      <p>
        If you fail to comply with all of the requirements of The Copyright Act-
        Act No. 12 of 2001 your Notice may not be effective.
      </p>
      <p>
        Please be aware that if you knowingly materially misrepresent that
        material or activity available through the Services is infringing your
        copyright, you may be held liable for damages (including costs and
        attorneys&rsquo; fees) under Copyright Act- Act No. 12 of 2001
      </p>
      <ol start="15">
        <li>
          <strong> General Terms</strong>
        </li>
      </ol>
      <p>
        <strong>15.1. Governing Law</strong>
      </p>
      <p>
        All matters relating to the Services and these Terms and any dispute or
        claim arising therefrom or related thereto (in each case, including
        non-contractual disputes or claims), shall be governed by and construed
        in accordance with the laws of the republic of Kenya without giving
        effect to any choice or conflict of law provision or rule
      </p>
      <p>
        <strong>15.2 Waiver and Severability</strong>
      </p>
      <p>
        No waiver of by us of any term or condition set forth in these Terms
        shall be deemed a further or continuing waiver of such term or condition
        or a waiver of any other term or condition, and any failure of us to
        assert a right or provision under these Terms shall not constitute a
        waiver of such right or provision.
      </p>
      <p>
        If any provision of these Terms is held by a court or other tribunal of
        competent jurisdiction to be invalid, illegal or unenforceable for any
        reason, such provision shall be eliminated or limited to the minimum
        extent such that the remaining provisions of these Terms will continue
        in full force and effect.
      </p>
      <p>
        <strong>15.3. Entire Agreement</strong>
      </p>
      <p>
        These Terms, along with the Property Schedule that Customer completed as
        part of these Terms and which is hereby incorporated by reference,
        constitute the entire and exclusive understanding and agreement between
        us and you regarding the Services and Products, and these Terms
        supersede and replace any and all prior oral or written understandings
        or agreements between us and you regarding the Services and Products.
      </p>
      <p>
        <strong>15.4. Assignment</strong>
      </p>
      <p>
        You may not assign or transfer these Terms, by operation of law or
        otherwise, without our prior written consent. Any attempt by you to
        assign or transfer these Terms, without such consent, will be null. We
        may freely assign or transfer these Terms without restriction. Subject
        to the foregoing, these Terms will bind and inure to the benefit of the
        parties, their successors and permitted assigns.
      </p>
      <p>
        <strong>15.5. Notices</strong>
      </p>
      <p>
        Any notices or other communications provided by us under these Terms,
        including those regarding modifications to these Terms, will be given:
        (i) via email; or (ii) by posting to the Services. For notices made by
        e-mail, the date of receipt will be deemed the date on which such notice
        is transmitted.
      </p>
      <p>
        <strong>15.6. Contact Information</strong>
      </p>
      <p>
        If you have any questions about these Terms or the Services, please
        contact us via the &ldquo;Contact Support&rdquo; link in the help center
        section of the Site or App or by emailing&nbsp;info@rentizy.co.ke
      </p>
    </div>
  );
};

export default LandlordTerms;
