import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { connect } from "react-redux";

import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import "./login.css";
import { setUser } from "../../actions/user";
import { Link } from "react-router-dom";
import axios from "axios";
import { serverAddress } from "../../config/Config";
import { setContext } from "../../actions/context";
import { analytics } from "../../config/FirebaseConfig";

const Login = ({ setUser, setContext }) => {
  let history = useHistory();
  const [state, setstate] = useState({
    email: "",
    password: "",
  });
  let { email, password } = state;

  let login = (e) => {
    e.preventDefault();

    axios
      .post(`${serverAddress}/login`, state)
      .then((res) => {
        let config = {
          headers: {
            Authorization: `Bearer ${res.headers.authorization}`,
          },
        };
        axios.get(`${serverAddress}/auth`, config).then((r) => {
          analytics.logEvent("User " + r.data.email);
          setUser({ ...r.data, token: res.headers.authorization });
          if (r.data.type === "ADMIN") {
            history.push("/admin/dashboard");
          } else if (r.data.type === "TENANT") {
            history.push("/tenant/dashboard");
          } else {
            history.push("/owner/dashboard");
          }
          console.log(r.data.type);
          setContext("dashboard");
        });
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data.status === 401)
            toast.error("Invalid Credentials");
          else toast.error("Server Error");
        } else if (err.request) {
          toast.error("Can't send Request please try again");
        }
      });
  };
  return (
    <div className="login">
      <Row>
        <Col className="l-space" xs="12" sm="12" md="8">
          <div className="login-logo"></div>
        </Col>
        <Col xs="12" sm="12" md="4">
          <div className="login-form">
              <div className="logo">
                <h4>LOGIN</h4>
              </div>
              <Form onSubmit={login}>
                <FormGroup>
                  <Label for="exampleEmail">Email</Label>
                  <Input
                    required
                    value={email}
                    onChange={(e) =>
                      setstate({ ...state, email: e.target.value })
                    }
                    type="email"
                    name="email"
                    id="exampleEmail"
                    placeholder="email"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="examplePassword">Password</Label>
                  <Input
                    required
                    value={password}
                    onChange={(e) =>
                      setstate({ ...state, password: e.target.value })
                    }
                    type="password"
                    name="password"
                    id="examplePassword"
                    placeholder="password"
                  />
                </FormGroup>
                <div className="action">
                  <Link to="/change-password">Forgot Password?</Link>
                  <Button>Login</Button>
                </div>
                <div>
                  <p>
                    Don't have an account? <Link to="/register">Register</Link>
                  </p>
                </div>
              </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default connect(null, { setUser, setContext })(Login);
