import React from "react";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import PaymentsStats from "./PaymentsStats";

const Payments = () => {
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-chart">
              <CardHeader>
                <CardTitle tag="h5">Payments</CardTitle>
                <p className="card-category">Monthly Payments Amount</p>
              </CardHeader>
              <CardBody>
                <PaymentsStats />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Payments;
