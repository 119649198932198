import Dashboard from "./views/Dashboard.js";
import Notifications from "./views/Notifications.js";

import Apartments from "./views/Apartments";
import Records from "./views/Records";
import Payments from "./views/Payments";
import TenatsView from "./views/TenantsView";
import AdminSubscription from "./components/Subscription/AdminSubscription.js";
import AdminLoans from "./views/AdminLoans.js";

import StaffView from "./views/StaffView.js";
import Landlords from "./components/Landloards/Landlords.js";
import Balance from "./views/Balance";
import Withdrawals from "./views/Withdrawals";
import ChangePassword from "./views/ChangePassword.js";

var routes = [
  {
    path: "/dashboard",
    name: "Home",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/staff",
    name: "Staff",
    icon: "nc-icon nc-bank",
    component: StaffView,
    layout: "/admin",
  },

  {
    path: "/apartments",
    name: "Apartments",
    icon: "nc-icon nc-single-02",
    component: Apartments,
    layout: "/admin",
  },

  {
    path: "/balance",
    name: "Balance",
    icon: "nc-icon nc-single-02",
    component: Balance,
    layout: "/admin",
  },

  {
    path: "/withdrawals",
    name: "Withdrawals",
    icon: "nc-icon nc-single-02",
    component: Withdrawals,
    layout: "/admin",
  },

  {
    path: "/landlords",
    name: "Landlords",
    icon: "nc-icon nc-single-02",
    component: Landlords,
    layout: "/admin",
  },

  {
    path: "/tenants",
    name: "Tenants",
    icon: "nc-icon nc-single-02",
    component: TenatsView,
    layout: "/admin",
  },

  {
    path: "/subscriptions",
    name: "Subscriptions",
    icon: "nc-icon nc-single-02",
    component: AdminSubscription,
    layout: "/admin",
  },

  {
    path: "/records",
    name: "Records",
    icon: "nc-icon nc-pin-3",
    component: Records,
    layout: "/admin",
  },
  {
    path: "/payments",
    name: "Payments",
    icon: "nc-icon nc-pin-3",
    component: Payments,
    layout: "/admin",
  },
  {
    path: "/loans",
    name: "Loans",
    icon: "nc-icon nc-pin-3",
    component: AdminLoans,
    layout: "/admin",
  },

  {
    path: "/notifications",
    name: "Notifications",
    icon: "nc-icon nc-bell-55",
    component: Notifications,
    layout: "/admin",
  },
  {
    path: "/change-password",
    name: "Change Password",
    icon: "nc-icon nc-pin-3",
    component: ChangePassword,
    layout: "/admin",
  },
];
export default routes;
