import React, { useState } from "react";
import axios from "axios";
import { serverAddress } from "../config/Config";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setSelectedUser } from "../actions/selectedUser";
import dayjs from "dayjs";
import ReactPaginate from "react-paginate";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";

const Tenants = ({ user, setSelectedUser }) => {
  const [dropdownOpen, setOpen] = useState(false);
  const [dropdownActiveOpen, setActiveOpen] = useState(false);
  const [state, setstate] = useState({
    flag: false,
    data: [],
    pageCount: 0,
    pageSize: 25,
    pageData: [],
    apartments: [],
    active: "ACTIVE",
    filter: { apartment: "" },
  });

  let { flag, data, apartments, filter, active , pageCount, pageSize, pageData} = state;

  if (user && !flag) {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    let url = `${serverAddress}/admin/tenants`;

    axios.get(url, config).then((res) => {
      axios.get(`${serverAddress}/apartments/apartmentnames`).then((r) => {
        console.log(res.data);
        setstate({
          ...state,
          apartments: r.data,
          flag: true,
          data: res.data,
          pageCount: Math.ceil(res.data.length / pageSize),
          pageData: res.data.slice(0, 0 + pageSize),
        });
      });
    });
  }

  const toggle = () => setOpen(!dropdownOpen);
  const toggleActive = () => setActiveOpen(!dropdownActiveOpen);
  let handlePageClick = (pageData) => {
    let selected = pageData.selected;
    let offset = selected * pageSize;
    let a = data.slice(offset, offset + pageSize);

    setstate({ ...state, pageData: a });
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <CardTitle tag="h4">Tenants</CardTitle>
                <div className="record-header">
                  <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle caret>
                      {filter.apartment !== ""
                        ? filter.apartment
                        : "Select Appartment"}
                    </DropdownToggle>
                    <DropdownMenu>
                      {apartments.map((a) => (
                        <DropdownItem
                          onClick={(e) => {
                            setstate({
                              ...state,
                              filter: { ...filter, apartment: a },
                            });
                          }}
                        >
                          {a}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </ButtonDropdown>

                  <ButtonDropdown
                    isOpen={dropdownActiveOpen}
                    toggle={toggleActive}
                  >
                    <DropdownToggle caret>{active}</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={(e) =>
                          setstate({ ...state, active: "ACTIVE" })
                        }
                      >
                        ACTIVE
                      </DropdownItem>
                      <DropdownItem
                        onClick={(e) =>
                          setstate({ ...state, active: "NOT ACTIVE" })
                        }
                      >
                        NOT ACTIVE
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </div>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Tenant Name</th>
                      <th>Apartment</th>
                      <th>Unit Name</th>
                      <th>Phone Number</th>
                      <th>Registration Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pageData
                      .filter((e) => {
                        if (filter.apartment !== "")
                          return e.apartment_name === filter.apartment;
                        else return true;
                      })
                      .filter((e) => {
                        if (active === "ACTIVE") return e.active;
                        else return !e.active;
                      })
                      .map((e) => (
                        <tr>
                          <td>
                            <Link
                              onClick={(event) => setSelectedUser(e)}
                              to={"/admin/selected-user"}
                            >{`${e.first_name} ${e.last_name}`}</Link>
                          </td>
                          <td>{e.apartment_name}</td>
                          <td>{e.apartmentUnit}</td>
                          <td>{e.phone_number}</td>
                          <td className="text-right">
                                {` ${dayjs(e.timestamp).format(
                                  "D MMM , YYYY h:mm A"
                                )}`}
                              </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                <ReactPaginate
                previousLabel={"<<"}
                nextLabel={">>"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { setSelectedUser })(Tenants);
