import axios from "axios";

import React, { useState } from "react";
import { serverAddress } from "../../config/Config";

import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import { connect } from "react-redux";

const AddSubscription = ({ user, subscription }) => {
  let history = useHistory();
  const [state, setstate] = useState({
    description: "",
    duration: 0,

    name: "",
    pricePercentage: 0,
    loading: false,
    flag: false,
  });
  let { name, duration, description, pricePercentage, loading, flag } = state;
  if (subscription && subscription.id && !flag) {
    setstate({
      ...state,
      ...subscription,

      flag: true,
    });
  }

  let addSubscription = (e) => {
    e.preventDefault();
    setstate({ ...state, loading: true });
    let obj = {
      ...state,
      duration: Number.parseInt(duration),
      pricePercentage: Number.parseFloat(pricePercentage),
    };

    console.log(obj);

    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    axios
      .post(`${serverAddress}/packages`, obj, config)
      .then((res) => {
        console.log(res.data);
        history.push("/admin/subscriptions");
      })
      .catch((err) => {
        toast.error("Error occured while adding subscription");
        setstate({ ...state, loading: false });
      });
  };
  return (
    <div className="content center">
      <Row>
        <Col md="8" sm="12" style={{ margin: "auto" }}>
          <Card className="card-user">
            <CardHeader>
              {subscription.id ? (
                <CardTitle tag="h5">Edit Subscription</CardTitle>
              ) : (
                <CardTitle tag="h5">Add Subscription</CardTitle>
              )}
            </CardHeader>
            <CardBody>
              <Form onSubmit={addSubscription}>
                <Row>
                  <Col className="pr-1" md="12">
                    <FormGroup>
                      <label>Subscription Name</label>
                      <Input
                        placeholder="Subscription Name"
                        type="text"
                        value={name}
                        required
                        onChange={(e) =>
                          setstate({
                            ...state,
                            name: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Subscription Description</label>
                      <Input
                        placeholder="Subscription Description"
                        type="text"
                        value={description}
                        required
                        onChange={(e) =>
                          setstate({
                            ...state,
                            description: e.target.value,
                          })
                        }
                      />
                    </FormGroup>

                    <FormGroup>
                      <label>Duration</label>
                      <Input
                        placeholder="Duration"
                        type="number"
                        value={duration}
                        required
                        onChange={(e) =>
                          setstate({ ...state, duration: e.target.value })
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Price Percentage</label>
                      <Input
                        placeholder="Price Percentage"
                        type="number"
                        value={pricePercentage}
                        required
                        onChange={(e) =>
                          setstate({
                            ...state,
                            pricePercentage: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <div className="update ml-auto mr-auto">
                    {!loading ? (
                      <Button
                        className="btn-round"
                        color="primary"
                        type="submit"
                      >
                        {subscription.id ? (
                          <span>Edit Subscription</span>
                        ) : (
                          <span>Add Subscription</span>
                        )}
                      </Button>
                    ) : (
                      <Spinner color="primary" />
                    )}
                  </div>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  subscription: state.subscription,
});

export default connect(mapStateToProps)(AddSubscription);
