import React from "react";
import "./faq.css";

const FaqComponent = () => {
  return (
    <div className="faq">
      <h1>FAQs</h1>
      <div className="faqs">
        <div>
          <h4>Is Rentizy a loan or rent guarantee?</h4>
          <p>
            No, Rentizy is not a loan or rent guarantee. It is a custom rent
            payment schedule designed to help residents pay rent over their pay
            dates instead of all at once.
          </p>
        </div>
        <div>
          <h4>What about late fees under my lease?</h4>
          <p>
            While you are enrolled in Rentizy, your property manager will defer
            your late fees. Simply keep paying according to your custom Rentizy
            schedule, and you will not pay any late fees
          </p>
        </div>
        <div>
          <h4>
            My income is sporadic, in variable amounts and usually, in cash. Can
            I use Rentizy?
          </h4>
          <p>
            Yes, you just need some form of income that allows you to pay your
            rent in full by the end of the month it's due. Multiple forms of
            cash inflow are accepted.
          </p>
        </div>
        <div>
          <h4>Why should I use Rentizy over direct deposit?</h4>
          <p>
            Rentizy is a better way to pay rent without penalties from your
            landlord. Instead of paying rent all at once, you can split it up
            over your pay cycle to stabilize and improve your cash flow.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FaqComponent;
