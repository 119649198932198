import React, { useState } from "react";
import axios from "axios";
import { serverAddress } from "../../config/Config";
import { connect } from "react-redux";
import "./package.css";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Button,
  Row,
  Col,
} from "reactstrap";
import { useHistory } from "react-router-dom";

import { setSelectedSubscription } from "../../actions/selectedSubscription";

const Packages = ({ user, setSelectedSubscription }) => {
  let colors = [
    "#006583",
    "#BF2C23",
    "#8E2034",
    "#2D5159",
    "#57273F",
    "#E35B19",
  ];
  let history = useHistory();
  const [state, setstate] = useState({
    subs: [],
    flag: false,
  });

  let { subs, flag } = state;

  if (user && !flag) {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    axios.get(`${serverAddress}/packages`, config).then((res) => {
      console.log(res.data);
      setstate({ ...state, subs: res.data, flag: true });
    });
  }
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <Row>
                  <Col md={6}>
                    <CardTitle tag="h4">Packages</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="package">
                  {subs.map((a, index) => {
                    return (
                      <div
                        style={{
                          backgroundColor: colors[index % colors.length],
                        }}
                      >
                        <h4>{a.name}</h4>
                        <p>{a.description}</p>
                        {a.duration > 1 ? (
                          <p>{`Duration ${a.duration} months`}</p>
                        ) : (
                          <p>{`Duration ${a.duration} month`}</p>
                        )}

                        <Button
                          onClick={(e) => {
                            setSelectedSubscription(a);
                            history.push("add-subscription");
                          }}
                          className="btn-round"
                          color="primary"
                        >
                          Select Package
                        </Button>
                      </div>
                    );
                  })}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { setSelectedSubscription })(Packages);
