import axios from "axios";
import { Collapse, Spinner } from "reactstrap";

import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { serverAddress } from "../config/Config";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import { useHistory } from "react-router";
import mpesa from "../assets/img/mpesa.png";

const SchedulePayment = ({ user }) => {
  let history = useHistory();
  const [state, setstate] = useState({
    loading: false,
    loanLoading: false,
    phoneNumber: user.phone_number,
    amount: 1,
    userLoan: {},
    loanAmount: 0,
    flag: false,
  });
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  let {
    loading,
    userLoan,
    phoneNumber,
    amount,
    loanAmount,
    loanLoading,
    flag,
  } = state;
  if (user && !flag) {
    if (user.type === "TENANT") {
      if (
        !user.location ||
        !user.occupation ||
        !user.address ||
        !user.placeOfWork ||
        !user.emergency_contact ||
        !user.apartmentUnit ||
        !user.id_number === 0
      ) {
        history.push("user-page");
      }
    }
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    axios.get(`${serverAddress}/payments/loan`, config).then((res) => {
      console.log(res.data);

      setstate({ ...state, userLoan: res.data, loading: false, flag: true });
    });
  }

  let pay = (e) => {
    setstate({ ...state, loading: true });
    e.preventDefault();

    let obj = {
      phoneNumber: Number.parseInt(phoneNumber.replace("+254", "254")),
      amountPaid: Number.parseInt(amount),
      userId: user.tenant_id,
      username: `${user.first_name} ${user.last_name}`,
    };

    console.log(obj);

    axios
      .post(
        "https://rentizy.co.ke/rentizy-payment/api/v1/payments/mpesa/stk-push",
        obj
      )
      .then((res) => {
        console.log(res.data);
        toast.success("Payment initiated successfuly");
        setstate({ ...state, loading: false });
      })
      .catch((err) => {
        toast.error("Payment initiation Failed");
        setstate({ ...state, loading: false });
      });
  };

  let loan = (e) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    setstate({ ...state, loanLoading: true });
    let obj = { ...state.userLoan, loanAmount: loanAmount };
    console.log(obj);

    axios
      .post(`${serverAddress}/payments/loan`, obj, config)
      .then((res) => {
        setstate({ ...state, loanLoading: false });
        toast.success("Loan request submited successfuly");
      })
      .catch((err) => {
        toast.error("Loan Request Failed");
        setstate({ ...state, loanLoading: false });
      });
  };
  return (
    <div className="content center">
      <Row>
        <Col md="8" sm="12" style={{ margin: "auto" }}>
          <Card className="card-user" style={{ textAlign: "center" }}>
            <CardHeader>
              <CardTitle tag="h4">Make Payment</CardTitle>
            </CardHeader>
            <CardBody>
              <Form onSubmit={pay}>
                <Row>
                  <Col className="pr-1" md="12">
                    <img
                      style={{ height: "50%" }}
                      src={mpesa}
                      alt="mpesa-logo"
                    />

                    <FormGroup>
                      <label>Phone number</label>
                      <Input
                        placeholder="phone number"
                        type="text"
                        value={phoneNumber}
                        required
                        onChange={(e) =>
                          setstate({ ...state, phoneNumber: e.target.value })
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Amount</label>
                      <Input
                        placeholder="amount"
                        type="text"
                        value={amount}
                        required
                        onChange={(e) =>
                          setstate({ ...state, amount: e.target.value })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <div>
                    {!loading ? (
                      <Button
                        className="btn-round"
                        color="primary"
                        type="submit"
                      >
                        Initiate Payment
                      </Button>
                    ) : (
                      <Spinner color="primary" />
                    )}
                  </div>
                </Row>
                <Row>
                  <div>
                    <Button
                      color="primary"
                      onClick={toggle}
                      style={{ marginBottom: "1rem" }}
                    >
                      Request loan
                    </Button>
                    <Collapse isOpen={isOpen}>
                      <Card>
                        <CardBody>
                          <div>
                            <h5>
                              {`Your current loan limit is ${userLoan.loanAmount}`}
                            </h5>
                            {userLoan && userLoan.loanAmount > 0 ? (
                              <div>
                                <FormGroup>
                                  <label>Amount</label>
                                  <Input
                                    placeholder="amount"
                                    type="number"
                                    value={loanAmount}
                                    required
                                    onChange={(e) =>
                                      setstate({
                                        ...state,
                                        loanAmount: e.target.value,
                                      })
                                    }
                                  />
                                </FormGroup>
                                {!loanLoading ? (
                                  <Button
                                    color="primary"
                                    onClick={loan}
                                    style={{ marginBottom: "1rem" }}
                                  >
                                    Loan
                                  </Button>
                                ) : (
                                  <Spinner color="primary" />
                                )}
                              </div>
                            ) : null}
                          </div>
                        </CardBody>
                      </Card>
                    </Collapse>
                  </div>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(SchedulePayment);
