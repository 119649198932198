import React, { useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { serverAddress } from "../config/Config";
import { Link } from "react-router-dom";

import dayjs from "dayjs";

// reactstrap components
import { Card, CardBody, Table, Row, Col } from "reactstrap";

const Loans = ({ user }) => {
  const [state, setstate] = useState({
    loans: [],
    flag: false,
  });
  let { loans, flag } = state;
  if (user && !flag) {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    axios.get(`${serverAddress}/loan`, config).then((res) => {
      console.log(res.data);
      setstate({ ...state, loans: res.data, flag: true });
    });
  }
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>User Id</th>
                      <th>Amount</th>
                      <th>Net Amount</th>
                      <th>Interest</th>
                      <th>Status</th>
                      <th>Timestamp</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loans.map((i) => (
                      <tr>
                        {user.type === "TENANT" ? (
                          <td>{`${user.first_name} ${user.last_name}`}</td>
                        ) : (
                          <Link>
                            <td>{`${user.first_name} ${user.last_name}`}</td>
                          </Link>
                        )}

                        <td>{i.loanAmount}</td>
                        <td>{i.netAmount}</td>
                        <td>{i.interestAmount}</td>
                        <td>{i.status}</td>

                        <td>
                          {` ${dayjs(i.timestamp).format(
                            "D MMM , YYYY h:mm A"
                          )}`}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Loans);
