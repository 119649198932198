import React, { useState } from "react";
import axios from "axios";
import { serverAddress } from "../config/Config";
import { setDialog } from "../actions/dialog";
import { connect } from "react-redux";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Button,
  Row,
  Col,
} from "reactstrap";
import { useHistory } from "react-router-dom";

import { setSelectedUnit } from "../actions/selectedUnit";
import { toast } from "react-toastify";
import Dialog from "../components/Dialog/Dialog";

const ApartmentUnits = ({ user, apartment, setSelectedUnit, setDialog }) => {
  let history = useHistory();
  const [state, setstate] = useState({
    units: [],
    flag: false,
    id: "",
  });

  let { units, flag, id } = state;

  if (user && apartment.apartment_id && !flag) {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    if (user.type === "TENANT") {
      axios
        .get(
          `${serverAddress}` +
            "/tenant/apartments/apartment-unit/" +
            apartment.apartment_id,
          config
        )
        .then((res) => setstate({ ...state, units: res.data, flag: true }));
    } else
      axios
        .get(
          `${serverAddress}` +
            "/apartments/apartment-unit/" +
            apartment.apartment_id,
          config
        )
        .then((res) => setstate({ ...state, units: res.data, flag: true }));
  }

  let verifyApartmentUnit = (id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    axios
      .post(
        `${serverAddress}/admin/apartments/verify-apartment-unit`,
        {
          id: id,
        },
        config
      )
      .then((res) => {
        console.log(res.data);
        toast.warning("Apartment  unit Verified successfuly");
        setstate({
          ...state,

          flag: false,
        });
      });
  };
  return (
    <>
      <Dialog param={id} callback={verifyApartmentUnit} />
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <Row>
                  <Col md={6}>
                    <CardTitle tag="h4">Apartment Units</CardTitle>
                  </Col>
                  <Col md={6}>
                    <div className="update ml-auto mr-auto">
                      <Button
                        onClick={(e) => {
                          setSelectedUnit({});
                          history.push("add-apartment-unit");
                        }}
                        className="btn-round"
                        color="primary"
                      >
                        Add Apartment Unit
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Unit Name</th>
                      <th>Rent Per Month</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {units.map((a) => {
                      return (
                        <tr>
                          <td>{a.unitName}</td>
                          <td>{a.rent}</td>
                          <td>{a.status}</td>
                          <td>
                            <div className="update ml-auto mr-auto">
                              <Button
                                onClick={(e) => {
                                  setSelectedUnit(a);
                                  history.push("add-apartment-unit");
                                }}
                                className="btn-round"
                                color="primary"
                              >
                                Edit Apartment Unit
                              </Button>
                              {a.status === "PENDING" ? (
                                <Button
                                  onClick={(e) => {
                                    setstate({ ...state, id: a.id });
                                    setDialog({
                                      message: `Do you want to verify apartment unit ${a.unitName} `,
                                      show: true,
                                    });
                                  }}
                                  className="btn-round"
                                  color="primary"
                                >
                                  Verify Apartment Unit
                                </Button>
                              ) : null}
                              <Button
                                onClick={(e) => {
                                  if (user.type === "TENANT") {
                                    axios
                                      .post(
                                        `${serverAddress}/apartments/apartment-unit/delete`,
                                        {
                                          id: a.id,
                                        }
                                      )
                                      .then((res) => {
                                        toast.warning(res.data);
                                        setstate({
                                          ...state,

                                          flag: false,
                                        });
                                      });
                                  } else
                                    axios
                                      .post(
                                        `${serverAddress}/apartments/apartment-unit/delete`,
                                        {
                                          id: a.id,
                                        }
                                      )
                                      .then((res) => {
                                        toast.warning(res.data);
                                        setstate({
                                          ...state,

                                          flag: false,
                                        });
                                      });
                                }}
                                className="btn-round"
                                color="danger"
                              >
                                Delete Apartment Unit
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.user,
  apartment: state.apartment,
});

export default connect(mapStateToProps, { setSelectedUnit, setDialog })(
  ApartmentUnits
);
