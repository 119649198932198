import React from "react";
import "./terms.css";

const PrivacyPolicy = () => {
  return (
    <div className="terms">
      <p>
        <strong>The Privacy policy.</strong>
      </p>
      <p>Last modified: August 22, 2021</p>
      <p>
        This Privacy Policy describes how Rentizy, Inc., Kenyan corporation, and
        its affiliates, subsidiaries, successors, and assigns (collectively,
        referred to as &ldquo;we&rdquo; or &ldquo;Rentizy&rdquo;) collects,
        shares, uses, and protects your personal information in connection with
        Rentizy&rsquo;s products and services (&ldquo;Rentizy Services&rdquo;),
        including any access of Rentizy&rsquo;s website,{" "}
        <a href="http://www.rentizy.co.ke">www.rentizy.co.ke</a> and all
        subdomains (the &ldquo;Rentizy Site&rdquo;). You accept this Privacy
        Policy by using the Rentizy Services or accessing the Rentizy Site.
      </p>
      <p>
        Rentizy reserves the right to change this Privacy Policy in its sole
        discretion at any time. You are responsible for reading and
        understanding any notices related to this Privacy Policy and the current
        version.
      </p>
      <ol>
        <li>
          <strong> Information Rentizy Collects</strong>
        </li>
      </ol>
      <p>
        The information we collect about you depends on what Rentizy Services
        you use and how you interact with those services.
      </p>
      <p>
        <strong>Information you provide to us</strong>
      </p>
      <p>
        When you use the Rentizy Services, including creating an account, we may
        collect the personal and other information you provide us. Personal
        information refers to that information that tells us specifically who
        you are. This information includes your name, mailing address, email
        address and phone number. If you register or apply for a credit or other
        product, we may collect the additional information you provide us, such
        as your social security number, date of birth, income information, and
        other information requested during the registration process. And when
        you make payments through the Rentizy Services, we and our third-party
        payment processors may collect information to enable us to process your
        payments.
      </p>
      <p>
        <strong>Bank account information</strong>
      </p>
      <p>
        If you use the Rentizy Services, you may provide us with your bank
        account credentials that allow us to gain access to your account in
        connection with the Rentizy Services. If you provide us these
        credentials, you authorize us to collect and use the information to
        provide the Rentizy Services to you and to build and improve Rentizy
        Products and Services. You may revoke this authorization, which can
        impact your use of the Rentizy Services. We work with{" "}
        <strong>
          Plaid, Inc. to connect to your accounts. Plaid&rsquo;s services and
          use of your information are governed by its terms and conditions found
          at{" "}
        </strong>
        <a href="https://plaid.com/legal/">
          <strong>https://plaid.com/legal/</strong>
        </a>
        <strong>
          . By providing us the credentials, you agree to Plaid&rsquo;s privacy
          policy located at that link.
        </strong>
      </p>
      <p>
        <strong>
          Information from your computer or mobile device (cookies and tracking
          technology)
        </strong>
      </p>
      <p>
        When you visit the Rentizy Site or open emails from us, we automatically
        collect certain information about your equipment, browsing actions, and
        patterns. We use cookies, flash cookies and web beacons to facilitate
        this collection. The information collected includes details of your
        visits to the Rentizy Site (such as traffic data, location data, logs,
        and other communication data and the resources that you access and use
        on the Rentizy Site) and information about your computer and internet
        connection, including your device type, IP address, operating system,
        location, and browser type. We may also collect analytics data, or use
        third-party analytics tools, to help us measure traffic and usage trends
        for the Rentizy Site. Although we do our best to honor the privacy
        preferences of our visitors, we are not able to respond to Do Not Track
        signals from your browser currently.
      </p>
      <p>
        We use or may use the data collected through tracking technologies to
        (a) remember information so that you will not have to reenter it during
        your visit or the next time you visit the site; (b) provide custom,
        personalized content and information, including targeted content and
        advertising; (c) recognize and contact you across multiple devices; (d)
        provide and monitor the effectiveness of the Rentizy Services; (e)
        monitor aggregate metrics such as total number of visitors, traffic,
        usage, and demographic patterns on our website; (f) diagnose or fix
        technology problems; and (g) otherwise to plan for and enhance the
        Rentizy Services.
      </p>
      <p>
        <strong>
          Information from emails, texts, phone calls, and other communications
          with us
        </strong>
      </p>
      <p>
        When you use the Rentizy Services, we may communicate with you through
        emails, texts, phone calls, and other communication methods. We may
        collect information about these communications, including recordings of
        conversations after notice to you, the content of these communications
        and whether you open and respond to emails and texts.
      </p>
      <p>
        <strong>Information from third parties</strong>
      </p>
      <p>
        We may receive information about you from third parties, including
        information we request on your behalf from credit reporting agencies
        such as TransUnion, Metropol and/or Creditinfo. We may also work with
        different third-party data partners to supplement information we collect
        directly from you to assess your credit worthiness or for fraud or
        identity verification purposes. If you are a resident of a landlord with
        whom Rentizy has contracted, we may collect information regarding your
        rental or payment history.
      </p>
      <ol start="2">
        <li>
          <strong> How Rentizy Uses the Information it Collects</strong>
        </li>
      </ol>
      <p>
        We use information that we collect about you or that you provide to us,
        including any personal information, to provide you with information,
        products, or services that you request from us. This use includes:
      </p>
      <ul>
        <li>Registering you for an account with Rentizy</li>
        <li>
          Assessing your eligibility for Rentizy Rental Loan and originating and
          servicing such a loan
        </li>
        <li>Providing customer support</li>
        <li>Conducting sweepstakes, contents, or promotions</li>
        <li>Pre-populating forms or applications you might fill out</li>
        <li>Improving our insights to provide you a more tailored product</li>
        <li>To fulfill any other purpose for which you provide it</li>
        <li>To provide you with notices about your account or subscriptions</li>
        <li>
          To communicate with you, including sending direct email and marketing
          messages
        </li>
        <li>
          To carry out our obligations and enforce our rights arising from any
          contracts entered into between you and us, including for billing and
          collection
        </li>
        <li>
          To notify you about changes to the Rentizy Site or any products or
          services we offer or provide though it
        </li>
        <li>
          In any other way we may describe when you provide the information
        </li>
      </ul>
      <p>
        We may also use your personal information to conduct behavioral
        assessments, to develop and improve Rentizy &nbsp;Services, including
        the creation and improvement of new products and offerings, to protect
        against fraud and fulfill legal requirements. We may also use aggregate
        or de-identified data for any permissible business purpose, including
        sharing with third-party service providers for allowable purposes or for
        research conducted by others. We will not share this de-identified or
        aggregate information in a way that enables the recipient to personally
        identify you.
      </p>
      <p>
        We protect your personal information with security measures that comply
        with applicable law. These measures include computer and cloud
        safeguards. &nbsp;
      </p>
      <ol start="3">
        <li>
          <strong> How Rentizy Shares Information</strong>
        </li>
      </ol>
      <p>
        Rentizy will not sell your personal information to a third-party for
        their marketing purposes.
      </p>
      <p>We may share your personal information with:</p>
      <ul>
        <li>
          our subsidiaries and affiliates for marketing or their everyday
          business purposes, including information about your creditworthiness
          or transactions and experiences
        </li>
        <li>purchasers of your loan</li>
        <li>
          our contractors, service providers, and other third parties we use to
          support our business such as fraud prevention, servicing, bill
          collection, marketing and technology services, product development,
          and nonaffiliated financial institutions that we partner with to
          market or provide the products or services to you
        </li>
        <li>
          to a buyer or other successor in the event of a merger, divestiture,
          restructuring, reorganization, dissolution, or other sale or transfer
          of some or all of our assets, whether as a going concern or as part of
          bankruptcy, liquidation, or similar proceeding, in which personal
          information held by us is among the assets transferred
        </li>
        <li>
          If you are using a Rentizy Service as a renter with a landlord with
          whom Rentizy has contracted, with your landlord
        </li>
        <li>Credit bureaus to report account information</li>
        <li>To fulfill the purpose for which you provide it</li>
      </ul>
      <p>We may also share your personal information:</p>
      <ul>
        <li>
          To comply with any court order, law, or legal process, including to
          respond to any government or regulatory request.
        </li>
        <li>
          To enforce or apply our contractual arrangements, including for
          billing and collection purposes.
        </li>
        <li>
          If we believe disclosure is necessary or appropriate to protect the
          rights, property, or safety of us, our customers, or others. This
          includes exchanging information with other companies and organizations
          for the purposes of fraud protection and credit risk reduction.
        </li>
      </ul>
      <p>
        De-identified or aggregated information is not considered personal
        information. We may also share de-identified or aggregated information
        for any purpose.
      </p>
      <ol start="4">
        <li>
          <strong> Children&rsquo;s Privacy</strong>
        </li>
      </ol>
      <p>
        Our Website is not intended for children under 13 years of age. We do
        not knowingly collect personal information from children under 13. If we
        learn we have collected or received personal information from a child
        under 13, we will delete that information.
      </p>
      <ol start="5">
        <li>
          <strong> Choices About How we Use and Share Your Information</strong>
        </li>
      </ol>
      <p>
        You may limit our sharing of the following types of your personal
        information:
      </p>
      <ul>
        <li>
          Personal information provided to our affiliates related to your
          creditworthiness
        </li>
        <li>
          Personal information provided to your landlord unless that information
          is needed to administer, process, or enforce a transaction that you
          requested or authorized or you provide us consent or direction to
          share that information.
        </li>
      </ul>
      <p>
        To limit our sharing of this type of personal information, please send
        us an email to{" "}
        <a href="mailto:privacy@rentizy.co.ke">privacy@rentizy.co.ke</a> with a
        message the requests we limit our sharing of your personal information.
      </p>
      <p>
        You may also request that we remove any live connection we have to your
        bank or financial information through Plaid by sending a request to{" "}
        <a href="mailto:privacy@rentizy.com">privacy@rentizy.co.ke</a> with a
        message that requests we terminate such a connection.
      </p>
      <p>
        If you delete or deactivate your account and use of the Rentizy
        Services, we will retain your information following deactivation and may
        use it to contact you, unless you have otherwise opted out, or for our
        business purposes, subject to applicable law.
      </p>
      <ol start="6">
        <li>
          <strong> Changes to Our Privacy Policy</strong>
        </li>
      </ol>
      <p>
        It is our policy to post any changes we make to our privacy policy on
        this page. If we make material changes to how we treat our users'
        personal information, we will notify you by email to the email address
        specified in your account or through a notice on the Rentizy Site. The
        date the privacy policy was last revised is identified at the top of the
        page. You are responsible for ensuring we have an up-to-date active and
        deliverable email address for you, and for periodically visiting the
        Rentizy Site and this privacy policy to check for any changes.
      </p>
      <ol start="8">
        <li>
          <strong> Contact Information</strong>
        </li>
      </ol>
      <p>
        To ask questions or comment about this privacy policy and our privacy
        practices, contact us at:&nbsp;
        <a href="mailto:privacy@rentizy.com">privacy@rentizy.co.ke</a>
      </p>
      <p>
        Please note that the Rentizy Services may contain links to other web
        sites and these web sites&rsquo; privacy policies may not conform to our
        own. This Privacy Policy and accompanying Privacy Notice does not cover
        third party data collection practices and Rentizy does not assume any
        responsibility for any actions of third parties. You are solely
        responsible for reading and understanding the privacy and other policies
        of such properties, and for complying therewith.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
