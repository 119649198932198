import axios from "axios";

import React, { useState } from "react";

import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { serverAddress } from "../config/Config";
import { connect } from "react-redux";

const AddUnit = ({ user, apartment, unit }) => {
  let history = useHistory();
  const [state, setstate] = useState({
    unitName: "",
    rent: 0,
    loading: false,
    flag: false,
  });
  let { unitName, rent, loading, flag } = state;
  if (unit && unit.id && !flag) {
    setstate({
      ...state,
      ...unit,

      flag: true,
    });
  }

  let addApartment = (e) => {
    e.preventDefault();
    setstate({ ...state, loading: true });
    let obj = {
      ...state,
    };
    obj.apartmentName = apartment.name_of_apartment;
    obj.apartmentId = apartment.apartment_id;
    obj.rent = Number.parseFloat(rent);

    console.log(obj);

    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    if (user.type === "TENANT") {
      axios
        .post(`${serverAddress}/tenant/apartments/apartment-unit`, obj, config)
        .then((res) => {
          history.push("/tenant/apartments");
        })
        .catch((err) => {
          toast.error("Error occured while adding appartment");
          setstate({ ...state, loading: false });
        });
    } else
      axios
        .post(`${serverAddress}/apartments/apartment-unit`, obj, config)
        .then((res) => {
          console.log(res.data);
          history.push("/admin/apartments");
        })
        .catch((err) => {
          toast.error("Error occured while adding appartment");
          setstate({ ...state, loading: false });
        });
  };
  return (
    <div className="content center">
      <Row>
        <Col md="8" sm="12" style={{ margin: "auto" }}>
          <Card className="card-user">
            <CardHeader>
              {unit.id ? (
                <CardTitle tag="h5">Edit Apartment Unit</CardTitle>
              ) : (
                <CardTitle tag="h5">Add Apartment Unit</CardTitle>
              )}
            </CardHeader>
            <CardBody>
              <Form onSubmit={addApartment}>
                <Row>
                  <Col className="pr-1" md="12">
                    <FormGroup>
                      <label>Unit Name</label>
                      <Input
                        placeholder="Unit Name"
                        type="text"
                        value={unitName}
                        required
                        onChange={(e) =>
                          setstate({
                            ...state,
                            unitName: e.target.value,
                          })
                        }
                      />
                    </FormGroup>

                    <FormGroup>
                      <label>Rent</label>
                      <Input
                        placeholder="Rent per month"
                        type="number"
                        value={rent}
                        required
                        onChange={(e) =>
                          setstate({ ...state, rent: e.target.value })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <div className="update ml-auto mr-auto">
                    {!loading ? (
                      <Button
                        className="btn-round"
                        color="primary"
                        type="submit"
                      >
                        {unit.id ? (
                          <span>Edit Unit</span>
                        ) : (
                          <span>Add Unit</span>
                        )}
                      </Button>
                    ) : (
                      <Spinner color="primary" />
                    )}
                  </div>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  apartment: state.apartment,
  unit: state.unit,
});

export default connect(mapStateToProps)(AddUnit);
