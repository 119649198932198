import axios from "axios";

import React, { useState } from "react";
import Dropdown from "react-dropdown";
import { counties } from "../config/counties";

import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { serverAddress } from "../config/Config";
import { connect } from "react-redux";

const AddApartment = ({ user, apartment }) => {
  let history = useHistory();
  const [owners, setowners] = useState([]);
  const [email, setEmail] = useState("");
  const [state, setstate] = useState({
    name_of_apartment: "",
    location: "",
    no_of_houses: 0,
    rent: 0,
    address: "",
    county: "",
    loading: false,
    flag: false,
  });
  let {
    name_of_apartment,
    location,
    no_of_houses,
    county,
    address,
    loading,

    flag,
  } = state;
  if (user && apartment && !flag) {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    axios.get(`${serverAddress}/admin/owners`, config).then((res) => {
      setowners(res.data);
    });

    setstate({
      ...state,
      ...apartment,

      flag: true,
    });
  }

  let addApartment = (e) => {
    e.preventDefault();
    setstate({ ...state, loading: true });
    let ownerId = null;

    owners
      .filter((o) => o.email === email)
      .forEach((o) => {
        ownerId = o.owner_id;
      });
    let obj = {
      ...state,
    };
    if (ownerId) {
      obj = { ...obj, ownerId: ownerId };
    }

    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    if (apartment.apartment_id) {
      axios
        .post(`${serverAddress}/apartments/update`, obj, config)
        .then((res) => {
          history.push("/admin/apartments");
        })
        .catch((err) => {
          toast.error("Error occured while updating appartment");
          setstate({ ...state, loading: false });
        });
    } else {
      axios
        .post(`${serverAddress}/apartments`, obj, config)
        .then((res) => {
          history.push("/admin/apartments");
        })
        .catch((err) => {
          toast.error("Error occured while adding appartment");
          setstate({ ...state, loading: false });
        });
    }
  };

  return (
    <div className="content center">
      <Row>
        <Col md="8" sm="12" style={{ margin: "auto" }}>
          <Card className="card-user">
            <CardHeader>
              {apartment.apartment_id ? (
                <CardTitle tag="h5">Edit Apartment</CardTitle>
              ) : (
                <CardTitle tag="h5">Add Apartment</CardTitle>
              )}
            </CardHeader>
            <CardBody>
              <Form onSubmit={addApartment}>
                <Row>
                  <Col className="pr-1" md="12">
                    <FormGroup>
                      <label>Name</label>
                      <Input
                        placeholder="Name"
                        type="text"
                        value={name_of_apartment}
                        required
                        onChange={(e) =>
                          setstate({
                            ...state,
                            name_of_apartment: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Location</label>
                      <Input
                        placeholder="Location"
                        type="text"
                        value={location}
                        onChange={(e) =>
                          setstate({ ...state, location: e.target.value })
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Address</label>
                      <Input
                        placeholder="Address"
                        type="text"
                        value={address}
                        required
                        onChange={(e) =>
                          setstate({ ...state, address: e.target.value })
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Landlord</label>
                      <Dropdown
                        options={owners.map((o) => o.email)}
                        onChange={(e) => setEmail(e.value)}
                        value={email}
                        placeholder="Select Landlord"
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>County</label>
                      <Dropdown
                        options={counties}
                        onChange={(e) =>
                          setstate({ ...state, county: e.value })
                        }
                        value={county}
                        placeholder="Select County"
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Number of houses</label>
                      <Input
                        placeholder="No of houses"
                        type="number"
                        value={no_of_houses}
                        required
                        onChange={(e) =>
                          setstate({ ...state, no_of_houses: e.target.value })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <div className="update ml-auto mr-auto">
                    {!loading ? (
                      <Button
                        className="btn-round"
                        color="primary"
                        type="submit"
                      >
                        {apartment.apartment_id ? (
                          <span>Edit Apartment</span>
                        ) : (
                          <span>Add Apartment</span>
                        )}
                      </Button>
                    ) : null}
                  </div>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  apartment: state.apartment,
});

export default connect(mapStateToProps)(AddApartment);
