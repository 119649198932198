import firebase from "firebase/app";

import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDpoF-Z4lU2f0xNi2__554xpG41UvDt3Oc",
  authDomain: "rentizy-ab905.firebaseapp.com",
  projectId: "rentizy-ab905",
  storageBucket: "rentizy-ab905.appspot.com",
  messagingSenderId: "504128142310",
  appId: "1:504128142310:web:8e09d97c42d47c78d8f427",
  measurementId: "G-BR9SP9NTQ6",
};

firebase.initializeApp(firebaseConfig);

const analytics = firebase.analytics();

export { analytics, firebase as default };
