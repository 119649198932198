import React, { useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { serverAddress } from "../config/Config";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";

// reactstrap components
import {
  Card,
  CardBody,
  Table,
  Row,
  Col,
  CardHeader,
  CardTitle,
} from "reactstrap";
import UserSubscription from "../components/Subscription/UserSubscription";

const Subscriptions = ({ user }) => {
  let history = useHistory();

  const [state, setstate] = useState({
    subs: [],
    flag: false,
  });
  let { subs, flag } = state;
  if (user && !flag) {
    if (user.type === "TENANT") {
      if (
        !user.location ||
        !user.occupation ||
        !user.address ||
        !user.placeOfWork ||
        !user.emergency_contact ||
        !user.apartmentUnit ||
        !user.id_number === 0
      ) {
        history.push("user-page");
      }
    }
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    axios.get(`${serverAddress}/subscription`, config).then((res) => {
      console.log(res.data);
      setstate({ ...state, subs: res.data, flag: true });
    });
  }
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md={6}>
                    <CardTitle tag="h4">Subscriptions</CardTitle>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody>
                <UserSubscription />
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Id</th>
                      <th>Description</th>
                      <th>Start</th>
                      <th>End</th>
                      <th>Status</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subs.map((i) => (
                      <tr>
                        <td>{i.id.substring(0, 6) + "..."}</td>
                        <td>{i.description}</td>
                        <td>
                          {` ${dayjs(i.start).format("D MMM , YYYY h:mm A")}`}
                        </td>
                        <td>
                          {` ${dayjs(i.end).format("D MMM , YYYY h:mm A")}`}
                        </td>
                        <td>{i.status}</td>
                        <td>{i.price}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Subscriptions);
