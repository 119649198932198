import React from "react";
import { connect } from "react-redux";
import { setUser } from "../../actions/user";

const SignOut = ({ setUser }) => {
  return (
    <div onClick={(e) => setUser(null)}>
      <i className="nc-icon nc-minimal-left" />
      <p>Sign Out</p>
    </div>
  );
};

export default connect(null, { setUser })(SignOut);
