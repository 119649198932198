import { combineReducers } from "redux";
import user from "./UserReducer";
import context from "./ContextReducer";
import selectedUser from "./SelectedUserReducer";
import apartment from "./SelectedApartmentReducer";
import staff from "./StaffReducer";
import unit from "./SelectedUnit";
import landlord from "./SelectedLandlord";
import subscription from "./SelectedSubscriptionReducer";
import event from "./SelectedEvent";
import dialog from "./DialogReducer";
import balance from "./BalanceReducer";
import selectedLoan from "./LoanReducer";

const rootReducer = combineReducers({
  dialog,
  balance,
  selectedLoan,
  user,
  context,
  selectedUser,
  apartment,
  staff,
  subscription,
  unit,
  landlord,
  event,
});

export default rootReducer;
