import { SET_SELECTED_BALANCE } from "../actions/types";

let initialState = {};

const context = (state = initialState, action) => {
  let { type, payload } = action;
  switch (type) {
    case SET_SELECTED_BALANCE:
      return payload;

    default:
      return state;
  }
};
export default context;
