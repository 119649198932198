import { SET_DIALOG } from "../actions/types";

let initialState = {
  message: "",
  show: false,
};

const context = (state = initialState, action) => {
  let { type, payload } = action;
  switch (type) {
    case SET_DIALOG:
      return payload;

    default:
      return state;
  }
};
export default context;
