import React from "react";
import { Product } from "../../components";
import { productSectionData } from "./Data";

function Products() {
  return (
    <>
      <Product {...productSectionData} />
    </>
  );
}

export default Products;
