import React from "react";
import { connect } from "react-redux";
import { setEvent } from "../../actions/selectedEvent";
import "./event.css";
import dayjs from "dayjs";
import { AiFillCloseCircle } from "react-icons/ai";

const EventOverlay = ({ event, setEvent }) => {
  return (
    <>
      {event ? (
        <div onClick={(e) => setEvent(null)} className="event-overlay">
          <div onClick={(e) => e.stopPropagation()} className="event-dm">
            <div>
              <AiFillCloseCircle size={25} onClick={(e) => setEvent(null)} />
            </div>
            <p>{dayjs(event.end).format("D MMM , YYYY h:mm A")}</p>
            <h5>{event.title}</h5>
            <p>{event.description}</p>
          </div>
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  event: state.event,
});

const mapDispatchToProps = { setEvent };

export default connect(mapStateToProps, mapDispatchToProps)(EventOverlay);
