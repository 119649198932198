import React, { useState } from "react";
import axios from "axios";
import { serverAddress } from "../config/Config";
import { connect } from "react-redux";
import dayjs from "dayjs";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";

const Records = ({ user }) => {
  const [dropdownOpenCalender, setOpenCalender] = useState(false);

  const toggleCalendar = () => setOpenCalender(!dropdownOpenCalender);
  const [dropdownOpen, setOpen] = useState(false);
  const [state, setstate] = useState({
    flag: false,
    data: {},
    apartments: [],
    filter: { month: "", apartment: "" },
  });

  let { flag, data, apartments, filter } = state;

  if (user && !flag) {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    let url = `${serverAddress}/admin/metric/monthly-payment`;

    if (user.type === "ADMIN") {
      url = `${serverAddress}/admin/metric/monthly-payment`;
    } else if (user.type === "TENANT") {
      url = `${serverAddress}/tenant/metric/monthly-payment`;
    } else {
      url = `${serverAddress}/owner/metric/monthly-payment`;
    }
    axios.get(url, config).then((res) => {
      console.log(res.data);
      axios.get(`${serverAddress}/apartments/apartmentnames`).then((r) => {
        setstate({
          ...state,
          apartments: r.data,
          flag: true,
          data: res.data,
        });
      });
    });
  }

  const toggle = () => setOpen(!dropdownOpen);

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <CardTitle tag="h4">Records</CardTitle>
                <div className="record-header">
                  <ButtonDropdown
                    isOpen={dropdownOpenCalender}
                    toggle={toggleCalendar}
                  >
                    <DropdownToggle caret>
                      {filter.month !== "" ? filter.month : "Select month"}
                    </DropdownToggle>
                    {data ? (
                      <DropdownMenu>
                        {Object.keys(data)
                          .sort(
                            (a, b) =>
                              dayjs(`1 ${a}`).toDate() -
                              dayjs(`1 ${b}`).toDate()
                          )

                          .map((key) => (
                            <DropdownItem
                              onClick={(e) => {
                                setstate({
                                  ...state,
                                  filter: { ...filter, month: key },
                                });
                              }}
                            >
                              {key}
                            </DropdownItem>
                          ))}
                      </DropdownMenu>
                    ) : null}
                  </ButtonDropdown>

                  <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle caret>
                      {filter.apartment !== ""
                        ? filter.apartment
                        : "Select Appartment"}
                    </DropdownToggle>
                    <DropdownMenu>
                      {apartments.map((a) => (
                        <DropdownItem
                          onClick={(e) => {
                            setstate({
                              ...state,
                              filter: { ...filter, apartment: a },
                            });
                          }}
                        >
                          {a}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </ButtonDropdown>
                </div>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Tenant Name</th>
                      <th>Apartment</th>

                      <th>Phone Number</th>
                      <th>Expected Amount</th>
                      <th>Amount Paid</th>
                      <th>Balance</th>
                      <th>Receipt</th>
                      <th>Timestamp</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(data)
                      .filter((key) => {
                        if (filter.month !== "") return key === filter.month;
                        else return true;
                      })
                      .map((key) => data[key])
                      .map((d) => {
                        return d
                          .filter((e) => {
                            if (filter.apartment !== "")
                              return e.apartment === filter.apartment;
                            else return true;
                          })
                          .sort(
                            (a, b) =>
                              dayjs(`${b.payment.timestamp}`).toDate() -
                              dayjs(`${a.payment.timestamp}`).toDate()
                          )
                          .map((e) => (
                            <tr>
                              <td>{e.tenantName}</td>
                              <td>{e.apartment}</td>

                              <td>{e.phone}</td>
                              <td>{e.payment.expected_rent}</td>

                              <td className="text-right">
                                {e.payment.rent_paid}
                              </td>

                              <td className="text-right">
                                {e.payment.expected_rent - e.payment.rent_paid}
                              </td>
                              <td className="text-right">
                                {e.payment.receipt}
                              </td>
                              <td className="text-right">
                                {` ${dayjs(e.payment.timestamp).format(
                                  "D MMM , YYYY h:mm A"
                                )}`}
                              </td>
                            </tr>
                          ));
                      })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Records);
