import React from "react";
import "./payment.css";
import { Button } from "reactstrap";

import { useHistory } from "react-router";

const FlexibleRent = () => {
  let history = useHistory();
  return (
    <div className="f">
      <div className="tenant-home">
        <div className="rent">
          <h1>
            We work with you to ensure you can pay rent on time, every month.
          </h1>
          <p>
            With budgeting, automated payments, and rent reporting, we help you
            build towards a better financial future. With Rentizy, you can
            alleviate the stress that comes with lump-sum payments and avoid
            late fees when life gets in the way.
          </p>
          <div>
            <Button
              onClick={(e) => history.push("/register")}
              className="btn-round"
              color="primary"
            >
              Create Your Schedule
            </Button>
          </div>
        </div>
      </div>

      <div className="works">
        <div className="w-content">
          <p>HOW IT WORKS</p>
          <h1>Rent, on your terms.</h1>
          <h5>
            With Rentizy , you pay a portion of your rent each time you get
            paid, rather than on the first of the month. And by avoiding late
            fees while enrolled, Rentizy frees up some extra cash to help you
            become an on-time payer.
          </h5>
        </div>
      </div>
      <div className="steps">
        <div className="first">
          <div className="number-badge">
            <span>1</span>
          </div>
          <h4>Sign Up</h4>
          <p>Sign up and link your account with your apartment/residence</p>
        </div>
        <div className="second">
          <div className="number-badge">
            <span>2</span>
          </div>
          <h4>Complete Profile.</h4>
          <p>Complete your profile and select your subscription level</p>
        </div>
        <div className="second">
          <div className="number-badge">
            <span>3</span>
          </div>
          <h4>Subscription Payment.</h4>
          <p>Submit your subscription payment</p>
        </div>
        <div className="second">
          <div className="number-badge">
            <span>4</span>
          </div>
          <h4>Loan Request.</h4>
          <p>
            Start enjoying your membership perks immediately.Request any amount
            to be paid directly and promptly to your landlord.
          </p>
        </div>
        <div className="third">
          <div className="number-badge">
            <span>5</span>
          </div>
          <h4>Loan Payment</h4>
          <p>
            Payback the amount availed you either in lumpsum or in portions over
            the month, we understand life happens.
          </p>
          <div>
            <Button
              onClick={(e) => history.push("/register")}
              className="btn-round"
              color="primary"
            >
              Start Now
            </Button>
          </div>
        </div>
      </div>

      <div className="why">
        <h1>Why Choose Rentizy</h1>
        <div>
          <div>
            <h4>No more bulk payments.</h4>
            <p>
              You pay your rent evenly over the month, rather than all at once.
              This helps reduce the time and stress of thinking about your
              finances.
            </p>
          </div>
          <div>
            <h4>We meet you where you are.</h4>
            <p>
              Regardless of whether you pay on time or find yourself a little
              behind, Rentizy meets you where you are and helps you pay rent on
              your schedule.
            </p>
          </div>
          <div>
            <h4>Get back on track.</h4>
            <p>
              By avoiding late fees while enrolled, Rentizy frees up a little
              money each month to help you become an on-time payer.
            </p>
          </div>
        </div>
        <div>
          <Button
            onClick={(e) => history.push("/register")}
            className="btn-round"
            color="primary"
          >
            Get Started
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FlexibleRent;
