import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import { paymentAmount } from "../variables/charts.js";
import dayjs from "dayjs";

import axios from "axios";
import { connect } from "react-redux";
import { serverAddress } from "../config/Config";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";

const PaymentStats = ({ user, selectedUser }) => {
  let history = useHistory();
  const location = useLocation();
  const [state, setstate] = useState({
    flag: false,
    t: {
      labels: [],
      data: [],
    },
  });
  let { flag, t } = state;
  if (user && !flag) {
    if (user.type === "TENANT") {
      if (
        !user.location ||
        !user.occupation ||
        !user.address ||
        !user.placeOfWork ||
        !user.emergency_contact ||
        !user.apartmentUnit ||
        !user.id_number === 0
      ) {
        history.push("user-page");
      }
    }
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    if (
      selectedUser.type === "TENANT" &&
      location.pathname === "/admin/selected-user"
    ) {
      console.log("admin tenants");
      axios
        .get(
          `${serverAddress}/admin/metric/monthly-tenant/${selectedUser.tenant_id}`,
          config
        )
        .then((res) => {
          setstate({
            ...state,
            flag: true,
            t: {
              labels: Object.keys(res.data).sort(
                (a, b) => dayjs(`1 ${a}`).toDate() - dayjs(`1 ${b}`).toDate()
              ),
              data: Object.keys(res.data)
                .sort(
                  (a, b) => dayjs(`1 ${a}`).toDate() - dayjs(`1 ${b}`).toDate()
                )
                .map((key) => res.data[key]),
            },
          });
        });
    }
    if (user.type === "TENANT") {
      axios
        .get(`${serverAddress}/tenant/metric/monthly-payment-amount`, config)
        .then((res) => {
          console.log(res.data);
          setstate({
            ...state,
            flag: true,
            t: {
              labels: Object.keys(res.data).sort(
                (a, b) => dayjs(`1 ${a}`).toDate() - dayjs(`1 ${b}`).toDate()
              ),
              data: Object.keys(res.data)
                .sort(
                  (a, b) => dayjs(`1 ${a}`).toDate() - dayjs(`1 ${b}`).toDate()
                )
                .map((key) => res.data[key]),
            },
          });
        });
    }
    if (selectedUser.type !== "TENANT" && user.type === "ADMIN") {
      console.log("admin metrics");
      axios
        .get(`${serverAddress}/admin/metric/monthly-payment-amount`, config)
        .then((res) => {
          setstate({
            ...state,
            flag: true,
            t: {
              labels: Object.keys(res.data).sort(
                (a, b) => dayjs(`1 ${a}`).toDate() - dayjs(`1 ${b}`).toDate()
              ),
              data: Object.keys(res.data)
                .sort(
                  (a, b) => dayjs(`1 ${a}`).toDate() - dayjs(`1 ${b}`).toDate()
                )
                .map((key) => res.data[key]),
            },
          });
        });
    }
  }

  return (
    <div>
      <Line
        data={paymentAmount.data(t)}
        options={paymentAmount.options}
        width={400}
        height={200}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  selectedUser: state.selectedUser,
});

export default connect(mapStateToProps)(PaymentStats);
