import React from "react";

import { connect } from "react-redux";
import logo from "../../assets/img/logo.png";
import "./footer.css";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";
import {
  FooterContainer,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
} from "./Footer.elements";

function Footer({ context }) {
  return (
    <>
      {context === "default" ? (
        <FooterContainer>
          <div className="f-content">
            <img src={logo} alt="rentizy logo" height="120px" />

            <div className="f-links">
              <FooterLink to="/platform">How it works</FooterLink>
              <FooterLink to="/testimonials">Testimonials</FooterLink>
              <FooterLink to="/contact">Contact Us</FooterLink>
              <FooterLink to="/careers">Careers</FooterLink>
              <FooterLink to="/faq">FAQs</FooterLink>
              <FooterLink to="/terms-of-service">Terms of Service</FooterLink>
              <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
              <FooterLink to="/terms-of-service-owners-and-operators">
                Terms of Service - landlords
              </FooterLink>
            </div>
          </div>

          <SocialMedia>
            <SocialMediaWrap>
              <WebsiteRights>RENTIZY © 2021</WebsiteRights>
              <SocialIcons>
                <SocialIconLink
                  href="https://www.facebook.com/Rentizy-105525385195752/"
                  target="_blank"
                  aria-label="Facebook"
                >
                  <FaFacebook color="#00AEFF" />
                </SocialIconLink>
                <SocialIconLink
                  href="https://www.instagram.com/just_sham_it/"
                  target="_blank"
                  aria-label="Instagram"
                >
                  <FaInstagram color="#EF3750" />
                </SocialIconLink>
                <SocialIconLink
                  // href={
                  //   "//www.youtube.com/channel/UCsKsymTY_4BYR-wytLjex7A?view_as=subscriber"
                  // }
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label="Youtube"
                >
                  <FaYoutube />
                </SocialIconLink>
                <SocialIconLink
                  href="https://twitter.com/RentizyF?s=08"
                  target="_blank"
                  aria-label="Twitter"
                >
                  <FaTwitter />
                </SocialIconLink>
                <SocialIconLink href="/" target="_blank" aria-label="LinkedIn">
                  <FaLinkedin />
                </SocialIconLink>
              </SocialIcons>
            </SocialMediaWrap>
          </SocialMedia>
        </FooterContainer>
      ) : null}
    </>
  );
}
const mapStateToProps = (state) => ({
  context: state.context,
});

export default connect(mapStateToProps)(Footer);
