import axios from "axios";

import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { serverAddress } from "../config/Config";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router";

const AddStaff = ({ user, staff }) => {
  let history = useHistory();
  if (staff) {
  }
  const [state, setstate] = useState({
    email: "",
    first_name: "",
    last_name: "",
    loading: false,
    flag: false,
  });
  let { email, first_name, last_name, loading, flag } = state;
  if (!flag && staff.appUser) {
    setstate({
      ...state,
      email: staff.appUser.email,
      first_name: staff.first_name,
      last_name: staff.last_name,
      flag: true,
    });
  }

  let addStaff = (e) => {
    e.preventDefault();
    let obj = {
      ...state,
    };

    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    setstate({ ...state, loading: true });

    if (staff.appUser) {
      let obj = { ...staff };

      obj.last_name = last_name;
      obj.first_name = first_name;
      obj.email = email;
      console.log(obj);

      axios
        .put(`${serverAddress}/admin/staff`, obj, config)
        .then((res) => {
          console.log(res.data);
          history.push("/admin/staff");
        })
        .catch((err) => {
          toast.error("Edit staff failed");
          setstate({ ...state, loading: false });
        });
    } else {
      axios
        .post(`${serverAddress}/admin/staff`, obj, config)
        .then((res) => {
          console.log(res.data);
          history.push("/admin/staff");
        })
        .catch((err) => {
          toast.error("Adding staff failed");
          setstate({ ...state, loading: false });
        });
    }
  };
  return (
    <div className="content center">
      <Row>
        <Col md="8" sm="12" style={{ margin: "auto" }}>
          <Card className="card-user">
            <CardHeader>
              {staff.appUser ? (
                <CardTitle tag="h5">Edit Staff</CardTitle>
              ) : (
                <CardTitle tag="h5">Add Staff</CardTitle>
              )}
            </CardHeader>
            <CardBody>
              <Form onSubmit={addStaff}>
                <Row>
                  <Col className="pr-1" md="12">
                    <FormGroup>
                      <label>FirstName</label>
                      <Input
                        placeholder="Firstname"
                        type="text"
                        value={first_name}
                        required
                        onChange={(e) =>
                          setstate({ ...state, first_name: e.target.value })
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>LastName</label>
                      <Input
                        placeholder="LastName"
                        type="text"
                        value={last_name}
                        required
                        onChange={(e) =>
                          setstate({ ...state, last_name: e.target.value })
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Email</label>
                      <Input
                        placeholder="Email"
                        type="email"
                        value={email}
                        required
                        onChange={(e) =>
                          setstate({ ...state, email: e.target.value })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <div className="update ml-auto mr-auto">
                    {!loading ? (
                      <Button
                        className="btn-round"
                        color="primary"
                        type="submit"
                      >
                        {staff.appUser ? (
                          <span>Edit Staff</span>
                        ) : (
                          <span>Add Staff</span>
                        )}
                      </Button>
                    ) : null}
                  </div>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  staff: state.staff,
});

export default connect(mapStateToProps)(AddStaff);
