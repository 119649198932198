import React from "react";
import "./operators.css";
import { Button } from "reactstrap";
import CountUp from "react-countup";
import { useHistory } from "react-router-dom";

const Operators = () => {
  let history = useHistory();
  return (
    <div className="operators">
      <div className="la">
        <div>
          <h1>Together, let’s change the future of rent.</h1>
          <p>
            Rentizy improves your renter’s financial stability and your returns.
          </p>
          <div>
            <Button
              onClick={(e) => history.push("/register")}
              className="btn-round"
              color="primary"
            >
              Join Now
            </Button>
          </div>
        </div>
      </div>

      <div className="build">
        <div className="first">
          <h4>
            Rentizy is building a new future of rent, with products that help
            100% of renters pay rent 100% of the time.
          </h4>
        </div>
        <div>
          <h5>Better relationships, more on-time payments.</h5>
          <p>
            We partner with you to increase on-time collections & simplify and
            streamline your workload.
          </p>
          <div>
            <Button
              onClick={(e) => history.push("/register")}
              className="btn-round"
              color="primary"
            >
              Start Now
            </Button>
          </div>
        </div>
      </div>
      <div className="platform">
        <h1>What is the Rentizy platform?</h1>
        <div className="p-content">
          <div>
            <h4>Flexible payments.</h4>
            <p>
              A personal schedule that breaks down rent into more manageable
              payments throughout the month.
            </p>
          </div>
          <div>
            <h4>Personalized, proactive collection.</h4>
            <p>
              We provide payment reminders, follow-ups, easy electronic payments
              and human support so you don’t have to.
            </p>
          </div>
          <div>
            <h4>Delinquency management.</h4>
            <p>
              Our smart scheduling ensures delinquent renters become reliable
              on-time payers.
            </p>
          </div>
        </div>
      </div>
      <div>
        <h4>Simplified Rent Reporting</h4>
        <p>
          Easily report your resident’s successful rent payments to the credit
          bureaus with Rentizy Credit Builder.
        </p>
        <div>
          <Button
            onClick={(e) => history.push("/register")}
            className="btn-round"
            color="primary"
          >
            Start Now
          </Button>
        </div>
      </div>

      <div className="stats">
        <div>
          <h1>
            <CountUp end={20} />
            -
            <CountUp end={50} />%
          </h1>
          <p>Increase in on-time collections</p>
        </div>
        <div>
          <h1>
            <CountUp end={97} /> %{" "}
          </h1>
          <p>Renter payment success</p>
        </div>
        <div>
          <h1>
            <CountUp end={70} /> %{" "}
          </h1>
          <p>Reduction in time spent managing delinquency</p>
        </div>
      </div>

      <div className="partner">
        <h1>Why partner with Rentizy?</h1>
        <div className="partner-content">
          <div>
            <h4>Increase your cashflow.</h4>
            <p>
              Offer innovative services that set you apart from your competition
              and increase your revenue streams.
            </p>
          </div>
          <div>
            <h4>Access advanced analytics.</h4>
            <p>
              Understand your payments better than ever with program usage and
              property performance analytics.
            </p>
          </div>
          <div>
            <h4>Improve property value.</h4>
            <p>
              We work with you and your renters to lower delinquency, bad debt
              and turnover.
            </p>
          </div>
          <div>
            <h4>Pay nothing.</h4>
            <p>
              Rentizy is completely free for owner/operators to adopt. Our
              program is designed with your team in mind to get you paid on time
              and in full.
            </p>
          </div>
        </div>
      </div>
      <div className="saying">
        <div className="s-header">
          <h1>What our partners are saying.</h1>
        </div>
        <div className="s-content">
          <div>
            <h4>
              “The products themselves have changed our entire rent collection
              process on site, which has been instrumental in giving us a
              competitive advantage in the marketplace…Bottom line: I highly
              recommend Rentizy and their programs.”
            </h4>
            <p>— Steve Yogo</p>
          </div>
          <div>
            <h4>
              “The products themselves have changed our entire rent collection
              process on site, which has been instrumental in giving us a
              competitive advantage in the marketplace…Bottom line: I highly
              recommend Rentizy and their programs.”“Rentizy has cut the time I
              spend trying to collect late rent in half by proactively servicing
              all my residents that are enrolled in their programs…Those who
              face financial uncertainty deeply appreciate Rentizy's
              accommodating customer service.”
            </h4>
            <p>— Austine Gwa</p>
          </div>
          <div>
            <h4>
              “Rentizy is an easy decision for anyone who is looking to have a
              heart in an industry where we’re unable to bend due to contracts
              and laws.”
            </h4>
            <p>— Collins</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Operators;
