import React from "react";
import "./budget.css";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import { BiTimeFive } from "react-icons/bi";
import { FaUserCheck } from "react-icons/fa";
import { FcCalendar } from "react-icons/fc";

const Budget = () => {
  let history = useHistory();
  return (
    <div className="budget">
      <div className="main">
        <h1>Better budget and save for rent</h1>
        <h5>
          With Rentizy, we break up your rent payments to align with your
          income. You'll always have money saved to ensure your rent is paid in
          full on the first of the month.
        </h5>
        <Button
          onClick={(e) => history.push("/register")}
          className="btn-round"
          color="primary"
        >
          Join Now
        </Button>
      </div>

      <div className="more">
        <div>
          <BiTimeFive size={50} style={{ margin: "auto" }} />
          <h4>No more late fees</h4>
          <p>
            Automating payments with Rentizy means you're always up to date and
            paid on time.
          </p>
        </div>
        <div>
          <FaUserCheck size={50} style={{ margin: "auto" }} />
          <h4>No more bulk payments</h4>
          <p>
            Rentizy breaks up your payments in a schedule that works for you.
          </p>
        </div>
        <div>
          <FcCalendar size={50} style={{ margin: "auto" }} />
          <h4>No more overdrafts</h4>
          <p>
            Rentizy ensures you can make your rent payments by aligning with
            when you've got the most in the bank.
          </p>
        </div>
      </div>
      <div className="life">
        <div>
          <h2>Your life is flexible, so why isn't your rent?</h2>
        </div>
        <div>
          <h4>Rentizy is creating a new financial model for rent.</h4>
          <p>
            We are working to align your payment schedules with your rent
            payments, to help keep you finances structured and your bills paid
            on time.
          </p>
          <Button
            onClick={(e) => history.push("/register")}
            className="btn-round"
            color="primary"
          >
            Join now
          </Button>
        </div>
      </div>

      <div className="value">
        <div>
          <h1>Pay, stay and thrive in your home.</h1>
          <div>
            <Button
              onClick={(e) => history.push("/register")}
              className="btn-round"
              color="primary"
            >
              Join now
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Budget;
