import React from "react";
import { Button } from "reactstrap";
import "./dialog.css";
import { connect } from "react-redux";
import { setDialog } from "../../actions/dialog";

const Dialog = ({ param, setDialog, dialog, callback, message }) => {
  let cancel = () => {
    setDialog({
      message: "",
      show: false,
    });
  };
  let proceed = () => {
    console.log(param);
    callback(param);
    setDialog({
      message: "",
      show: false,
    });
  };
  return (
    <div>
      {dialog && dialog.show ? (
        <div onClick={(e) => setDialog(false)} className="dialog">
          <div className="dialog-overlay">
            <h5 style={{ color: "whitesmoke" }}>{dialog.message}</h5>
            <div className="actions">
              <Button
                onClick={(e) => cancel()}
                className="btn-round"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={(e) => proceed()}
                className="btn-round"
                color="primary btn-danger"
              >
                Proceed
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
const mapStateToProps = (state) => ({
  dialog: state.dialog,
});

export default connect(mapStateToProps, { setDialog })(Dialog);
